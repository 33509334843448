<template>
  <div class="d-flex flex-column flex-xl-row justify-content-between">
    <slot />
    <div
      class="mb-3 mb-xl-0 mr-xl-1"
      :class="{'flex-grow-1': flexGrow}"
    >
      <slot name="left" />
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessGuideHeader',

  props: {
    flexGrow: {
      type: Boolean,
      default: false
    }
  }
}
</script>
