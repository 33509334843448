<template>
  <div class="">
    <v-text-field
      v-model="searchValue"
      outlined
      dense
      :placeholder="$t('search')"
      hide-details
      height="44px"
      clearable
      class="search-text-field"
    >
      <template #prepend-inner>
        <v-icon>{{ icons.mdiMagnify }}</v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'BusinessGuideSearch',

  props: {
    value: {
      validator: value => typeof value === 'string' || value === null,
      required: true
    },
    view: {
      type: String,
      validator: value => ['grid', 'list'].includes(value),
      default: 'grid'
    }
  },
  data () {
    return {
      icons: { mdiMagnify }
    }
  },

  computed: {
    searchValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onChangeView (view) {
      this.$emit('onChangeView', view)
    }
  }
}

</script>

<style lang="scss" scoped>
.tab-description {
  opacity: 0.5;
  font-size: 18px;
  margin: 30px 0 10px;
}

.search-text-field {
  ::v-deep {
    .v-input__prepend-inner {
      z-index: 1;
    }
    fieldset {
      background-color: #fff;
      border-color: #E3E3E3;
    }
  }
}
</style>
