<template>
  <v-app>
    <div class="d-flex flex-column flex-md-row justify-content-between bg-white position-relative chart-component">
      <slot />
      <div class="left-side-wrapper d-flex flex-column justify-content-between flex-grow-1">
        <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between title-and-switcher-container">
          <!-- Title -->
          <div class="mb-3 mb-md-0 mr-md-1 text-center text-md-left">
            <h3 class="m-0 title-height">
              <slot name="title" />
            </h3>
            <p v-if="hasSubtitleSlot" class="subtitle m-0 o-50">
              <slot name="subtitle" />
            </p>
          </div>
          <!-- /Title -->
          <!-- Switcher -->
          <div v-if="hasSwitcherSlot" class="switcher-container">
            <slot name="switcher" />
          </div>
          <!-- /Switcher -->
        </div>
        <!-- Chart Wrapper -->
        <div class="chart-wrapper">
          <!-- Chart -->
          <slot name="chart" />
          <!-- /Chart -->
        </div>
        <!-- /Chart Wrapper -->
      </div>
      <!-- Right side -->
      <div class="right-side-wrapper">
        <slot name="right-side" />
      </div>
      <!-- /Right side -->
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'ChartComponent',

  computed: {
    hasSwitcherSlot () {
      return !!this.$slots.switcher
    },

    hasSubtitleSlot () {
      return !!this.$slots.subtitle
    }
  }
}
</script>

<style scoped lang="scss">
.title-height {
  min-height: 30px;
}
.chart-component {
  ::v-deep {
    .right-side {
      @include media-breakpoint-up($md) {
        border-left: 1px solid #e6ecf7;
      }
    }

    .right-side > div:first-child {
      min-width: 260px;
      max-width: 260px;

      @include media-breakpoint-up($md) {
        min-width: unset;
        max-width: unset;
      }
    }
  }

  .left-side-wrapper {
    @include media-breakpoint-up($md) {
      width: 75%;
    }

    .chart-wrapper {
      @include media-breakpoint-up($md) {
        padding: 0 20px;
      }
    }
  }

  .right-side-wrapper {
    @include media-breakpoint-up($md) {
      width: 25%;
    }
  }

  .title-and-switcher-container {
    @include media-breakpoint-up($md) {
      padding: 0 20px 0 30px;
    }

    .subtitle {
        font-family: $outfit-medium;
    }

    .switcher-container {
      min-width: 128px;
      max-width: 128px;
    }
  }
}
</style>
