export default {
  data () {
    return {
      dialogProcessCompletedVisible: false,
      showUnlock: false
    }
  },

  methods: {
    onCloseDialogProcessCompleted () {
      this.showUnlock = true
      this.dialogProcessCompletedVisible = false
    },

    openedDialogProcessCompleted () {
      this.dialogProcessCompletedVisible = true
      this.showUnlock = false
    },

    onCompleteStep () {
      this.guideVisible = false
      this.exampleVisible = false
      this.showUnlock = false
    }
  }
}
