export default {
  methods: {
    calculateRevenueStreamAmount (revenueStream, format) {
      let revenueStreamCalculated = {}

      switch (revenueStream.type) {
        case 'bl':
          revenueStreamCalculated = this.calculateBillableRateOld(revenueStream, format)
          break
        case 'or':
          revenueStreamCalculated = this.calculateOccupancyRateOld(revenueStream, format)
          break
        case 'rc':
          revenueStreamCalculated = this.calculateRecurringChargeOld(revenueStream, format)
          break
        default:
          revenueStreamCalculated = this.calculateProductAndServiceOld(revenueStream, format)
      }

      return revenueStreamCalculated
    },

    calculateProductAndServiceOld (revenueStream, format) {
      let result = {}

      const startMoment = this.$moment(revenueStream.starts)
      const endMoment = this.$moment(revenueStream.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      const monthlyInterval = {
        Monthly: 1,
        Quarterly: 3,
        Annually: 12
      }

      if (revenueStream.isOverall) {
        result = {
          name: revenueStream.name,
          values: [],
          currencySymbolIncluded: true,
          percentageSymbolIncluded: false,
          children: [
            {
              id: 'total-revenue-' + revenueStream.id,
              name: 'Total Revenue',
              values: [],
              currencySymbolIncluded: true,
              percentageSymbolIncluded: false
            }
          ]
        }

        const overallRevenueBase = revenueStream.overallRevenue

        let growthAdjustment = this.calculateValueAdjustment(overallRevenueBase, revenueStream.growth, revenueStream.isGrowthPercentage, revenueStream.isGrowthNegative)
        growthAdjustment = revenueStream.isGrowthNegative ? -growthAdjustment : growthAdjustment

        for (let i = 0; i < totalAmountOfMonths; i++) {
          const dateString = this.$moment(revenueStream.starts).add(i, 'M').format(format)

          let value = (overallRevenueBase + Math.floor(i / monthlyInterval[revenueStream.overallRevenueInterval]) * growthAdjustment) / monthlyInterval[revenueStream.overallRevenueInterval]
          value = value < 0 ? 0 : value

          const index = result.values.indexOf(
            result.values.find(valueToFind => Object.keys(valueToFind)[0] === dateString)
          )

          if (index !== -1) {
            result.values[index][dateString] += value
            result.children[0].values[index][dateString] += value
          } else {
            result.values.push({ [dateString]: value })
            result.children[0].values.push({ [dateString]: value })
          }
        }

        return result
      }

      result = {
        name: revenueStream.name,
        values: [],
        currencySymbolIncluded: true,
        percentageSymbolIncluded: false,
        children: [
          {
            id: 'product-sales-' + revenueStream.id,
            name: revenueStream.type === 'product' ? 'Product Sales' : 'Services Provided',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'product-price-' + revenueStream.id,
            name: revenueStream.type === 'product' ? 'Product Price' : 'Service Charge',
            values: [],
            currencySymbolIncluded: true,
            percentageSymbolIncluded: false
          }
        ]
      }

      const sellingPriceBase = revenueStream.sellingPrice
      const salesVolumeBase = revenueStream.salesVolume

      let sellingPriceAdjustment = this.calculateValueAdjustment(sellingPriceBase, revenueStream.priceIncrease, revenueStream.isPriceIncreasePercentage, revenueStream.isPriceIncreaseNegative)
      sellingPriceAdjustment = revenueStream.isPriceIncreaseNegative ? -sellingPriceAdjustment : sellingPriceAdjustment

      let growthAdjustment = this.calculateValueAdjustment(salesVolumeBase, revenueStream.growth, revenueStream.isGrowthPercentage, revenueStream.isGrowthNegative)
      growthAdjustment = revenueStream.isGrowthNegative ? -growthAdjustment : growthAdjustment

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateString = this.$moment(revenueStream.starts).add(i, 'M').format(format)

        let sellingPriceTotal = sellingPriceBase + Math.floor(i / monthlyInterval[revenueStream.priceIncreaseInterval]) * sellingPriceAdjustment
        sellingPriceTotal = sellingPriceTotal < 0 ? 0 : sellingPriceTotal

        let salesVolumeTotal = salesVolumeBase + Math.floor(i / monthlyInterval[revenueStream.growthInterval]) * growthAdjustment
        salesVolumeTotal = salesVolumeTotal < 0 ? 0 : salesVolumeTotal

        let value = sellingPriceTotal * salesVolumeTotal / monthlyInterval[revenueStream.salesVolumeInterval]
        value = value < 0 ? 0 : value
        sellingPriceTotal = value <= 0 ? 0 : sellingPriceTotal
        salesVolumeTotal = value <= 0 ? 0 : salesVolumeTotal

        const index = result.values.indexOf(
          result.values.find(valueToFind => Object.keys(valueToFind)[0] === dateString)
        )

        if (index !== -1) {
          result.values[index][dateString] += value
          result.children[0].values[index][dateString] += salesVolumeTotal / monthlyInterval[revenueStream.growthInterval]
        } else {
          result.values.push({ [dateString]: value })
          result.children[0].values.push({ [dateString]: salesVolumeTotal / monthlyInterval[revenueStream.growthInterval] })
        }
      }

      for (let i = 0; i < result.values.length; i++) {
        const dateString = Object.keys(result.values[i])[0]
        result.children[1].values.push({ [dateString]: result.values[i][dateString] / result.children[0].values[i][dateString] })
      }

      return result
    },

    calculateBillableRateOld (revenueStream, format) {
      let result = {}

      const startMoment = this.$moment(revenueStream.starts)
      const endMoment = this.$moment(revenueStream.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      const monthlyInterval = {
        Monthly: 1,
        Quarterly: 3,
        Annually: 12
      }

      result = {
        name: revenueStream.name,
        values: [],
        currencySymbolIncluded: true,
        percentageSymbolIncluded: false,
        children: [
          {
            id: 'billable-rate-hours-' + revenueStream.id,
            name: 'Hours',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'billable-rate-charge-' + revenueStream.id,
            name: 'Charge per hour',
            values: [],
            currencySymbolIncluded: true,
            percentageSymbolIncluded: false
          }
        ]
      }

      const billableRateBase = revenueStream.billableRate
      const servedBase = revenueStream.served

      let billableRateAdjustment = this.calculateValueAdjustment(billableRateBase, revenueStream.priceIncrease, revenueStream.isPriceIncreasePercentage, revenueStream.isPriceIncreaseNegative)
      billableRateAdjustment = revenueStream.isPriceIncreaseNegative ? -billableRateAdjustment : billableRateAdjustment

      let growthAdjustment = this.calculateValueAdjustment(servedBase, revenueStream.growth, revenueStream.isGrowthPercentage, revenueStream.isGrowthNegative)
      growthAdjustment = revenueStream.isGrowthNegative ? -growthAdjustment : growthAdjustment

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateString = this.$moment(revenueStream.starts).add(i, 'M').format(format)

        let billableRateTotal = billableRateBase + Math.floor(i / monthlyInterval[revenueStream.priceIncreaseInterval]) * billableRateAdjustment
        billableRateTotal = billableRateTotal < 0 ? 0 : billableRateTotal

        let servedTotal = servedBase + Math.floor(i / monthlyInterval[revenueStream.growthInterval]) * growthAdjustment
        servedTotal = servedTotal < 0 ? 0 : servedTotal

        let value = billableRateTotal * servedTotal / monthlyInterval[revenueStream.servedInterval]
        value = value < 0 ? 0 : value
        billableRateTotal = value <= 0 ? 0 : billableRateTotal
        servedTotal = value <= 0 ? 0 : servedTotal

        const index = result.values.indexOf(
          result.values.find(valueToFind => Object.keys(valueToFind)[0] === dateString)
        )

        if (index !== -1) {
          result.values[index][dateString] += value
          result.children[0].values[index][dateString] += servedTotal / monthlyInterval[revenueStream.growthInterval]
        } else {
          result.values.push({ [dateString]: value })
          result.children[0].values.push({ [dateString]: servedTotal / monthlyInterval[revenueStream.growthInterval] })
        }
      }

      for (let i = 0; i < result.values.length; i++) {
        const dateString = Object.keys(result.values[i])[0]
        result.children[1].values.push({ [dateString]: result.values[i][dateString] / result.children[0].values[i][dateString] })
      }

      return result
    },

    calculateOccupancyRateOld (revenueStream, format) {
      let result = {}

      const startMoment = this.$moment(revenueStream.starts)
      const endMoment = this.$moment(revenueStream.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      const monthlyInterval = {
        Daily: 0.032786885,
        Weekly: 0.229357798,
        Monthly: 1,
        Quarterly: 3,
        Annually: 12
      }

      const monthlyIntervalFormats = {
        'MMM YY': 1,
        '[Q]Q YY': 3,
        YYYY: 12
      }

      result = {
        name: revenueStream.name,
        values: [],
        currencySymbolIncluded: true,
        percentageSymbolIncluded: false,
        children: [
          {
            id: 'service-units-' + revenueStream.id,
            name: 'Service Units',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'max-turnovers-' + revenueStream.id,
            name: 'Max Turnover Per Unit',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'occupancy-rate-' + revenueStream.id,
            name: 'Occupancy Rate',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: true
          },
          {
            id: 'average-charge-' + revenueStream.id,
            name: 'Average Charge',
            values: [],
            currencySymbolIncluded: true,
            percentageSymbolIncluded: false
          }
        ]
      }

      const count = revenueStream.count
      const averageChargePerUnitBase = revenueStream.averageChargePerUnit
      const maxTurnoversPerUnitBase = revenueStream.maxTurnoversPerUnit
      const occupancyRateBase = revenueStream.occupancyRate

      let averageChargePerUnitAdjustment = this.calculateValueAdjustment(averageChargePerUnitBase, revenueStream.priceIncrease, revenueStream.isPriceIncreasePercentage)
      averageChargePerUnitAdjustment = revenueStream.isPriceIncreaseNegative ? -averageChargePerUnitAdjustment : averageChargePerUnitAdjustment

      let growthAdjustment = revenueStream.growth
      growthAdjustment = revenueStream.isGrowthNegative === true ? -growthAdjustment : growthAdjustment

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateString = this.$moment(revenueStream.starts).add(i, 'M').format(format)

        let averageChargePerUnitTotal = averageChargePerUnitBase + Math.floor(i / monthlyInterval[revenueStream.priceIncreaseInterval]) * averageChargePerUnitAdjustment
        averageChargePerUnitTotal = averageChargePerUnitTotal < 0 ? 0 : averageChargePerUnitTotal

        const maxTurnoversPerUnitTotal = Math.round(maxTurnoversPerUnitBase / monthlyInterval[revenueStream.maxTurnoversPerUnitInterval] * 10) / 10

        let occupancyRateChanged = Math.floor(i / monthlyInterval[revenueStream.growthInterval]) === 0
          ? occupancyRateBase / 100
          : (occupancyRateBase + growthAdjustment * Math.floor(i / monthlyInterval[revenueStream.growthInterval])) / 100
        occupancyRateChanged = occupancyRateChanged > 1 ? 1 : occupancyRateChanged

        let value = count * averageChargePerUnitTotal * maxTurnoversPerUnitTotal * occupancyRateChanged
        value = value < 0 ? 0 : value

        const index = result.values.indexOf(
          result.values.find(valueToFind => Object.keys(valueToFind)[0] === dateString)
        )

        if (index !== -1) {
          result.values[index][dateString] += value
          result.children[1].values[index][dateString] += maxTurnoversPerUnitTotal
          result.children[2].values[index][dateString] += occupancyRateChanged * 100
          result.children[3].values[index][dateString] += averageChargePerUnitTotal
        } else {
          result.values.push({ [dateString]: value })
          result.children[0].values.push({ [dateString]: count })
          result.children[1].values.push({ [dateString]: maxTurnoversPerUnitTotal })
          result.children[2].values.push({ [dateString]: occupancyRateChanged * 100 })
          result.children[3].values.push({ [dateString]: averageChargePerUnitTotal })
        }
      }

      for (let i = 0; i < result.values.length; i++) {
        const dateString = Object.keys(result.values[i])[0]
        result.children[2].values[i][dateString] /= monthlyIntervalFormats[format]
        result.children[3].values[i][dateString] /= monthlyIntervalFormats[format]
      }

      return result
    },

    calculateRecurringChargeOld (revenueStream, format) {
      let result = {}

      const startMoment = this.$moment(revenueStream.starts)
      const endMoment = this.$moment(revenueStream.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      const monthlyInterval = {
        Monthly: 1,
        Quarterly: 3,
        Annually: 12
      }

      result = {
        name: revenueStream.name,
        values: [],
        currencySymbolIncluded: true,
        percentageSymbolIncluded: false,
        children: [
          {
            id: 'acquired-customers-' + revenueStream.id,
            name: 'Acquired Customers',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'total-acquired-' + revenueStream.id,
            name: 'Total acquired',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'customer-churn-per-' + revenueStream.id,
            name: 'Customer Churn %',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: true
          },
          {
            id: 'customer-churn-' + revenueStream.id,
            name: 'Customer Churn',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'subscriber-base-' + revenueStream.id,
            name: 'Subscriber Base',
            values: [],
            currencySymbolIncluded: false,
            percentageSymbolIncluded: false
          },
          {
            id: 'recurring-charge-' + revenueStream.id,
            name: 'Recurring Charge',
            values: [],
            currencySymbolIncluded: true,
            percentageSymbolIncluded: false
          },
          {
            id: 'upfront-fee-' + revenueStream.id,
            name: 'Upfront Fee',
            values: [],
            currencySymbolIncluded: true,
            percentageSymbolIncluded: false
          }
        ]
      }

      const recurringChargeBase = revenueStream.recurringCharge
      const newSignUpsBase = revenueStream.newSignUps
      let subscriberBase = 0
      let totalAcquired = 0
      const monthChurn = {}
      const calcChurn = {}

      let growthAdjustment = this.calculateValueAdjustment(newSignUpsBase, revenueStream.growth, revenueStream.isGrowthPercentage)
      growthAdjustment = revenueStream.isGrowthNegative ? -growthAdjustment : growthAdjustment

      let recurringChargeAdjustment = this.calculateValueAdjustment(recurringChargeBase, revenueStream.priceIncrease, revenueStream.isPriceIncreasePercentage)
      recurringChargeAdjustment = revenueStream.isPriceIncreaseNegative ? -recurringChargeAdjustment : recurringChargeAdjustment

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateString = this.$moment(revenueStream.starts).add(i, 'M').format(format)

        const acquiredCustomers = newSignUpsBase / monthlyInterval[revenueStream.growthInterval] + growthAdjustment / monthlyInterval[revenueStream.growthInterval] * Math.floor(i / monthlyInterval[revenueStream.growthInterval])

        for (let j = 0; j < i; j++) {
          let churnThisMonth = 0
          const dateStringChurn = this.$moment(revenueStream.starts).add(j, 'M').format(format)
          const acquiredPrevPeriod = typeof result.children[0].values[j] === 'undefined' ? 0 : result.children[0].values[j][dateStringChurn]
          if (typeof monthChurn[dateStringChurn] === 'undefined') {
            monthChurn[dateStringChurn] = 0
          }
          if (monthChurn[dateStringChurn] < acquiredPrevPeriod) {
            if (acquiredPrevPeriod * revenueStream.churn / 100 + monthChurn[dateStringChurn] > acquiredPrevPeriod) {
              churnThisMonth = acquiredPrevPeriod - monthChurn[dateStringChurn]
            } else {
              churnThisMonth = acquiredPrevPeriod * revenueStream.churn / 100
            }
            monthChurn[dateStringChurn] += churnThisMonth
          }
          if (typeof calcChurn[dateString] === 'undefined') {
            calcChurn[dateString] = 0
          }

          calcChurn[dateString] += churnThisMonth
        }

        totalAcquired += acquiredCustomers
        subscriberBase += acquiredCustomers - (typeof calcChurn[dateString] !== 'undefined' ? calcChurn[dateString] : 0)

        let recurringChargeTotal = recurringChargeBase / monthlyInterval[revenueStream.recurringChargeInterval] + recurringChargeAdjustment * Math.floor(i / monthlyInterval[revenueStream.recurringChargeInterval])
        recurringChargeTotal = recurringChargeTotal < 0 ? 0 : recurringChargeTotal

        let value = revenueStream.upfrontFee * acquiredCustomers + subscriberBase * recurringChargeTotal
        value = value < 0 ? 0 : value

        const index = result.values.indexOf(
          result.values.find(valueToFind => Object.keys(valueToFind)[0] === dateString)
        )

        if (index !== -1) {
          result.values[index][dateString] += value
          result.children[0].values[index][dateString] += acquiredCustomers
          result.children[1].values[index][dateString] += totalAcquired
          result.children[2].values[index][dateString] += revenueStream.churn
          result.children[3].values[index][dateString] += calcChurn[dateString]
          result.children[4].values[index][dateString] += subscriberBase
          result.children[5].values[index][dateString] += recurringChargeTotal
          result.children[6].values[index][dateString] += revenueStream.upfrontFee
        } else {
          result.values.push({ [dateString]: value })
          result.children[0].values.push({ [dateString]: acquiredCustomers })
          result.children[1].values.push({ [dateString]: totalAcquired })
          result.children[2].values.push({ [dateString]: revenueStream.churn })
          result.children[3].values.push({ [dateString]: calcChurn[dateString] })
          result.children[4].values.push({ [dateString]: subscriberBase })
          result.children[5].values.push({ [dateString]: recurringChargeTotal })
          result.children[6].values.push({ [dateString]: revenueStream.upfrontFee })
        }
      }

      return result
    },

    calculateRevenueStreamAverageAmount (revenueStream) {
      let result = 0

      const revenueStreamCalculated = this.calculateRevenueStreamAmount(revenueStream, 'MMM YY')

      for (let i = 0; i < revenueStreamCalculated.values.length; i++) {
        result += revenueStreamCalculated.values[i][Object.keys(revenueStreamCalculated.values[i])[0]]
      }

      return this.getFormattedAmount(Math.round(result /= revenueStreamCalculated.values.length))
    },

    calculateRevenueStreamTotalAmount (revenueStream) {
      let result = 0

      const revenueStreamCalculated = this.calculateRevenueStreamAmount(revenueStream, 'MMM YY')

      for (let i = 0; i < revenueStreamCalculated.values.length; i++) {
        result += revenueStreamCalculated.values[i][Object.keys(revenueStreamCalculated.values[i])[0]]
      }

      return this.getFormattedAmount(Math.round(result))
    },

    // TODO - Refactor
    calculateValueAdjustment (mainValue, mainValueChange, isPercentage) {
      let adjustmentValue = 0
      if (isPercentage) {
        adjustmentValue = mainValue * mainValueChange * 0.01
      } else {
        adjustmentValue = mainValueChange
      }

      return adjustmentValue
    },

    getRevenueStream (revenueStreamId) {
      const index = this.getIdea().storyMode.forecast.revenueStreams.revenueStreams.indexOf(
        this.getIdea().storyMode.forecast.revenueStreams.revenueStreams.find(revenueStreamToFind => revenueStreamToFind.id === revenueStreamId)
      )

      return this.getIdea().storyMode.forecast.revenueStreams.revenueStreams[index]
    },

    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    }
  }
}
