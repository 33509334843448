import { TimelineMax, gsap } from 'gsap'

const priceAnimation = (
  priceCrossRender,
  priceCrossLineRender,
  priceMainRender,
  priceBilledRender
) => {
  if (priceCrossRender !== null) {
    gsap.fromTo(
      priceCrossRender,
      { autoAlpha: 0, opacity: 0, y: -5 },
      { duration: 0.5, autoAlpha: 1, opacity: 1, y: 0, delay: 0.1 }
    )
    gsap.fromTo(
      priceCrossLineRender,
      { autoAlpha: 0, opacity: 0, width: 0 },
      { duration: 0.25, autoAlpha: 1, opacity: 1, width: '100%', delay: 0.25 }
    )
    gsap.fromTo(
      priceMainRender,
      { autoAlpha: 0, opacity: 0, y: 5 },
      { duration: 0.5, autoAlpha: 1, opacity: 1, y: 0, delay: 0.75 }
    )
    gsap.fromTo(
      priceBilledRender,
      { autoAlpha: 0, opacity: 0, y: 5 },
      { duration: 0.5, autoAlpha: 1, opacity: 1, y: 0, delay: 0.75 }
    )
  }
}

const priceMonthlyAnimation = (priceMainRender) => {
  if (priceMainRender !== null) {
    gsap.fromTo(
      priceMainRender,
      { autoAlpha: 0, opacity: 0, y: 5 },
      { duration: 0.5, autoAlpha: 1, opacity: 1, y: 0, delay: 0.25 }
    )
  }
}

const aiPillAnimation = (item, index) => {
  if (item) {
    const tl = new TimelineMax({ delay: (index + 1) * 0.25 })

    tl.from(item, 0.25, { autoAlpha: 0, x: index === 0 ? 0 : 10 })
    tl.to(item, 0.25, { autoAlpha: 1, x: 0 })
  }
}

export {
  aiPillAnimation,
  priceAnimation,
  priceMonthlyAnimation
}
