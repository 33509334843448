import 'quill-paste-smart'

export default {
  clipboard: {
    allowed: {
      tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
      attributes: ['href', 'rel', 'target', 'class']
    },
    substituteBlockElements: true
  }
}
