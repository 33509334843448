<template>
  <business-guide-card-wrapper
    class="set-up-and-projections-card"
    @click="$emit('edit', entity)"
  >
    <!-- Grid view -->
    <template #top-grid>
      <actions-dropdown
        v-if="canEdit && !disabled"
        class="actions"
        :entity="entity"
        :delete-disabled="deleteDisabled"
        :copy-disabled="copyDisabled"
        :edit-disabled="editDisabled"
        @edit="$emit('edit', $event)"
        @copy="$emit('copy', $event)"
        @delete="$emit('delete', $event)"
      />
      <div class="avatar-holder" :style="cardImage()" />
    </template>
    <template #bottom-grid>
      <div
        class="ib-card-bottom d-flex flex-column justify-content-around text-center"
        :class="frequency ? 'min-height-198' : 'min-height-176'"
      >
        <div class="d-flex flex-column flex-grow-1 py-3 justify-content-between">
          <p class="m-0 mb-2 name grid three-dots">
            {{ name }}
          </p>
          <p class="m-0 date grid three-dots">
            {{ date }}
          </p>
        </div>
        <div v-if="amount" class="frequency-wrapper text-center py-3">
          <p class="m-0 amount grid">
            {{ currencySymbol }}{{ amount }}
          </p>
          <p v-if="frequency" class="m-0 payroll-frequency grid">
            {{ frequency }}
          </p>
        </div>
      </div>
    </template>
    <!-- List view -->
    <template #list>
      <div>
        <div class="avatar-holder circle mr-2" :style="cardImage()" />
      </div>
      <div class="d-flex flex-grow-1 flex-column w-50">
        <span class="name list three-dots">{{ name }}</span>
        <span class="date list three-dots">
          {{ date }}
        </span>
      </div>
      <div class="d-flex align-items-center mr-3">
        <div v-if="amount" class="d-flex flex-column align-items-end text-right">
          <span class="amount list">
            {{ currencySymbol }}{{ amount }}
          </span>
          <span v-if="frequency" class="frequency list">
            {{ frequency }}
          </span>
        </div>
      </div>
      <div class="actions-grid-wrapper">
        <actions-dropdown
          v-if="canEdit && !disabled"
          class="actions"
          :entity="entity"
          :delete-disabled="deleteDisabled"
          :copy-disabled="copyDisabled"
          :edit-disabled="editDisabled"
          @edit="$emit('edit', $event)"
          @copy="$emit('copy', $event)"
          @delete="$emit('delete', $event)"
        />
      </div>
    </template>
  </business-guide-card-wrapper>
</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import BusinessGuideCardWrapper from '@/views/Home/StoryMode/Components/Cards/BusinessGuideCardWrapper'
import MixinCurrencies from '@/mixins/currencies'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { imageCardTypes } from '@/data/imageCardTypes'

export default {
  name: 'SetUpAndProjectionsCard',

  components: {
    BusinessGuideCardWrapper,
    ActionsDropdown
  },

  mixins: [MixinIdeaRoles, MixinCurrencies],

  provide () {
    return {
      view: this.view
    }
  },

  props: {
    entity: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      validator: value => ['grid', 'list'].includes(value),
      default: 'grid'
    },
    type: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    amount: {
      type: [Number, String],
      default: 0
    },
    frequency: {
      type: String,
      default: ''
    },
    deleteDisabled: {
      type: Boolean,
      default: false
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      imageCardTypes
    }
  },

  methods: {
    cardImage () {
      return 'background-image: url(' + this.imageCardTypes[this.type] + ')'
    }
  }
}
</script>

<style scoped lang="scss">

.set-up-and-projections-card.grid {
  ::v-deep {
    .ib-card-top {
      min-height: 150px;
      border-bottom: 1px solid #dcdbdc;
    }

    .ib-card-bottom {
      padding: 0 10px;
    }
  }
}

.min-height-176 {
  min-height: 176px;
}

.min-height-198 {
  min-height: 198px;
}

.frequency-wrapper {
  border-top: 1px solid #dcdbdc;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.name.grid {
  font-size: 18px;
  -webkit-line-clamp: 2;
}

.name.list {
  font-size: 17px;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.date {
  color: $color-yellow;
  font-size: 13px;
}

.frequency.grid {
  font-size: 14px;
}

.frequency.list {
  opacity: 0.5;
  font-size: 13px;
}

.amount {
  font-family: outfit-semi-bold;

  &.grid {
    font-size: 24px;
    color: $color-navy;
  }

  &.list {
    font-size: 16px;

    @include media-breakpoint-up($lg) {
      font-size: 18px;
    }
  }
}

.set-up-and-projections-card.list .avatar-holder.circle {
  background-size: 20px;
  background-color: #f6f9f9;
}

.actions-grid-wrapper {
  position: absolute;
  right: 15px;
}
</style>
