<template>
  <div class="dashboard-switch-wrapper d-flex align-items-center justify-content-center">
    <ib-tooltip :content="`${$t('tooltipToggleStatus')}`">
      <!--    <v-app>-->
      <v-switch
        v-model="value"
        color="success"
        :label="`${value ? $t('completed') : $t('inProgress')}`"
        hide-details
        :disabled="!canEdit"
        :class="`${value ? 'completed' : 'inProgress'}`"
      />
    <!--    </v-app>-->
    </ib-tooltip>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapActions } from 'vuex'

export default {
  name: 'StepStatusSwitch',

  mixins: [MixinIdeaRoles],

  props: {
    stepName: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      value: false
    }
  },

  computed: {
    statusOfCompleted () {
      return this.$store.state.idea.storyMode.common.completedSteps[this.stepName]
    }
  },

  watch: {
    statusOfCompleted (value) {
      if (!this.canEdit) return
      this.value = value
    },

    value () {
      if (!this.canEdit) return
      EventBus.$emit('change-step-status', this.value)
    }
  },

  created () {
    this.value = this.statusOfCompleted
  },

  mounted () {
    EventBus.$on('return-step-in-progress', () => {
      this.value = false
    })
  },

  beforeDestroy () {
    EventBus.$off('return-step-in-progress')
  },

  methods: {
    ...mapActions('idea', ['completeStep'])
  }
}
</script>

<style scoped lang="scss">
.dashboard-switch-wrapper {
  margin-left: 15px !important;

  @include media-breakpoint-up($lg) {
    margin-left: 25px !important;
  }
  ::v-deep {
    label {
      font-family: $outfit-regular;
      display: none !important;

      @include media-breakpoint-up($md) {
        display: flex !important;
      }
    }
  }
}

//.completed {
//  ::v-deep {
//    label {
//      opacity: 1;
//    }
//  }
//}

.inProgress {
  ::v-deep {
    label {
      color: $color-text-light-gray;
    }
  }
}

</style>
