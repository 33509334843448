<template>
  <div class="ai-buddy-button-rounded font-outfit-regular">
    <i
      class="ib-icon ib-icon-magic-2 cursor-pointer"
      @click="onClick"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'AiBuddyButtonRounded',

  props: {
    emitEventBussEvents: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick () {
      this.$emit('click')

      if (this.emitEventBussEvents) {
        EventBus.$emit('open-ai-buddy-suggestion')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ai-buddy-button-rounded {
  background: $color-ai-light;
  border: 2px solid $color-ai-light;
  border-radius: 50%;
  transition: all 0.25s;

  &:hover {
    border: 2px solid $color-ai;
  }

  .ib-icon {
    font-size: 22px;
    padding: 8px;
    color: $color-ai;
  }
}

</style>
