<template>
  <div>
    <!-- Button -->
    <ib-button
      class="px-3"
      variant="white"
      font-family="regular"
      :disabled="disabled"
      @click="onClick"
    >
      <template slot="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" v-bind:svg-inline="''" v-bind:class="'mr-3'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#bec0c9" fill-rule="evenodd" d="M11 18h9v-2h-9v-2H9v2H0v2h9v2h2v-2zm9-7V9h-4V7h-2v2H0v2h14v2h2v-2h4zm-9-7v2H9V4H0V2h9V0h2v2h9v2h-9z" opacity=".85"/></svg>
      </template>
      <span class="d-md-none">{{ $t('pages.businessGuide.staffAndPayroll.laborBurden') }}</span>
      <span class="d-none d-md-block">{{ $t('pages.businessGuide.staffAndPayroll.setLaborBurden') }}</span>
    </ib-button>

    <!-- Set Labor Burden Dialog -->
    <dialog-set-labor-burden
      :dialog-set-labor-burden-visible="dialogVisible"
      :labor-burden-action="getLaborBurden"
      @close-dialog-set-labor-burden="dialogVisible = false"
      @on-save="$emit('on-save')"
    />
    <!-- /Set Labor Burden Dialog -->
  </div>
</template>

<script>
import DialogSetLaborBurden from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Dialogs/DialogSetLaborBurden'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapGetters } from 'vuex'

export default {
  name: 'SetLaborBurden',

  components: {
    DialogSetLaborBurden
  },

  mixins: [MixinIdeaRoles],

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dialogVisible: false
    }
  },

  computed: {
    ...mapGetters('idea', ['getLaborBurden'])
  },

  methods: {
    onClick () {
      if (!this.canEdit) return
      this.dialogVisible = true
    }
  }
}
</script>
