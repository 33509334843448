<template>
  <p class="ib-loading-text font-outfit-medium">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'IbLoadingText',

  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.ib-loading-text {
  margin: 0;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}
</style>
