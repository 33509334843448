<template>
  <!-- Dialog Complete Step -->
  <ib-dialog
    :visible="visible"
    :closable="false"
    top="8vh"
    empty-header
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/fogg-no-comments.svg')"
      :title="$t('dialogs.completeStep.title')"
    />

    <div class="text-wrapper text-center">
      <p class="m-0">
        {{ $t('dialogs.completeStep.subtitleFirst') }}
      </p>
      <p class="m-0">
        {{ $t('dialogs.completeStep.subtitleSecond') }}
      </p>
    </div>
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('dialogs.completeStep.complete')"
        :cancel-btn-text="$t('dialogs.completeStep.notYet')"
        space-between-buttons
        @cancel="closeDialog"
        @submit="completeStep"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Complete Step -->
</template>

<script>
export default {
  name: 'DialogCompleteStep',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    completeStep () {
      this.$emit('complete-step')
    }
  }
}
</script>
<style scoped lang="scss">
.text-wrapper {
  p {
    font-size: 1.5rem;
    opacity: 0.5;
  }
}
</style>
