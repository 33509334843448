<template>
  <div class="text-center text-md-left">
    <ib-tooltip
      v-if="Math.abs(amount) >= 1.0e+7"
      :content="`${text}: ${chartTooltipDisplay(amount)}`"
      placement="right"
      :open-delay="0"
    >
      <p class="m-0 mb-1 amount">
        {{ chartValueFormatter(amount) }}
      </p>
    </ib-tooltip>
    <p v-else class="m-0 mb-1 amount">
      {{ chartValueFormatter(amount) }}
    </p>
    <p class="m-0 text-small">
      {{ text }}
    </p>
  </div>
</template>

<script>
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'TotalAmount',

  mixins: [MixinCurrencies],

  props: {
    amount: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.amount {
  font-family: outfit-semi-bold;
  font-size: 22px;
  line-height: 1;
}
</style>
