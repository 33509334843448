import { mapActions, mapGetters } from 'vuex'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {

  mixins: [MixinUserSubscription],

  computed: {
    ...mapGetters('user/gamification', ['hasGamificationAndAwardNotActivated', 'getPercentage'])
  },

  methods: {
    ...mapActions('user/gamification', ['fetchGamification']),

    handleGamification () {
      if (!this.subscriptionIsFreeTrial) return

      if (this.hasGamificationAndAwardNotActivated) {
        this.fetchGamification().then(() => {
          this.$intercom.update({
            GamificationProgress: this.getPercentage
          })
        })
      }
    }
  }
}
