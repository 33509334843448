<template>
  <div
    class="show-more"
    @click="$emit('click')"
  >
    <p>{{ show ? $t('showLess') : $t('showAll') }}</p>
  </div>
</template>

<script>
export default {
  name: 'ShowAllToggle',

  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>
