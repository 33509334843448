import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      headerScrollOffsetTop: null
    }
  },

  computed: {
    ...mapGetters('theme', ['isMenuCollapsed'])
  },

  watch: {
    isMenuCollapsed (value) {
      const header = document.querySelector('.page-header')

      if (header) {
        value ? header.classList.add('collapse') : header.classList.remove('collapse')
      }
    }
  },

  methods: {
    handleHeaderScroll () {
      const header = document.querySelector('.page-header')
      if (header) {
        // const fakeDiv = document.querySelector('.height-holder')
        // const h = header.offsetHeight
        const headerTopOffset = 12
        const pageContentEl = document.querySelector('.page-content')
        const scrollY = pageContentEl.scrollTop
        const scrollbarWidth = pageContentEl.offsetWidth - pageContentEl.clientWidth
        const scrollFixed = this.headerScrollOffsetTop + headerTopOffset

        if (
          scrollY >= scrollFixed &&
          !header.className.includes('header-fixed')
        ) {
          header.classList.add('header-fixed')
          if (this.isMenuCollapsed) {
            header.classList.add('collapse')
          }
          header.style.right = `${scrollbarWidth}px`
          // fakeDiv.style.height = `${h}px`
        } else if (scrollY < scrollFixed) {
          header.classList.remove('header-fixed')
          if (this.isMenuCollapsed) {
            header.classList.remove('collapse')
          }
          header.style.removeProperty('right')
          // fakeDiv.style.removeProperty('height')
        }
      }
    }
  },

  mounted () {
    document.querySelector('.page-content').addEventListener('scroll', this.handleHeaderScroll)
    this.headerScrollOffsetTop = document.querySelector('.page-header').offsetTop
  },

  destroyed () {
    document.querySelector('.page-content').removeEventListener('scroll', this.handleHeaderScroll)
  }
}
