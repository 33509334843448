export default {
  data () {
    return {
      periodMonthsNumber: {
        monthly: 1,
        quarterly: 3,
        annually: 12
      }
    }
  },

  methods: {
    getSalary (employee, prevSalary, periodsCounter, periodMonthsNumber) {
      let salary = employee.salary

      if (prevSalary) {
        prevSalary = salary = this.adjustedSalary(employee, prevSalary, periodsCounter)
      }

      if (periodsCounter % periodMonthsNumber === 0) {
        prevSalary = salary
      }

      return [prevSalary, ((salary * employee.howMany) / periodMonthsNumber)]
    },

    adjustedSalary (employee, prevSalary, periodsCounter) {
      let adjustedSalary = prevSalary

      const adjustmentIntervalExist = Object.prototype.hasOwnProperty.call(this.periodMonthsNumber, employee.payAdjustmentIntervalType.toLowerCase())

      if (adjustmentIntervalExist) {
        if ((periodsCounter - 1) % this.periodMonthsNumber[employee.payAdjustmentIntervalType.toLowerCase()] === 0) {
          let payAdjustmentAmount = employee.payAdjustmentAmount

          if (employee.isPayPercentage) {
            payAdjustmentAmount = (employee.salary * payAdjustmentAmount) / 100
          }

          if (employee.payAdjustmentPrefix === '+') {
            adjustedSalary = prevSalary + payAdjustmentAmount
          }

          if (employee.payAdjustmentPrefix === '-') {
            adjustedSalary = prevSalary - payAdjustmentAmount
          }
        }
      }

      return adjustedSalary
    },

    calculateExpenses (employee, salary, paymentFrequency, paymentPercentage, periodsCounter) {
      let expenses = 0
      const expensesIntervalExist = Object.prototype.hasOwnProperty.call(this.periodMonthsNumber, paymentFrequency.toLowerCase())

      if ((employee.type === 'fti' || employee.type === 'ftg') && expensesIntervalExist) {
        if (periodsCounter % this.periodMonthsNumber[paymentFrequency.toLowerCase()] === 0) {
          expenses = (salary * paymentPercentage) / 100
        }
      }

      return expenses
    },

    addNonExistingPeriod (valueObject, period) {
      if (typeof valueObject[period] === 'undefined') {
        valueObject[period] = 0
      }
    }
  }
}
