<template>

  <!-- View Switcher -->
  <div v-show="visible" class="switcher-wrapper d-flex align-items-center">

    <!-- Block View -->
    <div class="default" :class="{ 'active': activeView === 'grid' }" @click="activateView('grid')">
      <svg width="22" height="13" viewBox="0 0 22 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 0h4a1 1 0 011 1v11a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm8 0h4a1 1 0 011 1v11a1 1 0 01-1 1H9a1 1 0 01-1-1V1a1 1 0 011-1zm8 0h4a1 1 0 011 1v11a1 1 0 01-1 1h-4a1 1 0 01-1-1V1a1 1 0 011-1z" fill="none" fill-rule="evenodd"/></svg>
    </div>
    <!-- /Block View -->

    <!-- List View -->
    <div class="list" :class="{ 'active': activeView === 'list' }" @click="activateView('list')">
      <svg width="20" height="13" viewBox="0 0 20 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 0h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm0 5h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1zm0 5h18a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1v-1a1 1 0 011-1z" fill="none" fill-rule="evenodd" opacity=".205"/></svg>
    </div>
    <!-- /List View -->

  </div>
  <!-- /View Switcher -->

</template>

<script>
export default {
  name: 'ViewSwitcher',

  props: {
    activeView: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    activateView (view) {
      if (this.activeView === view) {
        return
      }

      this.$gtm.trackEvent({
        event: view + 'View'
      })

      this.$emit('activate-view', view)
    }
  }
}
</script>

<style scoped>
.switcher-wrapper .default, .switcher-wrapper .list {
  width: 44px;
  height: 44px;
  border: solid 1px #e7e7e7;
  background-color: #fafbff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.switcher-wrapper .default:hover, .switcher-wrapper .list:hover {
  transition: all 0.25s;
  cursor: pointer;
  background-color: #fff;
}
.switcher-wrapper .default svg path, .switcher-wrapper .list svg path {
  opacity: 0.5;
  fill: rgba(84, 89, 113, .4);
}
.switcher-wrapper .default.active, .switcher-wrapper .list.active {
  background-color: #fff;
}
.switcher-wrapper .default.active svg path, .switcher-wrapper .list.active svg path {
  opacity: 1;
  fill: #0b42b0;
}
.switcher-wrapper .default {
  border-radius: 4px 0 0 4px;
}
.switcher-wrapper .list {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}

</style>
