<template>

  <!-- Staff Payroll -->
  <div id="story-mode-staff-payroll">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.staffAndPayroll" />

      <!-- Content -->
      <div
        v-if="loaded.staffAndPayroll"
        class="content"
        :class="{ 'position-relative': isStaffAndPayrollCompleted }"
      >
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': isStaffAndPayrollCompleted }"
              :title="$t('steps.setUp.items.staffAndPayroll')"
              :guide-visible="guideVisible"
              :completed-step-name="'employeesAndPayrollCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
        <!-- /Page Header -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isStaffAndPayrollEmpty" class="table-view">

          <!-- Tabs -->
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
          <!-- /Tabs -->

          <!-- Set Labor Burden Button -->
          <div class="table-btn__wrapper">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isStaffAndPayrollCompleted"
              @click="checkStaffPayroll"
            />
            <!-- /Completed Overlay -->

            <!-- Set Labor Burden -->
            <set-labor-burden :disabled="!canEdit" @on-save="getStaffAndPayroll" />
            <!-- /Set Labor Burden -->
          </div>
          <!-- /Set Labor Burden Button -->
        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div v-if="!isStaffAndPayrollEmpty" class="table-view filled">

          <!-- Tabs -->
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="switcher-inner-wrapper">
                <distribution-of-payroll-expenses
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <payroll-expenses-over-time
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <staff-and-payroll-table class="pt-4" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
          <!-- /Tabs -->

          <!-- Set Labor Burden Button -->
          <div class="table-btn__wrapper">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isStaffAndPayrollCompleted"
              @click="checkStaffPayroll"
            />
            <!-- /Completed Overlay -->

            <!-- Set Labor Burden -->
            <set-labor-burden :disabled="!canEdit" @on-save="getStaffAndPayroll" />
            <!-- /Set Labor Burden -->
          </div>
          <!-- /Set Labor Burden Button -->
        </div>
        <!-- /Charts And Table Filled -->

        <v-app><!-- Header -->
          <business-guide-header>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                :class="{ 'mr-2': !isStaffAndPayrollEmpty }"
                @click="onEraseFromTemplate"
              />
              <business-guide-search
                v-if="!isStaffAndPayrollEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="!isStaffAndPayrollEmpty"
                :active-view="$store.state.itemsView.staffPayrollView"
                @activate-view="onActivateView"
              />
            </template>
          </business-guide-header>
          <!-- /Header -->
        </v-app>

        <!-- List Wrapper -->
        <div
          class="list-wrapper"
          :class="{ 'active': $store.state.itemsView.staffPayrollView === 'list' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isStaffAndPayrollCompleted"
              @click="checkStaffPayroll"
            />
            <!-- /Completed Overlay -->
            <!-- Add Payroll Expense -->
            <el-col :md="12" class="d-flex mb-2">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$t('steps.setUp.items.staffAndPayroll')"
                horizontal
                :disabled="!canEdit || isStaffAndPayrollCompleted"
                @click="onAddStaffAndPayroll"
              />
            </el-col>
            <!-- /Add Payroll Expense -->

            <!-- Placeholder 1 -->
            <el-col
              v-if="isStaffAndPayrollEmpty && $breakpoint.mdAndUp"
              class="d-flex"
              :md="12"
            >
              <div class="placeholder blue w-100">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->
            <el-col
              v-if="isStaffAndPayrollEmpty && $breakpoint.mdAndUp"
              :md="12"
            >
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Placeholder 3 -->
            <el-col
              v-if="isStaffAndPayrollEmpty && $breakpoint.mdAndUp"
              :md="12"
            >
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 3 -->

            <!-- Tutorial Tooltip  TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="$store.state.user.tutorial.employeesAndPayrollCompleted === false && 1 === 2"
              :title="$t('pages.businessGuide.staffAndPayroll.addCustomersWantToTarget')"
              :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
              :tutorial-name="'AddStaffAndPayrollCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="staffPayroll in staffAndPayrollToShowList"
              :key="staffPayroll.id"
              class="d-flex"
              :md="12"
            >
              <div class="d-flex w-100" @click="checkStaffPayroll">
                <set-up-and-projections-card
                  class="mb-2 w-100"
                  :entity="staffPayroll"
                  :name="staffPayroll.name"
                  :date="staffPayrollDate(staffPayroll)"
                  :amount="getFormattedAmount(staffPayroll.average)"
                  :frequency="$t('pages.businessGuide.monthlyAverage')"
                  :type="staffPayroll.type"
                  :disabled="isStaffAndPayrollCompleted"
                  view="list"
                  @edit="onEditStaffAndPayroll"
                  @copy="onCopyStaffAndPayroll"
                  @delete="onDeleteStaffAndPayroll"
                />
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllStaffAndPayrollList"
                @click="showAllStaffAndPayrollList = !showAllStaffAndPayrollList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

        <!-- Grid Wrapper -->
        <div class="grid-wrapper" :class="{ 'active': $store.state.itemsView.staffPayrollView === 'grid' }">
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isStaffAndPayrollCompleted"
              @click="checkStaffPayroll"
            />
            <!-- /Completed Overlay -->
            <!-- Add Payroll Expense -->
            <el-col :md="8" :xl="6" class="d-flex mb-4 width-lg-20">
              <add-new-card
                class="width-xl-20"
                :title="$t('addPayrollExpense')"
                :disabled="!canEdit || isStaffAndPayrollCompleted"
                @click="onAddStaffAndPayroll"
              />
            </el-col>
            <!-- /Add Payroll Expense -->

            <!-- Grid Items -->
            <el-col
              v-for="staffPayroll in staffAndPayrollToShowGrid"
              :key="staffPayroll.id"
              class="d-flex mb-4 width-lg-20"
              :md="8"
              :xl="6"
            >
              <div class="d-flex w-100" @click="checkStaffPayroll">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="staffPayroll"
                  :name="staffPayroll.name"
                  :date="staffPayrollDate(staffPayroll)"
                  :amount="getFormattedAmount(staffPayroll.average)"
                  :frequency="$t('pages.businessGuide.monthlyAverage')"
                  :type="staffPayroll.type"
                  :disabled="isStaffAndPayrollCompleted"
                  @edit="onEditStaffAndPayroll"
                  @copy="onCopyStaffAndPayroll"
                  @delete="onDeleteStaffAndPayroll"
                />
              </div>
            </el-col>
            <!-- /Grid Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length > 4">
            <el-col>
              <show-all-toggle
                :show="showAllStaffAndPayrollGrid"
                @click="showAllStaffAndPayrollGrid = !showAllStaffAndPayrollGrid"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /Grid Wrapper -->
      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'setup'"
        :step="'staffAndPayroll'"
        :concept-name="'employeesAndPayrollMoreDetails'"
        :completed-step-name="'employeesAndPayrollCompleted'"
      />
      <!-- /Add More Details -->

      <!-- Add Staff And Payroll-->
      <dialog-add-staff
        :dialog-add-staff-visible="dialogAddStaffVisible"
        :staff-and-payroll-action="staffAndPayrollAction"
        @close-dialog-add-staff="onCloseDialogAddStaff"
        @close-dialog-on-button="onCloseDialogAddStaff"
        @on-update-staff-and-payroll-view="getStaffAndPayroll"
      />
      <!-- /Add Staff And Payroll -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'employeesAndPayrollCompleted'"
      :step-filled="idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="employeesAndPayrollExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="staffAndPayroll"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Staff Payroll -->
</template>

<script>

import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddStaff from './Dialogs/DialogAddStaff'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DistributionOfPayrollExpenses from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/PayrollExpense/DistributionOfPayrollExpenses'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import PayrollExpensesOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/PayrollExpense/PayrollExpensesOverTime'
import SetLaborBurden from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/SetLaborBurden'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import StaffAndPayrollTable from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Tables/StaffAndPayrollTable'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'StaffAndPayroll',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddNewCard,
    AddMoreDetails,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddStaff,
    DialogProcessCompleted,
    DialogRemoveExamples,
    DistributionOfPayrollExpenses,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    PayrollExpensesOverTime,
    SetLaborBurden,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    StaffAndPayrollTable,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinProcessCompleted,
    MixinGuide,
    MixinApiCalculations,
    MixinProcessCompleted,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      removeExamplesLoading: false,
      dialogRemoveExamplesVisible: false,
      activeName: 'first',
      dialogAddStaffVisible: false,
      guideVisible: false,
      staffAndPayrollAction: null,
      showAllStaffAndPayrollGrid: false,
      showAllStaffAndPayrollList: false,
      dialogProcessCompletedVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      staffAndPayrolls: 'getStaffAndPayrolls',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    isStaffAndPayrollEmpty () {
      return !this.staffAndPayrolls.length
    },

    isStaffAndPayrollCompleted () {
      return this.getStepIsCompleted('employeesAndPayrollCompleted')
    },

    staffAndPayrollToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterstaffAndPayroll
      }

      return this.showAllStaffAndPayrollGrid === true
        ? this.staffAndPayrolls
        : this.staffAndPayrolls.slice(0, 4)
    },

    staffAndPayrollToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterstaffAndPayroll
      }

      return this.showAllStaffAndPayrollList === true
        ? this.staffAndPayrolls
        : this.staffAndPayrolls.slice(0, 7)
    },

    filterstaffAndPayroll () {
      return this.staffAndPayrolls.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getStaffAndPayrolls')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.managementTeam'),
        route: 'business-guide-set-up-management-team'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.ownership'),
        route: 'business-guide-set-up-ownership'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getStaffAndPayroll()
    }
  },

  async created () {
    // getStaffAndPayroll from mixin is used for calculations
    await this.getStaffAndPayroll()
    // fetchStaffAndPayroll is used for fresh data (Staff and Payrolls & Labor Burden)
    await this.fetchStaffAndPayroll()
    this.openGuide('staffPayrollGuide')
  },

  methods: {
    ...mapActions('idea', {
      fetchStaffAndPayroll: 'getStaffAndPayroll',
      deleteStaffAndPayroll: 'deleteStoryModeStaffAndPayroll',
      deleteIdeaExamples: 'deleteIdeaExamples'
    }),

    staffPayrollDate (staffPayroll) {
      return `${this.getFormattedDate(staffPayroll.salaryStarts)} - ${this.getFormattedDate(staffPayroll.salaryEnds)}`
    },

    checkStaffPayroll () {
      if (!this.canEdit) return
      if (this.isStaffAndPayrollCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onAddStaffAndPayroll () {
      if (!this.canEdit) return
      this.dialogAddStaffVisible = true
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setStaffPayrollView', view)
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onCloseDialogAddStaff () {
      this.dialogAddStaffVisible = false
      this.staffAndPayrollAction = null

      // Workaround to update computed property productsToShowGrid & productsToShowList
      this.updateStaffAndPayrollView()
    },

    updateStaffAndPayrollView () {
      this.showAllStaffAndPayrollList = !this.showAllStaffAndPayrollList
      this.showAllStaffAndPayrollList = !this.showAllStaffAndPayrollList
      this.showAllStaffAndPayrollGrid = !this.showAllStaffAndPayrollGrid
      this.showAllStaffAndPayrollGrid = !this.showAllStaffAndPayrollGrid
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    onEditStaffAndPayroll (staffAndPayroll) {
      this.staffAndPayrollAction = Object.assign({}, staffAndPayroll)
      this.dialogAddStaffVisible = true
    },

    onCopyStaffAndPayroll (staffAndPayroll) {
      this.staffAndPayrollAction = Object.assign({}, staffAndPayroll)
      this.staffAndPayrollAction.id = 0

      this.dialogAddStaffVisible = true
    },

    onDeleteStaffAndPayroll (staffAndPayroll) {
      this.loaded.staffAndPayroll = false
      this.deleteStaffAndPayroll(staffAndPayroll.id)
        .then((response) => {
          this.getStaffAndPayroll()
        })
        .finally(_ => {
          this.loaded.staffAndPayroll = true
        })
      this.updateStaffAndPayrollView()
    },

    onEraseFromTemplate () {
      if (this.isStaffAndPayrollCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true

      this.deleteIdeaExamples('staff-and-payrolls')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getStaffAndPayroll()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-staff-payroll {
    margin-left: 0;
  }
</style>
