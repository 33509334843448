<template>
  <div class="d-flex align-items-center w-100">
    <avatar-text :circle="circle" :text="avatarText" />
    <div class="w-100">
      <ib-tooltip
        v-if="Math.abs(money) >= 1.0e+7"
        :content="`${text}: ${chartTooltipDisplay(money)}`"
        placement="right"
        :open-delay="0"
      >
        <p class="bold m-0">
          {{ chartValueFormatter(money) }}
        </p>
      </ib-tooltip>

      <p v-else class="bold m-0">
        {{ chartValueFormatter(money) }}
      </p>

      <p class="text-small m-0 three-dots">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import AvatarText from '@/views/Home/StoryMode/FinancialPlan/Components/AvatarText'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'YearInfo',

  components: { AvatarText },

  mixins: [MixinCurrencies],

  props: {
    money: {
      type: Number,
      required: true
    },
    avatarText: {
      type: [String, Number],
      required: true
    },
    text: {
      type: String,
      required: true
    },
    circle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.text-small {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
