<template>
  <div class="help-me-write">
    <h3 class="font-outfit-medium">
      {{ $t('yourContent') }}
    </h3>

    <div class="mb-5">
      <jodit-editor
        v-model="content"
        :config="joditEditorConfig"
      />
    </div>

    <ai-help-me-write-generated-content
      :type="type"
      @replaceContent="content = $event"
    />

    <div class="d-flex justify-content-start">
      <p class="disclaimer-text font-outfit-light">
        {{ $t('aiDisclaimer') }}
      </p>
    </div>

    <v-divider />

    <div class="d-flex flex-column flex-md-row justify-content-end align-items-center help-me-write-footer">
      <ai-buddy-request-remaining />
      <div class="d-flex flex-row">
        <ib-button-basic
          class="mr-3"
          @click="save"
        >
          <span class="font-outfit-regular">{{ $t('save') }}</span>
        </ib-button-basic>
        <ib-button-basic
          color="gray"
          @click="$emit('close')"
        >
          <span class="font-outfit-regular">{{ $t('aiBuddy.close') }}</span>
        </ib-button-basic>
      </div>
    </div>
  </div>
</template>

<script>
import AiBuddyRequestRemaining from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyRequestRemaining.vue'
import AiHelpMeWriteGeneratedContent from '@/views/Home/StoryMode/Components/AiBuddy/AiHelpMeWriteGeneratedContent.vue'
import EventBus from '@/event-bus'
import { JoditEditor } from 'jodit-vue'
import { mapActions } from 'vuex'

export default {
  name: 'AiHelpMeWrite',

  components: {
    AiBuddyRequestRemaining,
    AiHelpMeWriteGeneratedContent,
    JoditEditor
  },

  provide () {
    return {
      content: () => this.content
    }
  },

  inject: ['getSection', 'getVisible'],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      content: null
    }
  },

  computed: {
    visible () {
      return this.getVisible()
    },

    section () {
      return this.getSection()
    },

    joditEditorConfig () {
      return {
        height: 250,
        // disabled: !this.canEdit || this.disabled,
        toolbar: false,
        inline: false,
        showPlaceholder: true,
        placeholder: this.$t('aiBuddy.helpMeWrite.addHereYourContent'),
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        limitChars: 10000,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        disablePlugins: ['addNewLine']
      }
    }
  },

  watch: {
    visible: {
      handler (value) {
        if (value) {
          this.content = this.section.subTitle
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('idea', ['updateBusinessPlanAdditionalInfo']),

    save () {
      this.updateBusinessPlanAdditionalInfo({ id: this.section.id, content: this.content })
      EventBus.$emit('ai-help-me-write-save', this.content)
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.help-me-write {
  h3 {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

.help-me-write-footer {
  row-gap: 10px;
}

.disclaimer-text {
  font-size: 14px;
  opacity: .7;
  margin: 5px 0 3px;
  line-height: 14px;
}
</style>
