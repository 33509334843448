export default {
  'idea-why': 'aiSuggestionsHeadings.idea-why',
  'idea-what': 'aiSuggestionsHeadings.idea-what',
  product: 'aiSuggestionsHeadings.product',
  promotion: 'aiSuggestionsHeadings.promotion',
  distribution: 'aiSuggestionsHeadings.distribution',
  partners: 'aiSuggestionsHeadings.partners',
  customers: 'aiSuggestionsHeadings.customers',
  competitors: 'aiSuggestionsHeadings.competitors',
  'swot-strengths': 'aiSuggestionsHeadings.swot-strengths',
  'swot-weaknesses': 'aiSuggestionsHeadings.swot-weaknesses',
  'swot-opportunities': 'aiSuggestionsHeadings.swot-opportunities',
  'swot-threats': 'aiSuggestionsHeadings.swot-threats',
  'startup-costs': 'aiSuggestionsHeadings.startup-costs',
  'staff-and-payroll': 'aiSuggestionsHeadings.staff-and-payroll',
  'revenue-streams': 'aiSuggestionsHeadings.revenue-streams',
  'direct-costs': 'aiSuggestionsHeadings.direct-costs',
  'marketing-costs': 'aiSuggestionsHeadings.marketing-costs',
  'other-overheads': 'aiSuggestionsHeadings.other-overheads'
}
