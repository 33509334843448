<template>
  <div id="dialog-congratulations-forecast">

    <!-- Dialog -->
    <el-dialog :visible="dialogCongratulationsForecastVisible"
               :close-on-click-modal="true"
               :fullscreen="true"
    >

      <!-- Background Wrapper -->
      <div class="background-wrapper">

        <!-- Dialog Head -->
        <div class="dialog-head">
          <header>

            <!-- Title -->
            <h1>{{ $t('dialogs.congratulations.title') }}</h1>
            <!-- /Title -->

            <!-- Subtitle -->
            <p class="text-lead">
              <span class="strong">{{ $t('dialogs.congratulations.greatJob') }}</span> {{ $t('dialogs.congratulations.youHaveSuccessfully') }}
            </p>
            <!-- /Subtitle -->

          </header>
        </div>
        <!-- /Dialog Head -->

        <!-- Dialog Body -->
        <div class="dialog-body">
          <div class="content-wrapper">

            <!-- Illustration -->
            <img src="@/assets/img/business-guide/icon-projections.svg" class="icon-image" alt="">
            <!-- /Illustration -->

            <!-- So what’s next? -->
            <p class="text-bold big-title">
              {{ $t('dialogs.congratulations.projectionsCompleted') }}
            </p>
            <!-- /So what’s next? -->

            <!-- List -->
            <ul class="intro-list">
              <li>
                {{ $t('dialogs.congratulations.thisWasTheFinalChapter') }}
              </li>
              <li>
                {{ $t('dialogs.congratulations.ifYouHaveCompleted') }}
              </li>
            </ul>
            <!-- /List -->

          </div>
        </div>
        <!-- /Dialog Body -->

        <!-- Continue Button -->
        <div class="dialog-footer">
          <el-button class="full" @click="finishForecastStep">
            {{ $t('dialogs.congratulations.continue') }}
          </el-button>
        </div>
        <!-- /Continue Button -->

      </div>
      <!-- /Background Wrapper -->

    </el-dialog>
    <!-- /Dialog -->

  </div>
</template>

<script>
export default {
  name: 'DialogCongratulationsForecast',
  props: {
    dialogCongratulationsForecastVisible: Boolean
  },

  methods: {
    finishForecastStep () {
      this.$emit('finish-forecast-step')
    }
  }
}
</script>

<style scoped lang="scss">

.icon-image {
  width: 100px;
}

.big-title {
  font-size: 22px !important;
}

</style>
