<template>
  <div class="requests-remaining font-outfit-semi-bold mb-2 mb-md-0">
    {{ aiRequests.amountSpent }} {{ $t('aiBuddy.getSuggestions.of') }} {{ aiRequests.amount }} {{ $t('aiBuddy.requestsRemaining') }}
    <ib-tooltip
      popper-class="small center"
      :content="$t('openAIAssistancePageTooltip')"
    >
      <a href="/ai-assistance" target="_blank">
        <i class="ib-icon ib-icon-open-in-new-window" />
      </a>
    </ib-tooltip>
  </div>
</template>

<script>
import MixinAiRequests from '@/mixins/permissions/aiRequests'

export default {
  name: 'AiBuddyRequestRemaining',

  mixins: [MixinAiRequests]
}
</script>

<style scoped lang="scss">
.requests-remaining {
  width: 100%;
  color: $color-ai;
  text-align: center;

  @include media-breakpoint-up($md) {
    text-align: left;
  }

  a {
    color: #8509F6;
    opacity: .6;
    text-decoration: none;
    margin-left: 5px;
    transition: all 0.25s;

    i {
      color: #8509F6;
      font-size: 16px;
      &:before {
        font-weight: 900;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
