<template>
  <el-button
    :type="type"
    :icon="icon"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span class="semi">{{ btnText }}</span>
    <br>{{ title }}
  </el-button>
</template>

<script>
/** Types **/
const NEXT_TYPE = 'forward-arrow'
const BACK_TYPE = 'back-arrow'

export default {
  name: 'IbNavigationButton',

  props: {
    type: {
      type: String,
      validator: value => {
        return [NEXT_TYPE, BACK_TYPE].includes(value)
      },
      default: NEXT_TYPE
    },

    text: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btnText () {
      if (this.text) return this.text

      return this.type === NEXT_TYPE ? this.$t('next') : this.$t('back')
    },

    icon () {
      return this.type === NEXT_TYPE ? 'el-icon-right' : 'el-icon-back'
    }
  }
}
</script>

<style scoped lang="scss">
.el-button {
  width: 100%;
  min-width: unset;
  max-width: unset;
  min-height: 56px;

  &.el-button--back-arrow {
    padding-left: 35px;
    padding-right: 10px;
    font-size: 13px;

    @include media-breakpoint-up($md) {
      padding-left: 58px;
      padding-right: 20px;
      font-size: 14px;
    }

    ::v-deep i {
      left: 5px;

      @include media-breakpoint-up($md) {
        left: 20px;
      }
    }
  }

  &.el-button--forward-arrow {
    padding-left: 10px;
    padding-right: 35px;
    font-size: 13px;

    @include media-breakpoint-up($md) {
      padding-left: 20px;
      padding-right: 58px;
      font-size: 14px;
    }

    ::v-deep i {
      right: 5px;

      @include media-breakpoint-up($md) {
        right: 20px;
      }
    }
  }
}
</style>
