export default {
  computed: {
    numberOfYearsToShow () {
      return Math.floor(this.getNumberOfMonthsPlanning() / 12) > 3 ? 5 : Math.floor(this.getNumberOfMonthsPlanning() / 12)
    }
  },

  methods: {
    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    },

    calculateCashFlow () {
      return this.sumObjectsByKey(
        this.calculateCashFlowOperations(),
        this.calculateCashFlowFinancing()
      )
    },

    calculateNetChangeInCash () {
      return this.sumObjectsByKey(
        this.calculateCashFlowOperations(),
        this.calculateCashFlowFinancing()
      )
    },

    calculateCashFlowOperations () {
      return this.sumObjectsByKey(
        this.calculateNetProfit(),
        this.calculateChangeInAccountPayables(),
        this.calculateChangeInAccountReceivables(),
        this.calculateChangeInIncomeTaxPayable(),
        this.calculateChangeInSalesTaxPayable()
      )
    },

    calculateCashFlowFinancing () {
      return this.sumObjectsByKey(
        this.calculateSavings(),
        this.calculateFundingReceived(),
        this.calculateChangeInDebt(),
        this.calculateDividends(true)
      )
    },

    calculateFinancing () {
      return this.sumObjectsByKey(
        this.calculateFinancingIncome(),
        this.calculateFinancingOutcome()
      )
    },

    calculateFinancingIncome () {
      return this.sumObjectsByKey(
        this.calculateSavings(),
        this.calculateFundingReceived(),
        this.calculateCreditsAndLoansIncome()
      )
    },

    calculateFinancingOutcome () {
      return this.sumObjectsByKey(
        this.calculateCreditsAndLoansOutcome()
      )
    },

    calculateNetProfit () {
      return this.sumObjectsByKey(
        this.calculateOperatingIncome(),
        this.calculateTotalInterestExpense(true),
        this.calculateAccruedIncomeTaxes(true)
      )
    },

    calculateOperatingIncome () {
      return this.sumObjectsByKey(
        this.calculateTotalRevenue(),
        this.calculateDirectCostsPlusInventory(true),
        this.calculateOperatingExpenses(true)
      )
    },

    calculateTotalExpenses (isNegative) {
      return this.sumObjectsByKey(
        this.calculateDirectCostsPlusInventory(isNegative),
        this.calculateOperatingExpenses(isNegative),
        this.calculateTotalInterestExpense(isNegative),
        this.calculateAccruedIncomeTaxes(isNegative)
      )
    },

    calculateChangeInAccountPayables () {
      const result = this.getEmptyCalculation()
      const cashCycle = this.getIdea().storyMode.forecast.profitAndCashFlow.cashCycle
      const daysToPay = cashCycle !== null && typeof cashCycle.daysToPay !== 'undefined' ? cashCycle.daysToPay : 0
      const purchasesOnCredit = cashCycle !== null && typeof cashCycle.purchasesOnCredit !== 'undefined' ? cashCycle.purchasesOnCredit : 0
      const totalExpensesMinusPayrollExpenses = this.sumObjectsByKey(
        this.calculateDirectCostsPlusInventory(false),
        this.calculateMarketingExpenses(false),
        this.calculateOtherOverheads(false),
        this.calculateStartupCostsMinusInventory(false),
        this.calculateTotalInterestExpense(false),
        this.calculateAccruedIncomeTaxes(false)
      )

      for (const [dateString, amount] of Object.entries(totalExpensesMinusPayrollExpenses)) {
        result[dateString] += amount * purchasesOnCredit / 100
        const dateStringMonthToPay = this.$moment(dateString, 'MMM YY').add(daysToPay / 30, 'M').format('MMM YY')

        if (Object.prototype.hasOwnProperty.call(result, dateStringMonthToPay)) {
          result[dateStringMonthToPay] -= amount * purchasesOnCredit / 100
        }
      }

      return result
    },

    calculateChangeInAccountReceivables () {
      const result = this.getEmptyCalculation()
      const cashCycle = this.getIdea().storyMode.forecast.profitAndCashFlow.cashCycle
      const daysToCollect = cashCycle !== null && typeof cashCycle.daysToCollect !== 'undefined' ? cashCycle.daysToCollect : 0
      const salesOnCredit = cashCycle !== null && typeof cashCycle.salesOnCredit !== 'undefined' ? cashCycle.salesOnCredit : 0
      const totalRevenue = this.calculateTotalRevenue()

      for (const [dateString, amount] of Object.entries(totalRevenue)) {
        result[dateString] -= amount * salesOnCredit / 100
        const dateStringMonthToPay = this.$moment(dateString, 'MMM YY').add(daysToCollect / 30, 'M').format('MMM YY')

        if (Object.prototype.hasOwnProperty.call(result, dateStringMonthToPay)) {
          result[dateStringMonthToPay] += amount * salesOnCredit / 100
        }
      }

      return result
    },

    calculateChangeInIncomeTaxPayable () {
      let result = this.getEmptyCalculation()

      result = this.sumObjectsByKey(
        this.calculateAccruedIncomeTaxes(false),
        this.calculateIncomeTaxesPaid(true)
      )

      return result
    },

    calculateChangeInSalesTaxPayable () {
      let result = this.getEmptyCalculation()

      result = this.sumObjectsByKey(
        this.calculateAccruedSalesTaxes(),
        this.calculateSalesTaxesPaid(true)
      )

      return result
    },

    calculateTotalInterestExpense (isNegative) {
      let result = this.getEmptyCalculation()
      const changeInDebt = this.getIdea().storyMode.setUp.financing.finances.filter(
        finance => finance.type === 'loc' || finance.type === 'loan'
      )

      for (let i = 0; i < changeInDebt.length; i++) {
        if (changeInDebt[i].type === 'loc') {
          result = this.sumObjectsByKey(result, this.calculateLineOfCreditInterestRate(changeInDebt[i], isNegative))
        }

        if (changeInDebt[i].type === 'loan') {
          result = this.sumObjectsByKey(result, this.calculateLoanInterestRate(changeInDebt[i], isNegative))
        }
      }

      return result
    },

    calculateAccruedIncomeTaxes (isNegative) {
      const result = this.getEmptyCalculation()
      const operatingIncome = this.calculateOperatingIncome()
      const incomeTax = this.getIdea().storyMode.forecast.profitAndCashFlow.incomeTax
      const taxRate = incomeTax !== null && typeof incomeTax.taxRate !== 'undefined' ? incomeTax.taxRate : 0

      for (const [dateString, amount] of Object.entries(operatingIncome)) {
        if (amount > 0) {
          result[dateString] = (isNegative ? -1 : 1) * amount * taxRate / 100
        }
      }

      return result
    },

    calculateAccruedSalesTaxes (isNegative) {
      const result = this.getEmptyCalculation()
      const totalRevenue = this.calculateTotalRevenueTaxable()
      const salesTax = this.getIdea().storyMode.forecast.revenueStreams.salesTaxRate
      const taxRate = salesTax !== null && typeof salesTax.percentageValue !== 'undefined' ? salesTax.percentageValue : 0

      for (const [dateString, amount] of Object.entries(totalRevenue)) {
        if (amount > 0) {
          result[dateString] = (isNegative ? -1 : 1) * amount * taxRate / 100
        }
      }

      return result
    },

    calculateIncomeTaxesPaid (isNegative) {
      const result = this.getEmptyCalculation()
      const accruedIncomeTaxes = this.calculateAccruedIncomeTaxes(false)
      const incomeTax = this.getIdea().storyMode.forecast.profitAndCashFlow.incomeTax
      let howOftenWillYouPay = incomeTax !== null && typeof incomeTax.howOftenWillYouPay !== 'undefined' ? incomeTax.howOftenWillYouPay : 'monthly'
      howOftenWillYouPay = this.getNumberOfMonthsByInterval(howOftenWillYouPay)
      let taxToPay = 0
      let monthCounter = 1

      for (const [dateString, amount] of Object.entries(accruedIncomeTaxes)) {
        if (monthCounter % howOftenWillYouPay === 0) {
          result[dateString] = taxToPay
          taxToPay = 0
        }
        taxToPay += (isNegative ? -1 : 1) * amount
        monthCounter++
      }

      return result
    },

    calculateSalesTaxesPaid (isNegative) {
      const result = this.getEmptyCalculation()
      const accruedSalesTaxes = this.calculateAccruedSalesTaxes(false)
      const salesTax = this.getIdea().storyMode.forecast.revenueStreams.salesTaxRate
      let howOftenWillYouPay = salesTax !== null && typeof salesTax.paymentFrequency !== 'undefined' ? salesTax.paymentFrequency : 0
      howOftenWillYouPay = this.getNumberOfMonthsByInterval(howOftenWillYouPay)
      let taxToPay = 0
      let monthCounter = 1

      for (const [dateString, amount] of Object.entries(accruedSalesTaxes)) {
        if (monthCounter % howOftenWillYouPay === 0) {
          result[dateString] = taxToPay
          taxToPay = 0
        }
        taxToPay += (isNegative ? -1 : 1) * amount
        monthCounter++
      }

      return result
    },

    calculateTotalRevenue () {
      let result = this.getEmptyCalculation()
      const revenueStreams = this.getIdea().storyMode.forecast.revenueStreams.revenueStreams

      for (let i = 0; i < revenueStreams.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateRevenueStream(revenueStreams[i]))
      }

      return result
    },

    calculateTotalRevenueTaxable () {
      let result = this.getEmptyCalculation()
      const revenueStreams = this.getIdea().storyMode.forecast.revenueStreams.revenueStreams

      for (let i = 0; i < revenueStreams.length; i++) {
        if (revenueStreams[i].isTaxable === true) {
          result = this.sumObjectsByKey(result, this.calculateRevenueStream(revenueStreams[i]))
        }
      }

      return result
    },

    calculateRevenueStream (revenueStream) {
      let result = this.getEmptyCalculation()

      switch (revenueStream.type) {
        case 'bl':
          result = this.sumObjectsByKey(result, this.calculateBillableRate(revenueStream))
          break
        case 'or':
          result = this.sumObjectsByKey(result, this.calculateOccupancyRate(revenueStream))
          break
        case 'rc':
          result = this.sumObjectsByKey(result, this.calculateRecurringCharge(revenueStream))
          break
        default:
          result = this.sumObjectsByKey(result, this.calculateProductOrService(revenueStream))
      }

      return result
    },

    calculateDirectCostsPlusInventory (isNegative) {
      let result = this.getEmptyCalculation()
      const directCosts = this.getIdea().storyMode.forecast.directCosts.directCosts
      const inventories = this.getIdea().storyMode.setUp.startupCosts.startupCosts.filter(
        startupCost => startupCost.typeGroup === 'inventory'
      )

      for (let i = 0; i < directCosts.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateDirectCost(directCosts[i], isNegative))
      }

      for (let i = 0; i < inventories.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateStartupCost(inventories[i], isNegative))
      }

      return result
    },

    calculateDirectCosts (isNegative) {
      let result = this.getEmptyCalculation()
      const directCosts = this.getIdea().storyMode.forecast.directCosts.directCosts

      for (let i = 0; i < directCosts.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateDirectCost(directCosts[i], isNegative))
      }

      return result
    },

    calculateOperatingExpenses (isNegative) {
      return this.sumObjectsByKey(
        this.calculatePayrollExpenses(isNegative),
        this.calculateMarketingExpenses(isNegative),
        this.calculateOtherOverheads(isNegative),
        this.calculateStartupCostsMinusInventory(isNegative)
      )
    },

    calculateOtherOverheads (isNegative) {
      let result = this.getEmptyCalculation()
      const otherOverheads = this.getIdea().storyMode.forecast.otherOverheads.otherOverheads

      for (let i = 0; i < otherOverheads.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateOtherOverhead(otherOverheads[i], isNegative))
      }

      return result
    },

    calculateOtherOverhead (otherOverhead, isNegative) {
      const result = this.getEmptyCalculation()

      if (otherOverhead.oneTime) {
        const dateString = this.$moment(otherOverhead.whenWillItOccur).format('MMM YY')

        result[dateString] = (isNegative ? -1 : 1) * otherOverhead.amount
      }

      const startMoment = this.$moment(otherOverhead.starts)
      const endMoment = this.$moment(otherOverhead.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      if (otherOverhead.revenue) {
        const revenueStream = this.getRevenueStream(otherOverhead.revenueStreamId)
        const calculatedRevenueStream = this.calculateRevenueStream(revenueStream)

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(otherOverhead.starts).add(i, 'M').format('MMM YY')

          result[dateString] += (isNegative ? -1 : 1) * (otherOverhead.percentageOfRevenue / 100) * calculatedRevenueStream[dateString]
        }
      }

      if (otherOverhead.constant) {
        const amountAdjustment = this.calculateValueAdjustment(otherOverhead.constantAmount, otherOverhead.overheadChange, otherOverhead.isOverheadChangePercentage, otherOverhead.overheadChangePrefix === '-')

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(otherOverhead.starts).add(i, 'M').format('MMM YY')

          result[dateString] = (isNegative ? -1 : 1) * otherOverhead.constantAmount + (isNegative ? -1 : 1) * (amountAdjustment ? amountAdjustment * Math.floor(i / this.getNumberOfMonthsByInterval(otherOverhead.paymentMethod)) : null)
        }
      }

      return result
    },

    calculateMarketingExpenses (isNegative) {
      let result = this.getEmptyCalculation()
      const marketingElements = this.getIdea().storyMode.forecast.marketingBudget.marketingElements

      for (let i = 0; i < marketingElements.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateMarketingExpense(marketingElements[i], isNegative))
      }

      return result
    },

    calculateMarketingExpense (marketingElement, isNegative) {
      const result = this.getEmptyCalculation()

      if (marketingElement.amountIntervalType === 'one_time') {
        const dateString = this.$moment(marketingElement.whenWillItOccur).format('MMM YY')

        result[dateString] = (isNegative ? -1 : 1) * marketingElement.amount
      }

      const startMoment = this.$moment(marketingElement.starts)
      const endMoment = this.$moment(marketingElement.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      if (marketingElement.amountIntervalType === 'revenue') {
        const revenueStream = this.getRevenueStream(marketingElement.revenueStreamId)
        const calculatedRevenueStream = this.calculateRevenueStream(revenueStream)

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(marketingElement.starts).add(i, 'M').format('MMM YY')

          result[dateString] = (isNegative ? -1 : 1) * (marketingElement.revenueStreamPercentage / 100) * calculatedRevenueStream[dateString]
        }
      }

      if (marketingElement.amountIntervalType === 'constant') {
        const amountAdjustment = this.calculateValueAdjustment(marketingElement.amount, marketingElement.budgetChangeAmount, marketingElement.isBudgetChangePercentage === '%', marketingElement.budgetChangePrefix === '-')

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(marketingElement.starts).add(i, 'M').format('MMM YY')

          result[dateString] = (isNegative ? -1 : 1) * marketingElement.amount + (isNegative ? -1 : 1) * (amountAdjustment ? amountAdjustment * Math.floor(i / this.getNumberOfMonthsByInterval(marketingElement.budgetChangeIntervalType)) : 0)
        }
      }

      return result
    },

    calculateStaffAndPayrollAverage (staff) {
      const startMoment = this.$moment(staff.salaryStarts)
      const endMoment = this.$moment(staff.salaryEnds)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      return this.calculateSumForPlanningPeriod(this.calculateSalariesAndWages(staff, false)) / durationMonths
    },

    calculatePayrollExpenses (isNegative) {
      let result = this.getEmptyCalculation()
      const staffAndPayrolls = this.getIdea().storyMode.setUp.staffAndPayrolls.staffAndPayrolls

      for (let i = 0; i < staffAndPayrolls.length; i++) {
        result = this.sumObjectsByKey(result, this.calculatePayrollExpense(staffAndPayrolls[i], isNegative))
      }

      return result
    },

    calculatePayrollExpensesWithoutLaborBurden (isNegative) {
      let result = this.getEmptyCalculation()
      const staffAndPayrolls = this.getIdea().storyMode.setUp.staffAndPayrolls.staffAndPayrolls

      for (let i = 0; i < staffAndPayrolls.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateSalariesAndWages(staffAndPayrolls[i], isNegative))
      }

      return result
    },

    calculatePayrollExpense (payrollExpense, isNegative) {
      let result = this.getEmptyCalculation()

      result = this.sumObjectsByKey(result, this.calculateSalariesAndWages(payrollExpense, isNegative))
      result = this.sumObjectsByKey(result, this.calculateStaffRelatedExpenses(payrollExpense, isNegative))

      return result
    },

    calculateSalariesAndWages (staff, isNegative) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(staff.salaryStarts)
      const endMoment = this.$moment(staff.salaryEnds)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const salaryAdjustment = this.calculateValueAdjustment(staff.salary, staff.payAdjustmentAmount, staff.isPayPercentage, staff.payAdjustmentPrefix === '-')

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(staff.salaryStarts).add(i, 'M').format('MMM YY')

        result[dateString] =
          staff.howMany * (isNegative ? -1 : 1) * staff.salary / this.getNumberOfMonthsByInterval(staff.salaryIntervalType) +
          staff.howMany * (isNegative ? -1 : 1) * salaryAdjustment * Math.floor(i / this.getNumberOfMonthsByInterval(staff.payAdjustmentIntervalType))
      }

      return result
    },

    calculateStaffRelatedExpenses (staff, isNegative) {
      const result = this.getEmptyCalculation()
      const laborBurden = this.getIdea().storyMode.setUp.staffAndPayrolls.laborBurden.percentageValue

      if (staff.type === 'fti' || staff.type === 'ftg') {
        const salary = this.calculateSalariesAndWages(staff, isNegative)

        for (const [dateString, amount] of Object.entries(salary)) {
          result[dateString] = amount * laborBurden / 100
        }
      }

      return result
    },

    calculateStartupCostsMinusInventory (isNegative) {
      let result = this.getEmptyCalculation()

      const startupCosts = this.getIdea().storyMode.setUp.startupCosts.startupCosts.filter(
        startupCost => startupCost.typeGroup !== 'inventory'
      )

      for (let i = 0; i < startupCosts.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateStartupCost(startupCosts[i], isNegative))
      }

      return result
    },

    calculateStartupCosts (isNegative) {
      let result = this.getEmptyCalculation()
      const startupCosts = this.getIdea().storyMode.setUp.startupCosts.startupCosts

      for (let i = 0; i < startupCosts.length; i++) {
        result = this.sumObjectsByKey(result, this.calculateStartupCost(startupCosts[i], isNegative))
      }

      return result
    },

    calculateDirectCost (directCost, isNegative) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(directCost.starts)
      const endMoment = this.$moment(directCost.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      if (directCost.isConstantAmount === true) {
        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(directCost.starts).add(i, 'M').format('MMM YY')

          result[dateString] += (isNegative ? -1 : 1) * directCost.amount
        }

        return result
      }

      const revenueStream = this.getRevenueStream(directCost.revenueStreamId)
      const calculatedRevenueStream = this.calculateRevenueStream(revenueStream)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(directCost.starts).add(i, 'M').format('MMM YY')

        result[dateString] += (isNegative ? -1 : 1) * (directCost.percentageOfRevenue / 100) * calculatedRevenueStream[dateString]
      }

      return result
    },

    calculateStartupCost (startupCost, isNegative) {
      const result = this.getEmptyCalculation()

      if (startupCost.oneTime === true) {
        const dateString = this.$moment(startupCost.whenWillItOccur).format('MMM YY')
        result[dateString] = (isNegative ? -1 : 1) * startupCost.amount

        return result
      }

      const installmentAmount = startupCost.amount / startupCost.numberOfInstallments

      for (let i = 0; i < startupCost.numberOfInstallments; i++) {
        const dateString = this.$moment(startupCost.whenWillItOccur).add(i * this.getNumberOfMonthsByInterval(startupCost.paymentMethod), 'M').format('MMM YY')

        result[dateString] = (isNegative ? -1 : 1) * installmentAmount
      }

      return result
    },

    calculateBillableRate (billableRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(billableRate.starts)
      const endMoment = this.$moment(billableRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const billableRateAdjustment = this.calculateValueAdjustment(billableRate.billableRate, billableRate.priceIncrease, billableRate.isPriceIncreasePercentage, billableRate.isPriceIncreaseNegative)
      const growthAdjustment = this.calculateValueAdjustment(billableRate.served, billableRate.growth, billableRate.isGrowthPercentage, billableRate.isGrowthNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(billableRate.starts).add(i, 'M').format('MMM YY')

        let billableRateTotal = billableRate.billableRate + Math.floor(i / this.getNumberOfMonthsByInterval(billableRate.priceIncreaseInterval)) * billableRateAdjustment
        billableRateTotal = billableRateTotal < 0 ? 0 : billableRateTotal

        let servedTotal = billableRate.served + Math.floor(i / this.getNumberOfMonthsByInterval(billableRate.growthInterval)) * growthAdjustment
        servedTotal = servedTotal < 0 ? 0 : servedTotal

        let value = billableRateTotal * servedTotal / this.getNumberOfMonthsByInterval(billableRate.servedInterval)
        value = value < 0 ? 0 : value

        result[dateString] += value
      }

      return result
    },

    calculateBillableRateHours (billableRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(billableRate.starts)
      const endMoment = this.$moment(billableRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const growthAdjustment = this.calculateValueAdjustment(billableRate.served, billableRate.growth, billableRate.isGrowthPercentage, billableRate.isGrowthNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(billableRate.starts).add(i, 'M').format('MMM YY')

        let servedTotal = billableRate.served + Math.floor(i / this.getNumberOfMonthsByInterval(billableRate.growthInterval)) * growthAdjustment
        servedTotal = servedTotal < 0 ? 0 : servedTotal

        result[dateString] += servedTotal / this.getNumberOfMonthsByInterval(billableRate.servedInterval)
      }

      return result
    },

    calculateBillableRateChargePerHour (billableRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(billableRate.starts)
      const endMoment = this.$moment(billableRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const billableRateAdjustment = this.calculateValueAdjustment(billableRate.billableRate, billableRate.priceIncrease, billableRate.isPriceIncreasePercentage, billableRate.isPriceIncreaseNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(billableRate.starts).add(i, 'M').format('MMM YY')

        let billableRateTotal = billableRate.billableRate + Math.floor(i / this.getNumberOfMonthsByInterval(billableRate.priceIncreaseInterval)) * billableRateAdjustment
        billableRateTotal = billableRateTotal < 0 ? 0 : billableRateTotal

        result[dateString] += billableRateTotal
      }

      return result
    },

    calculateOccupancyRate (occupancyRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(occupancyRate.starts)
      const endMoment = this.$moment(occupancyRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const averageChargePerUnitAdjustment = this.calculateValueAdjustment(occupancyRate.averageChargePerUnit, occupancyRate.priceIncrease, occupancyRate.isPriceIncreasePercentage, occupancyRate.isPriceIncreaseNegative)
      const growthAdjustment = occupancyRate.isGrowthNegative === true ? -1 * occupancyRate.growth : occupancyRate.growth

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(occupancyRate.starts).add(i, 'M').format('MMM YY')

        let averageChargePerUnitTotal = occupancyRate.averageChargePerUnit + Math.floor(i / this.getNumberOfMonthsByInterval(occupancyRate.priceIncreaseInterval)) * averageChargePerUnitAdjustment
        averageChargePerUnitTotal = averageChargePerUnitTotal < 0 ? 0 : averageChargePerUnitTotal

        const maxTurnoversPerUnitTotal = Math.round(occupancyRate.maxTurnoversPerUnit / this.getNumberOfMonthsByInterval(occupancyRate.maxTurnoversPerUnitInterval) * 10) / 10

        let occupancyRateChanged = Math.floor(i / this.getNumberOfMonthsByInterval(occupancyRate.growthInterval)) === 0
          ? occupancyRate.occupancyRate / 100
          : (occupancyRate.occupancyRate + growthAdjustment * Math.floor(i / this.getNumberOfMonthsByInterval(occupancyRate.growthInterval))) / 100
        occupancyRateChanged = occupancyRateChanged > 1 ? 1 : occupancyRateChanged

        let value = occupancyRate.count * averageChargePerUnitTotal * maxTurnoversPerUnitTotal * occupancyRateChanged
        value = value < 0 ? 0 : value

        result[dateString] = value
      }

      return result
    },

    calculateOccupancyRateServiceUnits (occupancyRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(occupancyRate.starts)
      const endMoment = this.$moment(occupancyRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(occupancyRate.starts).add(i, 'M').format('MMM YY')
        result[dateString] = occupancyRate.count
      }

      return result
    },

    calculateOccupancyRateMaxTurnoverPerServiceUnit (occupancyRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(occupancyRate.starts)
      const endMoment = this.$moment(occupancyRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(occupancyRate.starts).add(i, 'M').format('MMM YY')
        result[dateString] = Math.round(occupancyRate.maxTurnoversPerUnit / this.getNumberOfMonthsByInterval(occupancyRate.maxTurnoversPerUnitInterval) * 10) / 10
      }

      return result
    },

    calculateOccupancyRateOccupancyRate (occupancyRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(occupancyRate.starts)
      const endMoment = this.$moment(occupancyRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const growthAdjustment = occupancyRate.isGrowthNegative === true ? -1 * occupancyRate.growth : occupancyRate.growth

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(occupancyRate.starts).add(i, 'M').format('MMM YY')

        let occupancyRateChanged = Math.floor(i / this.getNumberOfMonthsByInterval(occupancyRate.growthInterval)) === 0
          ? occupancyRate.occupancyRate
          : (occupancyRate.occupancyRate + growthAdjustment * Math.floor(i / this.getNumberOfMonthsByInterval(occupancyRate.growthInterval)))
        occupancyRateChanged = occupancyRateChanged > 100 ? 100 : occupancyRateChanged

        result[dateString] = occupancyRateChanged
      }

      return result
    },

    calculateOccupancyRateAverageCharge (occupancyRate) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(occupancyRate.starts)
      const endMoment = this.$moment(occupancyRate.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const averageChargePerUnitAdjustment = this.calculateValueAdjustment(occupancyRate.averageChargePerUnit, occupancyRate.priceIncrease, occupancyRate.isPriceIncreasePercentage, occupancyRate.isPriceIncreaseNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(occupancyRate.starts).add(i, 'M').format('MMM YY')

        let averageChargePerUnitTotal = occupancyRate.averageChargePerUnit + Math.floor(i / this.getNumberOfMonthsByInterval(occupancyRate.priceIncreaseInterval)) * averageChargePerUnitAdjustment
        averageChargePerUnitTotal = averageChargePerUnitTotal < 0 ? 0 : averageChargePerUnitTotal

        result[dateString] = averageChargePerUnitTotal
      }

      return result
    },

    calculateRecurringCharge (recurringCharge) {
      const result = this.getEmptyCalculation()
      const acquiredCustomers = this.getEmptyCalculation()
      const monthChurn = this.getEmptyCalculation()
      const calcChurn = this.getEmptyCalculation()
      const startMoment = this.$moment(recurringCharge.starts)
      const endMoment = this.$moment(recurringCharge.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const growthAdjustment = this.calculateValueAdjustment(recurringCharge.newSignUps, recurringCharge.growth, recurringCharge.isGrowthPercentage, recurringCharge.isGrowthNegative)
      const recurringChargeAdjustment = this.calculateValueAdjustment(recurringCharge.recurringCharge, recurringCharge.priceIncrease, recurringCharge.isPriceIncreasePercentage, recurringCharge.isPriceIncreaseNegative)
      let subscriberBase = 0

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(recurringCharge.starts).add(i, 'M').format('MMM YY')
        const numberOfAcquiredCustomers = recurringCharge.newSignUps / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) + growthAdjustment / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) * Math.floor(i / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval))

        for (let j = 0; j < i; j++) {
          let churnThisMonth = 0
          const dateStringChurn = this.$moment(recurringCharge.starts).add(j, 'M').format('MMM YY')
          const acquiredPrevPeriod = acquiredCustomers[dateStringChurn]

          if (monthChurn[dateStringChurn] < acquiredPrevPeriod) {
            if (acquiredPrevPeriod * recurringCharge.churn / 100 + monthChurn[dateStringChurn] > acquiredPrevPeriod) {
              churnThisMonth = acquiredPrevPeriod - monthChurn[dateStringChurn]
            } else {
              churnThisMonth = acquiredPrevPeriod * recurringCharge.churn / 100
            }

            monthChurn[dateStringChurn] += churnThisMonth
          }

          calcChurn[dateString] += churnThisMonth
        }

        subscriberBase += numberOfAcquiredCustomers - (typeof calcChurn[dateString] !== 'undefined' ? calcChurn[dateString] : 0)

        let recurringChargeTotal = recurringCharge.recurringCharge / this.getNumberOfMonthsByInterval(recurringCharge.recurringChargeInterval) + recurringChargeAdjustment * Math.floor(i / this.getNumberOfMonthsByInterval(recurringCharge.recurringChargeInterval))
        recurringChargeTotal = recurringChargeTotal < 0 ? 0 : recurringChargeTotal

        let value = recurringCharge.upfrontFee * numberOfAcquiredCustomers + subscriberBase * recurringChargeTotal
        value = value < 0 ? 0 : value

        acquiredCustomers[dateString] = numberOfAcquiredCustomers
        result[dateString] = value
      }

      return result
    },

    calculateRecurringChargeAcquiredCustomers (recurringCharge) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(recurringCharge.starts)
      const endMoment = this.$moment(recurringCharge.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const growthAdjustment = this.calculateValueAdjustment(recurringCharge.newSignUps, recurringCharge.growth, recurringCharge.isGrowthPercentage, recurringCharge.isGrowthNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(recurringCharge.starts).add(i, 'M').format('MMM YY')

        result[dateString] = recurringCharge.newSignUps / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) + growthAdjustment / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) * Math.floor(i / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval))
      }

      return result
    },

    calculateRecurringChargeTotalAcquired (recurringCharge) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(recurringCharge.starts)
      const endMoment = this.$moment(recurringCharge.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const growthAdjustment = this.calculateValueAdjustment(recurringCharge.newSignUps, recurringCharge.growth, recurringCharge.isGrowthPercentage, recurringCharge.isGrowthNegative)
      let totalAcquired = 0

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(recurringCharge.starts).add(i, 'M').format('MMM YY')
        const numberOfAcquiredCustomers = recurringCharge.newSignUps / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) + growthAdjustment / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) * Math.floor(i / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval))
        totalAcquired += numberOfAcquiredCustomers

        result[dateString] = totalAcquired
      }

      return result
    },

    calculateRecurringChargeCustomerChurnPercentage (recurringCharge) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(recurringCharge.starts)
      const endMoment = this.$moment(recurringCharge.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(recurringCharge.starts).add(i, 'M').format('MMM YY')

        result[dateString] = recurringCharge.churn
      }

      return result
    },

    calculateRecurringChargeCustomerChurn (recurringCharge) {
      const result = this.getEmptyCalculation()
      const acquiredCustomers = this.getEmptyCalculation()
      const monthChurn = this.getEmptyCalculation()
      const calcChurn = this.getEmptyCalculation()
      const startMoment = this.$moment(recurringCharge.starts)
      const endMoment = this.$moment(recurringCharge.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1
      const growthAdjustment = this.calculateValueAdjustment(recurringCharge.newSignUps, recurringCharge.growth, recurringCharge.isGrowthPercentage, recurringCharge.isGrowthNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(recurringCharge.starts).add(i, 'M').format('MMM YY')
        const numberOfAcquiredCustomers = recurringCharge.newSignUps / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) + growthAdjustment / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval) * Math.floor(i / this.getNumberOfMonthsByInterval(recurringCharge.growthInterval))

        for (let j = 0; j < i; j++) {
          let churnThisMonth = 0
          const dateStringChurn = this.$moment(recurringCharge.starts).add(j, 'M').format('MMM YY')
          const acquiredPrevPeriod = acquiredCustomers[dateStringChurn]

          if (monthChurn[dateStringChurn] < acquiredPrevPeriod) {
            if (acquiredPrevPeriod * recurringCharge.churn / 100 + monthChurn[dateStringChurn] > acquiredPrevPeriod) {
              churnThisMonth = acquiredPrevPeriod - monthChurn[dateStringChurn]
            } else {
              churnThisMonth = acquiredPrevPeriod * recurringCharge.churn / 100
            }

            monthChurn[dateStringChurn] += churnThisMonth
          }

          calcChurn[dateString] += churnThisMonth
        }

        acquiredCustomers[dateString] = numberOfAcquiredCustomers
        result[dateString] = calcChurn[dateString]
      }

      return result
    },

    calculateProductOrService (product) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(product.starts)
      const endMoment = this.$moment(product.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      if (product.isOverall) {
        const growthAdjustment = this.calculateValueAdjustment(product.overallRevenue, product.growth, product.isGrowthPercentage, product.isGrowthNegative)

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(product.starts).add(i, 'M').format('MMM YY')

          let value = product.overallRevenue + Math.floor(i / this.getNumberOfMonthsByInterval(product.overallRevenueInterval) * growthAdjustment) / this.getNumberOfMonthsByInterval(product.overallRevenueInterval)
          value = value < 0 ? 0 : value

          result[dateString] = value
        }

        return result
      }

      const sellingPriceAdjustment = this.calculateValueAdjustment(product.sellingPrice, product.priceIncrease, product.isPriceIncreasePercentage, product.isPriceIncreaseNegative)
      const growthAdjustment = this.calculateValueAdjustment(product.salesVolume, product.growth, product.isGrowthPercentage, product.isGrowthNegative)

      for (let i = 0; i < durationMonths; i++) {
        const dateString = this.$moment(product.starts).add(i, 'M').format('MMM YY')

        let sellingPriceTotal = product.sellingPrice + Math.floor(i / this.getNumberOfMonthsByInterval(product.priceIncreaseInterval)) * sellingPriceAdjustment
        sellingPriceTotal = sellingPriceTotal < 0 ? 0 : sellingPriceTotal

        let salesVolumeTotal = product.salesVolume + Math.floor(i / this.getNumberOfMonthsByInterval(product.growthInterval)) * growthAdjustment
        salesVolumeTotal = salesVolumeTotal < 0 ? 0 : salesVolumeTotal

        let value = sellingPriceTotal * salesVolumeTotal / this.getNumberOfMonthsByInterval(product.salesVolumeInterval)
        value = value < 0 ? 0 : value

        result[dateString] = value
      }

      return result
    },

    calculateProductOrServiceSales (product) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(product.starts)
      const endMoment = this.$moment(product.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      if (!product.isOverall) {
        const growthAdjustment = this.calculateValueAdjustment(product.salesVolume, product.growth, product.isGrowthPercentage, product.isGrowthNegative)

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(product.starts).add(i, 'M').format('MMM YY')

          let salesVolumeTotal = product.salesVolume + Math.floor(i / this.getNumberOfMonthsByInterval(product.growthInterval)) * growthAdjustment
          salesVolumeTotal = salesVolumeTotal < 0 ? 0 : salesVolumeTotal

          result[dateString] = salesVolumeTotal / this.getNumberOfMonthsByInterval(product.salesVolumeInterval)
        }
      }

      return result
    },

    calculateProductOrServicePrice (product) {
      const result = this.getEmptyCalculation()
      const startMoment = this.$moment(product.starts)
      const endMoment = this.$moment(product.ends)
      const durationMonths = endMoment.diff(startMoment, 'M') + 1

      if (!product.isOverall) {
        const sellingPriceAdjustment = this.calculateValueAdjustment(product.sellingPrice, product.priceIncrease, product.isPriceIncreasePercentage, product.isPriceIncreaseNegative)

        for (let i = 0; i < durationMonths; i++) {
          const dateString = this.$moment(product.starts).add(i, 'M').format('MMM YY')

          let sellingPriceTotal = product.sellingPrice + Math.floor(i / this.getNumberOfMonthsByInterval(product.priceIncreaseInterval)) * sellingPriceAdjustment
          sellingPriceTotal = sellingPriceTotal < 0 ? 0 : sellingPriceTotal

          result[dateString] = sellingPriceTotal
        }
      }

      return result
    },

    calculateValueAdjustment (baseValue, changeValue, isPercentage, isNegative) {
      let result = 0

      if (changeValue) {
        result = changeValue
      }

      if (isPercentage) {
        result *= baseValue * 0.01
      }

      if (isNegative) {
        result *= -1
      }

      return result
    },

    calculateSavings () {
      const result = this.getEmptyCalculation()
      const savings = this.getIdea().storyMode.setUp.financing.finances.filter(
        finance => finance.type === 'savings'
      )

      for (let i = 0; i < savings.length; i++) {
        result[this.$moment(savings[i].date).format('MMM YY')] += savings[i].amount
      }

      return result
    },

    calculateFundingReceived () {
      let result = this.getEmptyCalculation()
      const fundingReceived = this.getIdea().storyMode.setUp.financing.finances.filter(
        finance => finance.type === 'crowdfunding' || finance.type === 'investment'
      )

      for (let i = 0; i < fundingReceived.length; i++) {
        if (fundingReceived[i].type === 'crowdfunding') {
          result = this.sumObjectsByKey(result, this.calculateCrowdfunding(fundingReceived[i]))
        }

        if (fundingReceived[i].type === 'investment') {
          result = this.sumObjectsByKey(result, this.calculateInvestment(fundingReceived[i]))
        }
      }

      return result
    },

    calculateCreditsAndLoansIncome () {
      let result = this.getEmptyCalculation()
      const changeInDebt = this.getIdea().storyMode.setUp.financing.finances.filter(
        finance => finance.type === 'loc' || finance.type === 'loan'
      )

      for (let i = 0; i < changeInDebt.length; i++) {
        if (changeInDebt[i].type === 'loc') {
          result = this.sumObjectsByKey(result, this.calculateLineOfCredit(changeInDebt[i]))
        }

        if (changeInDebt[i].type === 'loan') {
          result = this.sumObjectsByKey(result, this.calculateLoan(changeInDebt[i]))
        }
      }

      return result
    },

    calculateCreditsAndLoansOutcome () {
      let result = this.getEmptyCalculation()
      const changeInDebt = this.getIdea().storyMode.setUp.financing.finances.filter(
        finance => finance.type === 'loc' || finance.type === 'loan'
      )

      for (let i = 0; i < changeInDebt.length; i++) {
        if (changeInDebt[i].type === 'loc') {
          result = this.sumObjectsByKey(result, this.calculateLineOfCreditPayment(changeInDebt[i], true))
          result = this.sumObjectsByKey(result, this.calculateLineOfCreditInterestRate(changeInDebt[i], true))
        }

        if (changeInDebt[i].type === 'loan') {
          result = this.sumObjectsByKey(result, this.calculateLoanMonthlyInstallment(changeInDebt[i], true))
          result = this.sumObjectsByKey(result, this.calculateLoanInterestRate(changeInDebt[i], true))
        }
      }

      return result
    },

    calculateChangeInDebt () {
      let result = this.getEmptyCalculation()
      const changeInDebt = this.getIdea().storyMode.setUp.financing.finances.filter(
        finance => finance.type === 'loc' || finance.type === 'loan'
      )

      for (let i = 0; i < changeInDebt.length; i++) {
        if (changeInDebt[i].type === 'loc') {
          result = this.sumObjectsByKey(result, this.calculateLineOfCredit(changeInDebt[i]))
          result = this.sumObjectsByKey(result, this.calculateLineOfCreditPayment(changeInDebt[i], true))
          result = this.sumObjectsByKey(result, this.calculateLineOfCreditInterestRate(changeInDebt[i], true))
        }

        if (changeInDebt[i].type === 'loan') {
          result = this.sumObjectsByKey(result, this.calculateLoan(changeInDebt[i]))
          result = this.sumObjectsByKey(result, this.calculateLoanMonthlyInstallment(changeInDebt[i], true))
          result = this.sumObjectsByKey(result, this.calculateLoanInterestRate(changeInDebt[i], true))
        }
      }

      return result
    },

    calculateDividends (isNegative) {
      const result = this.getEmptyCalculation()
      const dividends = this.getIdea().storyMode.forecast.profitAndCashFlow.dividends

      for (let i = 0; i < dividends.length; i++) {
        const dateString = this.$moment(dividends[i].whenWillItOccur).format('MMM YY')

        if (dividends[i].oneTime === true) {
          result[dateString] = (isNegative ? -1 : 1) * dividends[i].amount
        } else {
          let j = 0
          const numberOfMonthsByInterval = this.getNumberOfMonthsByInterval(dividends[i].distributionFrequency)
          while (this.$moment(dividends[i].whenWillItOccur).add(j * numberOfMonthsByInterval, 'M').format('MMM YY') in result) {
            result[this.$moment(dividends[i].whenWillItOccur).add(j * numberOfMonthsByInterval, 'M').format('MMM YY')] += (isNegative ? -1 : 1) * dividends[i].amount
            j++
          }
        }
      }

      return result
    },

    calculateLineOfCredit (lineOfCredit) {
      const result = {}
      const withdrawalPeriod = Math.floor(lineOfCredit.period / lineOfCredit.howManyTimes)

      for (let i = 0; i < lineOfCredit.howManyTimes; i++) {
        result[this.$moment(lineOfCredit.date).add(i * withdrawalPeriod, 'M').format('MMM YY')] = lineOfCredit.averageWithdrawalAmount
      }

      return result
    },

    calculateLineOfCreditPayment (lineOfCredit, isNegative) {
      const result = {}
      const withdrawalPeriod = Math.floor(lineOfCredit.period / lineOfCredit.howManyTimes)
      const numberOfMonthsPaymentFrequency = this.getNumberOfMonthsForLineOfCreditPaymentFrequency(lineOfCredit)

      for (let i = 0; i < lineOfCredit.howManyTimes; i++) {
        const dateString = this.$moment(lineOfCredit.date).add(i * withdrawalPeriod + numberOfMonthsPaymentFrequency, 'M').format('MMM YY')
        result[dateString] = (isNegative ? -1 : 1) * lineOfCredit.averageWithdrawalAmount
      }

      return result
    },

    calculateLineOfCreditInterestRate (lineOfCredit, isNegative) {
      const result = {}
      const withdrawalPeriod = Math.floor(lineOfCredit.period / lineOfCredit.howManyTimes)
      const numberOfMonthsPaymentFrequency = this.getNumberOfMonthsForLineOfCreditPaymentFrequency(lineOfCredit)

      for (let i = 0; i < lineOfCredit.howManyTimes; i++) {
        const dateStringWithdrawal = this.$moment(lineOfCredit.date).add(i * withdrawalPeriod, 'M').format('MMM YY')
        for (let j = 0; j < numberOfMonthsPaymentFrequency; j++) {
          const dateString = this.$moment(dateStringWithdrawal, 'MMM YY').add(j + 1, 'M').format('MMM YY')
          result[dateString] = (isNegative ? -1 : 1) * (parseFloat(lineOfCredit.interestRate) / 100 / 12) * lineOfCredit.averageWithdrawalAmount
        }
      }

      return result
    },

    calculateLoan (loan) {
      const result = {}
      const dateString = this.$moment(loan.date).format('MMM YY')

      result[dateString] = loan.amount

      return result
    },

    calculateLoanMonthlyInstallment (loan, isNegative) {
      const result = {}

      const principal = parseFloat(loan.amount)
      const interestPerPeriod = parseFloat(loan.annualInterestRate) / 100 / 12
      const repaymentPeriod = loan.repaymentPeriod

      // Calculate the monthly payment figure
      const x = Math.pow(1 + interestPerPeriod, repaymentPeriod)
      let monthlyPayment = (principal * x * interestPerPeriod) / (x - 1)
      monthlyPayment = isNaN(monthlyPayment) ? loan.amount / repaymentPeriod : parseFloat(monthlyPayment.toFixed(2))

      if (loan.gracePeriod > 0) {
        for (let i = 0; i < loan.gracePeriod; i++) {
          const dateString = this.$moment(loan.date).add(i + 1, 'M').format('MMM YY')
          result[dateString] = (isNegative ? -1 : 1) * interestPerPeriod * loan.amount
        }
      }

      for (let j = 0; j < loan.repaymentPeriod; j++) {
        const dateString = this.$moment(loan.date).add(j + 1 + loan.gracePeriod, 'M').format('MMM YY')
        result[dateString] = (isNegative ? -1 : 1) * monthlyPayment
      }

      return result
    },

    calculateLoanInterestRate (loan, isNegative) {
      const result = {}

      const principal = parseFloat(loan.amount)
      const interestPerPeriod = parseFloat(loan.annualInterestRate) / 100 / 12
      let balance = principal

      // Calculate the monthly payment figure
      const x = Math.pow(1 + interestPerPeriod, loan.repaymentPeriod)
      const monthlyPayment = (principal * x * interestPerPeriod) / (x - 1)

      for (let i = 0; i < loan.repaymentPeriod; i++) {
        const dateString = this.$moment(loan.date).add(i, 'M').format('MMM YY')
        result[dateString] = 0

        if (i !== 0) {
          if (i > loan.gracePeriod + 1) {
            balance = balance - monthlyPayment + balance * interestPerPeriod
          }

          result[dateString] = (isNegative ? -1 : 1) * balance * interestPerPeriod
        }
      }

      return result
    },

    calculateCrowdfunding (crowdfunding) {
      const result = {}
      const dateString = this.$moment(crowdfunding.date).format('MMM YY')

      result[dateString] = crowdfunding.amount

      return result
    },

    calculateInvestment (investment) {
      const result = {}
      const dateString = this.$moment(investment.date).format('MMM YY')

      if (investment.oneTime === true) {
        result[dateString] = investment.amount
      } else {
        const installmentAmount = investment.amount / investment.numberOfInstallments
        const numberOfMonthsByInterval = this.getNumberOfMonthsByInterval(investment.paymentMethod)

        for (let i = 0; i < investment.numberOfInstallments; i++) {
          result[this.$moment(investment.date).add(i * numberOfMonthsByInterval, 'M').format('MMM YY')] = installmentAmount
        }
      }

      return result
    },

    getEmptyCalculation (format = 'MMM YY') {
      const result = {}
      const startingString = this.getIdea().ideaSettings.startingFrom
      const planningMonths = this.getNumberOfMonthsPlanning()

      for (let i = 0; i < planningMonths; i++) {
        result[this.$moment(startingString).add(i, 'M').format(format)] = 0
      }

      return result
    },

    getNumberOfMonthsPlanning () {
      return this.getIdea().ideaSettings.planningFor * 12
    },

    sumObjectsByKey (...objs) {
      return objs.reduce((a, b) => {
        for (const k in b) {
          if (Object.prototype.hasOwnProperty.call(b, k)) {
            a[k] = (a[k] || 0) + b[k]
          }
        }

        return a
      }, {})
    },

    calculateMargin (object1, object2) {
      for (const k in object2) {
        if (Object.prototype.hasOwnProperty.call(object2, k)) {
          object1[k] = object2[k] > 0 ? ((object1[k] || 0) / object2[k] * 100) : 0
        }
      }

      return object1
    },

    getNumberOfMonthsByInterval (interval) {
      const numberOfMonths = {
        daily: 0.032786885,
        weekly: 0.229357798,
        monthly: 1,
        quarterly: 3,
        annually: 12,
        yearly: 12
      }

      return numberOfMonths[interval.toLowerCase()]
    },

    getNumberOfMonthsForLineOfCreditPaymentFrequency (lineOfCredit) {
      if (lineOfCredit.paymentFrequency === 4) {
        return 6
      }

      if (lineOfCredit.paymentFrequency === 5) {
        return 12
      }

      return lineOfCredit.paymentFrequency
    },

    createSeriesDataFromCalculation (data) {
      const result = this.getEmptySeriesData()
      const chartCategoriesDateFormat = this.getChartCategoriesDateFormat()

      if (this.period === 'monthly' || this.period === 'quarterly') {
        for (const [dateString, value] of Object.entries(data)) {
          const dateStringInCategoryFormat = this.$moment(dateString, 'MMM YY').format(chartCategoriesDateFormat)

          if (this.chartOptions.xaxis.categories.indexOf(dateStringInCategoryFormat) !== -1) {
            result[this.chartOptions.xaxis.categories.indexOf(dateStringInCategoryFormat)] += value
          }
        }
      } else {
        const dataArray = Object.entries(data)
        for (let i = 0; i < dataArray.length; i++) {
          const yearIndex = Math.ceil((i + 1) / 12)
          if (this.chartOptions.xaxis.categories.indexOf('Y' + yearIndex) !== -1) {
            result[this.chartOptions.xaxis.categories.indexOf('Y' + yearIndex)] += dataArray[i][1]
          }
        }
      }

      return result
    },

    createTableDataFromCalculation (data) {
      const tableColumnsDateFormat = this.getTableColumnsDateFormat()
      const result = this.getEmptyCalculation(tableColumnsDateFormat)

      for (const [dateString, value] of Object.entries(data)) {
        const dateStringInColumnsFormat = this.$moment(dateString, 'MMM YY').format(tableColumnsDateFormat)

        if (Object.prototype.hasOwnProperty.call(result, dateStringInColumnsFormat)) {
          result[dateStringInColumnsFormat] += value
        }
      }

      return result
    },

    getEmptySeriesData () {
      const result = []

      for (let j = 0; j < this.chartOptions.xaxis.categories.length; j++) {
        result[j] = 0
      }

      return result
    },

    getChartCategoriesDateFormat () {
      let format = 'MMM [\']YY'

      if (this.period === 'quarterly') {
        format = '[Q]Q [\']YY'
      }

      if (this.period === 'yearly') {
        format = 'YYYY'
      }

      return format
    },

    getTableColumnsDateFormat () {
      let format = 'MMM YY'

      if (this.period === 'quarterly') {
        format = '[Q]Q YY'
      }

      if (this.period === 'yearly') {
        format = 'YYYY'
      }

      return format
    },

    getRevenueStream (revenueStreamId) {
      return this.getIdea().storyMode.forecast.revenueStreams.revenueStreams.find(revenueStream => revenueStream.id === revenueStreamId)
    },

    getDateStringPlanningStartYear () {
      return this.$moment(this.getIdea().ideaSettings.startingFrom).subtract(1, 'M').format('YYYY')
    },

    calculateDirectCostAverage (calculation, directCost) {
      return this.calculateSumForPlanningPeriod(calculation) / (this.$moment(directCost.ends).diff(this.$moment(directCost.starts), 'M') + 1)
    },

    calculatePayrollExpenseAverage (calculation, payrollExpense) {
      return this.calculateSumForPlanningPeriod(calculation) / (this.$moment(payrollExpense.salaryEnds).diff(this.$moment(payrollExpense.salaryStarts), 'M') + 1)
    },

    calculateMarketingExpenseAverage (calculation, marketingExpense) {
      const sum = this.calculateSumForPlanningPeriod(calculation)

      if (marketingExpense.amountIntervalType === 'one_time') {
        return sum / this.getNumberOfMonthsPlanning()
      }

      return sum / (this.$moment(marketingExpense.ends).diff(this.$moment(marketingExpense.starts), 'M') + 1)
    },

    calculateOtherOverheadAverage (calculation, otherOverhead) {
      const sum = this.calculateSumForPlanningPeriod(calculation)

      if (otherOverhead.oneTime === true) {
        return sum / this.getNumberOfMonthsPlanning()
      }

      return sum / (this.$moment(otherOverhead.ends).diff(this.$moment(otherOverhead.starts), 'M') + 1)
    },

    calculateAverageForPlanningPeriod (calculation) {
      return this.calculateSumForPlanningPeriod(calculation) / this.getNumberOfMonthsPlanning()
    },

    calculateSumForPlanningPeriod (calculation) {
      return Object.values(calculation).reduce((a, b) => a + b)
    },

    calculateSumForOneYearPlanningPeriod (calculation, year) {
      let result = 0
      const calculationStartDateString = this.$moment(this.getIdea().ideaSettings.startingFrom).add(year - 1, 'Y')
      const calculationEndDateString = this.$moment(this.getIdea().ideaSettings.startingFrom).add(year, 'Y')

      for (const [dateString, amount] of Object.entries(calculation)) {
        if (this.$moment(dateString, 'MMM YY') >= calculationStartDateString && this.$moment(dateString, 'MMM YY') < calculationEndDateString) {
          result += amount
        }
      }

      return result
    },

    getDateStringPeriodEndMonth () {
      return this.$moment(this.getIdea().ideaSettings.startingFrom).subtract(1, 'M').format('MMM')
    }
  }
}
