import MixinRevenueStreams from '@/mixins/revenueStreams'

export default {
  mixins: [
    MixinRevenueStreams
  ],

  methods: {
    calculateDirectCostPercentageAverageAmount (directCost) {
      const monthlyInterval = {
        Monthly: 1,
        Quarterly: 3,
        Annually: 12
      }

      if (directCost.isConstantAmount === true) {
        return directCost.amount / monthlyInterval[directCost.constantAmountInterval]
      }

      let result = 0
      const startMoment = this.$moment(directCost.starts)
      const endMoment = this.$moment(directCost.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateString = this.$moment(directCost.starts).add(i, 'M').format('MMM YY')
        result += this.calculateDirectCostPercentageAmountForPeriod(directCost, dateString, 'MMM YY')
      }

      return result / totalAmountOfMonths
    },

    calculateDirectCostPercentageTotalAmount (directCost) {
      const monthlyInterval = {
        Monthly: 1,
        Quarterly: 3,
        Annually: 12
      }

      if (directCost.isConstantAmount === true) {
        return directCost.amount / monthlyInterval[directCost.constantAmountInterval]
      }

      let result = 0
      const startMoment = this.$moment(directCost.starts)
      const endMoment = this.$moment(directCost.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateString = this.$moment(directCost.starts).add(i, 'M').format('MMM YY')
        result += this.calculateDirectCostPercentageAmountForPeriod(directCost, dateString, 'MMM YY')
      }

      return result
    },

    calculateDirectCostPercentageAmountForPeriod (directCost, dateString, format) {
      let result = 0
      const revenueStream = this.getRevenueStream(directCost.revenueStreamId)
      const revenueStreamCalculated = this.calculateRevenueStreamAmount(revenueStream, 'MMM YY')

      const totalAmountOfMonths = this.$moment(directCost.ends).diff(this.$moment(directCost.starts), 'months') + 1

      for (let i = 0; i < totalAmountOfMonths; i++) {
        const dateStringToCompare = this.$moment(directCost.starts).add(i, 'M').format(format)
        const dateStringToCompareInMonths = this.$moment(directCost.starts).add(i, 'M').format('MMM YY')
        if (dateStringToCompare === dateString) {
          const index = revenueStreamCalculated.values.indexOf(
            revenueStreamCalculated.values.find(valueToFind => Object.keys(valueToFind)[0] === dateStringToCompareInMonths)
          )

          if (index !== -1) {
            result += revenueStreamCalculated.values[index][dateStringToCompareInMonths] * directCost.percentageOfRevenue / 100
          }
        }
      }

      return result
    },

    calculateDirectCostConstantTotalAmount (directCost) {
      const period = {
        Monthly: 'months',
        Quarterly: 'quarter',
        Annually: 'years'
      }

      const startMoment = this.$moment(directCost.starts)
      const endMoment = this.$moment(directCost.ends)
      const totalAmountOfPeriods = endMoment.diff(startMoment, period[directCost.constantAmountInterval]) + 1

      return totalAmountOfPeriods * directCost.amount
    },

    getPeriodToAdd () {
      let format = 'M'

      if (this.period === 'quarterly') {
        format = 'Q'
      }

      if (this.period === 'yearly') {
        format = 'Y'
      }

      return format
    },

    getNumberOfPeriods (directCost, format) {
      const result = []
      const startMoment = this.$moment(directCost.starts)
      const endMoment = this.$moment(directCost.ends)
      const totalAmountOfMonths = endMoment.diff(startMoment, 'months') + 1

      for (let i = 0; i < totalAmountOfMonths; i++) {
        if (result.indexOf(this.$moment(directCost.starts).add(i, 'M').format(format)) === -1) {
          result.push(this.$moment(directCost.starts).add(i, 'M').format(format))
        }
      }

      return result.length
    }
  }
}
