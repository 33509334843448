<template>

  <!-- Actions Dropdown -->
  <el-dropdown ref="itemDropdown" class="action-items" trigger="click" @command="handleProductCommand">

    <!-- Action Items Image -->
    <span class="el-dropdown-link" @click.stop>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M170.667 85.333c23.573 0 42.667-19.093 42.667-42.667C213.333 19.093 194.24 0 170.667 0S128 19.093 128 42.667c0 23.573 19.093 42.666 42.667 42.666zM170.667 128C147.093 128 128 147.093 128 170.667s19.093 42.667 42.667 42.667 42.667-19.093 42.667-42.667S194.24 128 170.667 128zM170.667 256C147.093 256 128 275.093 128 298.667c0 23.573 19.093 42.667 42.667 42.667s42.667-19.093 42.667-42.667C213.333 275.093 194.24 256 170.667 256z"/></svg>
    </span>
    <!-- /Action Items Image -->

    <!-- Actions Dropdown -->
    <el-dropdown-menu slot="dropdown" class="action-dropdown">

      <!-- Edit -->
      <el-dropdown-item :disabled="editDisabled" :command="{ name: 'edit', entity: entity}">
        {{ $t('edit') }}
      </el-dropdown-item>
      <!-- /Edit -->

      <!-- Copy -->
      <el-dropdown-item :disabled="copyDisabled" :command="{ name: 'copy', entity: entity}">
        {{ $t('copy') }}
      </el-dropdown-item>
      <!-- /Copy -->

      <!-- Delete -->
      <el-dropdown-item :disabled="deleteDisabled" :command="{ name: 'delete', entity: entity}">
        {{ $t('delete') }}
      </el-dropdown-item>
      <!-- /Delete -->

    </el-dropdown-menu>
    <!-- /Actions Dropdown -->

  </el-dropdown>
  <!-- /Actions Dropdown -->

</template>

<script>
export default {
  name: 'ActionsDropdown',

  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    deleteDisabled: {
      type: Boolean,
      default: false
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleProductCommand (command) {
      if (command.name === 'edit') {
        this.$emit('edit', command.entity)
      } else if (command.name === 'copy') {
        this.$emit('copy', command.entity)
      } else {
        this.$emit('delete', command.entity)
      }
    }
  }
}
</script>
