<template>
  <div
    class="avatar-text"
    :class="{'circle': circle}"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'AvatarText',

  props: {
    text: {
      type: [String, Number],
      required: true
    },
    circle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.avatar-text {
  font-family: "outfit-medium";
  margin-right: 12px;
  padding: 8px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 30%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  color: #0b42b0;
  background-color: rgba(11, 66, 176, 0.1);
  flex-shrink: 0;
  box-sizing: content-box;
}

.circle {
  border-radius: 50%;
}
</style>
