<template>
  <div
    class="completed-overlay"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'CompletedOverlay'
}
</script>

<style scoped>
.completed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 100;
}
</style>
