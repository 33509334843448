export default {
  data () {
    return {
      currencies: [
        {
          symbol: '$',
          name: 'US Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'USD',
          namePlural: 'US dollars'
        },
        {
          symbol: '€',
          name: 'Euro',
          symbolNative: '€',
          decimalDigits: 2,
          rounding: 0,
          code: 'EUR',
          namePlural: 'euros'
        },
        {
          symbol: '£',
          name: 'British Pound Sterling',
          symbolNative: '£',
          decimalDigits: 2,
          rounding: 0,
          code: 'GBP',
          namePlural: 'British pounds sterling'
        },
        {
          symbol: 'Rs',
          name: 'Indian Rupee',
          symbolNative: '₹',
          decimalDigits: 2,
          rounding: 0,
          code: 'INR',
          namePlural: 'Indian rupees'
        },
        {
          symbol: 'CA$',
          name: 'Canadian Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'CAD',
          namePlural: 'Canadian dollars'
        },
        {
          symbol: 'AU$',
          name: 'Australian Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'AUD',
          namePlural: 'Australian dollars'
        },
        {
          symbol: 'Af',
          name: 'Afghan Afghani',
          symbolNative: '؋',
          decimalDigits: 0,
          rounding: 0,
          code: 'AFN',
          namePlural: 'Afghan Afghanis'
        },
        {
          symbol: 'ALL',
          name: 'Albanian Lek',
          symbolNative: 'Lek',
          decimalDigits: 0,
          rounding: 0,
          code: 'ALL',
          namePlural: 'Albanian lekë'
        },
        {
          symbol: 'DA',
          name: 'Algerian Dinar',
          symbolNative: 'د.ج.‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'DZD',
          namePlural: 'Algerian dinars'
        },
        {
          symbol: 'Kz',
          name: 'Angolan Kwanza',
          symbolNative: 'Kz‏',
          decimalDigits: 0,
          rounding: 0,
          code: 'AOA',
          namePlural: 'Angolan Kwanzas'
        },
        {
          symbol: 'AMD',
          name: 'Armenian Dram',
          symbolNative: 'դր.',
          decimalDigits: 0,
          rounding: 0,
          code: 'AMD',
          namePlural: 'Armenian drams'
        },
        {
          symbol: 'AR$',
          name: 'Argentine Peso',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'ARS',
          namePlural: 'Argentine pesos'
        },
        {
          symbol: 'ƒ',
          name: 'Aruban florin',
          symbolNative: 'ƒ',
          decimalDigits: 2,
          rounding: 0,
          code: 'AWG',
          namePlural: 'Aruban florin'
        },
        {
          symbol: 'man.',
          name: 'Azerbaijani Manat',
          symbolNative: 'ман.',
          decimalDigits: 2,
          rounding: 0,
          code: 'AZN',
          namePlural: 'Azerbaijani manats'
        },
        {
          symbol: 'BD',
          name: 'Bahraini Dinar',
          symbolNative: 'د.ب.‏',
          decimalDigits: 3,
          rounding: 0,
          code: 'BHD',
          namePlural: 'Bahraini dinars'
        },
        {
          symbol: '$',
          name: 'Bahamian dollar',
          symbolNative: '$',
          decimalDigits: 0,
          rounding: 0,
          code: 'BSD',
          namePlural: 'Bahamian dollar'
        },
        {
          symbol: 'Tk',
          name: 'Bangladeshi Taka',
          symbolNative: '৳',
          decimalDigits: 2,
          rounding: 0,
          code: 'BDT',
          namePlural: 'Bangladeshi takas'
        },
        {
          symbol: '$',
          name: 'Barbadian dollar',
          symbolNative: '$',
          decimalDigits: 0,
          rounding: 0,
          code: 'BBD',
          namePlural: 'Barbadian dollars'
        },
        {
          symbol: 'BYR',
          name: 'Belarusian Ruble',
          symbolNative: 'BYR',
          decimalDigits: 0,
          rounding: 0,
          code: 'BYR',
          namePlural: 'Belarusian rubles'
        },
        {
          symbol: 'BZ$',
          name: 'Belize Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'BZD',
          namePlural: 'Belize dollars'
        },
        {
          symbol: '$',
          name: 'Bermudian Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'BMD',
          namePlural: 'Bermudian Dollar'
        },
        {
          symbol: 'Nu.',
          name: 'Bhutanese Ngultrum',
          symbolNative: 'Nu.',
          decimalDigits: 2,
          rounding: 0,
          code: 'BTN',
          namePlural: 'Bhutanese Ngultrum'
        },
        {
          symbol: 'Bs',
          name: 'Bolivian Boliviano',
          symbolNative: 'Bs',
          decimalDigits: 2,
          rounding: 0,
          code: 'BOB',
          namePlural: 'Bolivian bolivianos'
        },
        {
          symbol: 'KM',
          name: 'Bosnia-Herzegovina Convertible Mark',
          symbolNative: 'KM',
          decimalDigits: 2,
          rounding: 0,
          code: 'BAM',
          namePlural: 'Bosnia-Herzegovina convertible marks'
        },
        {
          symbol: 'BWP',
          name: 'Botswanan Pula',
          symbolNative: 'P',
          decimalDigits: 2,
          rounding: 0,
          code: 'BWP',
          namePlural: 'Botswanan pulas'
        },
        {
          symbol: 'R$',
          name: 'Brazilian Real',
          symbolNative: 'R$',
          decimalDigits: 2,
          rounding: 0,
          code: 'BRL',
          namePlural: 'Brazilian reals'
        },
        {
          symbol: 'BN$',
          name: 'Brunei Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'BND',
          namePlural: 'Brunei dollars'
        },
        {
          symbol: 'BGN',
          name: 'Bulgarian Lev',
          symbolNative: 'лв.',
          decimalDigits: 2,
          rounding: 0,
          code: 'BGN',
          namePlural: 'Bulgarian leva'
        },
        {
          symbol: 'FBu',
          name: 'Burundian Franc',
          symbolNative: 'FBu',
          decimalDigits: 0,
          rounding: 0,
          code: 'BIF',
          namePlural: 'Burundian francs'
        },
        {
          symbol: 'KHR',
          name: 'Cambodian Riel',
          symbolNative: '៛',
          decimalDigits: 2,
          rounding: 0,
          code: 'KHR',
          namePlural: 'Cambodian riels'
        },
        {
          symbol: 'CV$',
          name: 'Cape Verdean Escudo',
          symbolNative: 'CV$',
          decimalDigits: 2,
          rounding: 0,
          code: 'CVE',
          namePlural: 'Cape Verdean escudos'
        },
        {
          symbol: '$',
          name: 'Cayman Islands dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'KYD',
          namePlural: 'Cayman Islands dollarS'
        },
        {
          symbol: 'CL$',
          name: 'Chilean Peso',
          symbolNative: '$',
          decimalDigits: 0,
          rounding: 0,
          code: 'CLP',
          namePlural: 'Chilean pesos'
        },
        {
          symbol: 'CN¥',
          name: 'Chinese Yuan',
          symbolNative: 'CN¥',
          decimalDigits: 2,
          rounding: 0,
          code: 'CNY',
          namePlural: 'Chinese yuan'
        },
        {
          symbol: 'CO$',
          name: 'Colombian Peso',
          symbolNative: '$',
          decimalDigits: 0,
          rounding: 0,
          code: 'COP',
          namePlural: 'Colombian pesos'
        },
        {
          symbol: 'CF',
          name: 'Comorian Franc',
          symbolNative: 'FC',
          decimalDigits: 0,
          rounding: 0,
          code: 'KMF',
          namePlural: 'Comorian francs'
        },
        {
          symbol: 'CDF',
          name: 'Congolese Franc',
          symbolNative: 'FrCD',
          decimalDigits: 2,
          rounding: 0,
          code: 'CDF',
          namePlural: 'Congolese francs'
        },
        {
          symbol: '$',
          name: 'Cook Islands dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'CKD',
          namePlural: 'Cook Islands dollars'
        },
        {
          symbol: '₡',
          name: 'Costa Rican Colón',
          symbolNative: '₡',
          decimalDigits: 0,
          rounding: 0,
          code: 'CRC',
          namePlural: 'Costa Rican colóns'
        },
        {
          symbol: 'kn',
          name: 'Croatian Kuna',
          symbolNative: 'kn',
          decimalDigits: 2,
          rounding: 0,
          code: 'HRK',
          namePlural: 'Croatian kunas'
        },
        {
          symbol: '$',
          name: 'Cuban peso',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'CUP',
          namePlural: 'Cuban pesos'
        },
        {
          symbol: '$',
          name: 'Cuban convertible peso',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'CUC',
          namePlural: 'Cuban convertible pesos'
        },
        {
          symbol: 'Kč',
          name: 'Czech Republic Koruna',
          symbolNative: 'Kč',
          decimalDigits: 2,
          rounding: 0,
          code: 'CZK',
          namePlural: 'Czech Republic korunas'
        },
        {
          symbol: 'Dkr',
          name: 'Danish Krone',
          symbolNative: 'kr',
          decimalDigits: 2,
          rounding: 0,
          code: 'DKK',
          namePlural: 'Danish kroner'
        },
        {
          symbol: 'Fdj',
          name: 'Djiboutian Franc',
          symbolNative: 'Fdj',
          decimalDigits: 0,
          rounding: 0,
          code: 'DJF',
          namePlural: 'Djiboutian francs'
        },
        {
          symbol: 'RD$',
          name: 'Dominican Peso',
          symbolNative: 'RD$',
          decimalDigits: 2,
          rounding: 0,
          code: 'DOP',
          namePlural: 'Dominican pesos'
        },
        {
          symbol: '$',
          name: 'Eastern Caribbean Dollar',
          symbolNative: '$‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'XCD',
          namePlural: 'Eastern Caribbean Dollars'
        },
        {
          symbol: 'EGP',
          name: 'Egyptian Pound',
          symbolNative: 'ج.م.‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'EGP',
          namePlural: 'Egyptian pounds'
        },
        {
          symbol: 'Nfk',
          name: 'Eritrean Nakfa',
          symbolNative: 'Nfk',
          decimalDigits: 2,
          rounding: 0,
          code: 'ERN',
          namePlural: 'Eritrean nakfas'
        },
        {
          symbol: 'Ekr',
          name: 'Estonian Kroon',
          symbolNative: 'kr',
          decimalDigits: 2,
          rounding: 0,
          code: 'EEK',
          namePlural: 'Estonian kroons'
        },
        {
          symbol: 'Br',
          name: 'Ethiopian Birr',
          symbolNative: 'Br',
          decimalDigits: 2,
          rounding: 0,
          code: 'ETB',
          namePlural: 'Ethiopian birrs'
        },
        {
          symbol: '£',
          name: 'Falkland Island Pound',
          symbolNative: '£',
          decimalDigits: 2,
          rounding: 0,
          code: 'FKP',
          namePlural: 'Falkland Island Pounds'
        },
        {
          symbol: '$',
          name: 'Fijian dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'FJD',
          namePlural: 'Fijian dollars'
        },
        {
          symbol: 'D',
          name: 'Gambian dalasi',
          symbolNative: 'D',
          decimalDigits: 2,
          rounding: 0,
          code: 'GMD',
          namePlural: 'Gambian dalasi'
        },
        {
          symbol: 'GEL',
          name: 'Georgian Lari',
          symbolNative: 'GEL',
          decimalDigits: 2,
          rounding: 0,
          code: 'GEL',
          namePlural: 'Georgian laris'
        },
        {
          symbol: 'GH₵',
          name: 'Ghanaian Cedi',
          symbolNative: 'GH₵',
          decimalDigits: 2,
          rounding: 0,
          code: 'GHS',
          namePlural: 'Ghanaian cedis'
        },
        {
          symbol: '£',
          name: 'Gibraltar Pound',
          symbolNative: '£',
          decimalDigits: 2,
          rounding: 0,
          code: 'GIP',
          namePlural: 'Gibraltar Pound'
        },
        {
          symbol: 'GTQ',
          name: 'Guatemalan Quetzal',
          symbolNative: 'Q',
          decimalDigits: 2,
          rounding: 0,
          code: 'GTQ',
          namePlural: 'Guatemalan quetzals'
        },
        {
          symbol: 'FG',
          name: 'Guinean Franc',
          symbolNative: 'FG',
          decimalDigits: 0,
          rounding: 0,
          code: 'GNF',
          namePlural: 'Guinean francs'
        },
        {
          symbol: '$',
          name: 'Guyanese dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'GYD',
          namePlural: 'Guyanese dollars'
        },
        {
          symbol: 'G',
          name: 'Haitian gourde',
          symbolNative: 'G',
          decimalDigits: 2,
          rounding: 0,
          code: 'HTG',
          namePlural: 'Haitian gourde'
        },
        {
          symbol: 'HNL',
          name: 'Honduran Lempira',
          symbolNative: 'L',
          decimalDigits: 2,
          rounding: 0,
          code: 'HNL',
          namePlural: 'Honduran lempiras'
        },
        {
          symbol: 'HK$',
          name: 'Hong Kong Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'HKD',
          namePlural: 'Hong Kong dollars'
        },
        {
          symbol: 'Ft',
          name: 'Hungarian Forint',
          symbolNative: 'Ft',
          decimalDigits: 0,
          rounding: 0,
          code: 'HUF',
          namePlural: 'Hungarian forints'
        },
        {
          symbol: 'Ikr',
          name: 'Icelandic Króna',
          symbolNative: 'kr',
          decimalDigits: 0,
          rounding: 0,
          code: 'ISK',
          namePlural: 'Icelandic krónur'
        },
        {
          symbol: 'Rp',
          name: 'Indonesian Rupiah',
          symbolNative: 'Rp',
          decimalDigits: 0,
          rounding: 0,
          code: 'IDR',
          namePlural: 'Indonesian rupiahs'
        },
        {
          symbol: 'IRR',
          name: 'Iranian Rial',
          symbolNative: '﷼',
          decimalDigits: 0,
          rounding: 0,
          code: 'IRR',
          namePlural: 'Iranian rials'
        },
        {
          symbol: 'IQD',
          name: 'Iraqi Dinar',
          symbolNative: 'د.ع.‏',
          decimalDigits: 0,
          rounding: 0,
          code: 'IQD',
          namePlural: 'Iraqi dinars'
        },
        {
          symbol: '₪',
          name: 'Israeli New Sheqel',
          symbolNative: '₪',
          decimalDigits: 2,
          rounding: 0,
          code: 'ILS',
          namePlural: 'Israeli new sheqels'
        },
        {
          symbol: 'J$',
          name: 'Jamaican Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'JMD',
          namePlural: 'Jamaican dollars'
        },
        {
          symbol: '¥',
          name: 'Japanese Yen',
          symbolNative: '￥',
          decimalDigits: 0,
          rounding: 0,
          code: 'JPY',
          namePlural: 'Japanese yen'
        },
        {
          symbol: 'JD',
          name: 'Jordanian Dinar',
          symbolNative: 'د.أ.‏',
          decimalDigits: 3,
          rounding: 0,
          code: 'JOD',
          namePlural: 'Jordanian dinars'
        },
        {
          symbol: 'KZT',
          name: 'Kazakhstani Tenge',
          symbolNative: 'тңг.',
          decimalDigits: 2,
          rounding: 0,
          code: 'KZT',
          namePlural: 'Kazakhstani tenges'
        },
        {
          symbol: 'Ksh',
          name: 'Kenyan Shilling',
          symbolNative: 'Ksh',
          decimalDigits: 2,
          rounding: 0,
          code: 'KES',
          namePlural: 'Kenyan shillings'
        },
        {
          symbol: 'KD',
          name: 'Kuwaiti Dinar',
          symbolNative: 'د.ك.‏',
          decimalDigits: 3,
          rounding: 0,
          code: 'KWD',
          namePlural: 'Kuwaiti dinars'
        },
        {
          symbol: 'c',
          name: 'Kyrgyzstani som',
          symbolNative: 'c',
          decimalDigits: 2,
          rounding: 0,
          code: 'KGS',
          namePlural: 'Kyrgyzstani som'
        },
        {
          symbol: 'K',
          name: 'Lao kip',
          symbolNative: 'K',
          decimalDigits: 0,
          rounding: 0,
          code: 'LAK',
          namePlural: 'Lao kip'
        },
        {
          symbol: 'LB£',
          name: 'Lebanese Pound',
          symbolNative: 'ل.ل.‏',
          decimalDigits: 0,
          rounding: 0,
          code: 'LBP',
          namePlural: 'Lebanese pounds'
        },
        {
          symbol: '$',
          name: 'Liberian Dollar',
          symbolNative: '$‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'LRD',
          namePlural: 'Liberian Dollars'
        },
        {
          symbol: 'LD',
          name: 'Libyan Dinar',
          symbolNative: 'د.ل.‏',
          decimalDigits: 3,
          rounding: 0,
          code: 'LYD',
          namePlural: 'Libyan dinars'
        },
        {
          symbol: 'MOP$',
          name: 'Macanese Pataca',
          symbolNative: 'MOP$',
          decimalDigits: 2,
          rounding: 0,
          code: 'MOP',
          namePlural: 'Macanese patacas'
        },
        {
          symbol: 'MKD',
          name: 'Macedonian Denar',
          symbolNative: 'MKD',
          decimalDigits: 2,
          rounding: 0,
          code: 'MKD',
          namePlural: 'Macedonian denari'
        },
        {
          symbol: 'MGA',
          name: 'Malagasy Ariary',
          symbolNative: 'MGA',
          decimalDigits: 0,
          rounding: 0,
          code: 'MGA',
          namePlural: 'Malagasy Ariaries'
        },
        {
          symbol: 'RM',
          name: 'Malaysian Ringgit',
          symbolNative: 'RM',
          decimalDigits: 2,
          rounding: 0,
          code: 'MYR',
          namePlural: 'Malaysian ringgits'
        },
        {
          symbol: 'MK',
          name: 'Malawian Kwacha',
          symbolNative: 'MK',
          decimalDigits: 2,
          rounding: 0,
          code: 'MWK',
          namePlural: 'Malawian Kwacha'
        },
        {
          symbol: 'Rf',
          name: 'Maldivian rufiyaa',
          symbolNative: 'Rf',
          decimalDigits: 2,
          rounding: 0,
          code: 'MVR',
          namePlural: 'Maldivian rufiyaa'
        },
        {
          symbol: 'UM',
          name: 'Mauritanian ouguiya',
          symbolNative: 'UM',
          decimalDigits: 0,
          rounding: 0,
          code: 'MRU',
          namePlural: 'Mauritanian ouguiya'
        },
        {
          symbol: 'MURs',
          name: 'Mauritian Rupee',
          symbolNative: 'MURs',
          decimalDigits: 0,
          rounding: 0,
          code: 'MUR',
          namePlural: 'Mauritian rupees'
        },
        {
          symbol: 'MX$',
          name: 'Mexican Peso',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'MXN',
          namePlural: 'Mexican pesos'
        },
        {
          symbol: 'MDL',
          name: 'Moldovan Leu',
          symbolNative: 'MDL',
          decimalDigits: 2,
          rounding: 0,
          code: 'MDL',
          namePlural: 'Moldovan lei'
        },
        {
          symbol: '₮',
          name: 'Mongolian tögrög',
          symbolNative: '₮',
          decimalDigits: 2,
          rounding: 0,
          code: 'MNT',
          namePlural: 'Mongolian tögrög'
        },
        {
          symbol: 'MAD',
          name: 'Moroccan Dirham',
          symbolNative: 'د.م.‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'MAD',
          namePlural: 'Moroccan dirhams'
        },
        {
          symbol: 'MTn',
          name: 'Mozambican Metical',
          symbolNative: 'MTn',
          decimalDigits: 2,
          rounding: 0,
          code: 'MZN',
          namePlural: 'Mozambican meticals'
        },
        {
          symbol: 'MMK',
          name: 'Burmese kyat',
          symbolNative: 'K',
          decimalDigits: 0,
          rounding: 0,
          code: 'MMK',
          namePlural: 'Burmese kyats'
        },
        {
          symbol: 'N$',
          name: 'Namibian Dollar',
          symbolNative: 'N$',
          decimalDigits: 2,
          rounding: 0,
          code: 'NAD',
          namePlural: 'Namibian dollars'
        },
        {
          symbol: 'ƒ',
          name: 'Netherlands Antillean guilder',
          symbolNative: 'ƒ',
          decimalDigits: 0,
          rounding: 0,
          code: 'ANG',
          namePlural: 'Netherlands Antillean guilder'
        },
        {
          symbol: '₦',
          name: 'Nigerian Naira',
          symbolNative: '₦',
          decimalDigits: 2,
          rounding: 0,
          code: 'NGN',
          namePlural: 'Nigerian nairas'
        },
        {
          symbol: 'C$',
          name: 'Nicaraguan Córdoba',
          symbolNative: 'C$',
          decimalDigits: 2,
          rounding: 0,
          code: 'NIO',
          namePlural: 'Nicaraguan córdobas'
        },
        {
          symbol: 'Nkr',
          name: 'Norwegian Krone',
          symbolNative: 'kr',
          decimalDigits: 2,
          rounding: 0,
          code: 'NOK',
          namePlural: 'Norwegian kroner'
        },
        {
          symbol: 'NPRs',
          name: 'Nepalese Rupee',
          symbolNative: 'नेरू',
          decimalDigits: 2,
          rounding: 0,
          code: 'NPR',
          namePlural: 'Nepalese rupees'
        },
        {
          symbol: 'NZ$',
          name: 'New Zealand Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'NZD',
          namePlural: 'New Zealand dollars'
        },
        {
          symbol: 'OMR',
          name: 'Omani Rial',
          symbolNative: 'ر.ع.‏',
          decimalDigits: 3,
          rounding: 0,
          code: 'OMR',
          namePlural: 'Omani rials'
        },
        {
          symbol: 'B/.',
          name: 'Panamanian Balboa',
          symbolNative: 'B/.',
          decimalDigits: 2,
          rounding: 0,
          code: 'PAB',
          namePlural: 'Panamanian balboas'
        },
        {
          symbol: 'K',
          name: 'Papua New Guinean kina',
          symbolNative: 'K',
          decimalDigits: 2,
          rounding: 0,
          code: 'PGK',
          namePlural: 'Papua New Guinean kina'
        },
        {
          symbol: 'S/.',
          name: 'Peruvian Nuevo Sol',
          symbolNative: 'S/.',
          decimalDigits: 2,
          rounding: 0,
          code: 'PEN',
          namePlural: 'Peruvian nuevos soles'
        },
        {
          symbol: '₱',
          name: 'Philippine Peso',
          symbolNative: '₱',
          decimalDigits: 2,
          rounding: 0,
          code: 'PHP',
          namePlural: 'Philippine pesos'
        },
        {
          symbol: 'PKRs',
          name: 'Pakistani Rupee',
          symbolNative: '₨',
          decimalDigits: 0,
          rounding: 0,
          code: 'PKR',
          namePlural: 'Pakistani rupees'
        },
        {
          symbol: 'zł',
          name: 'Polish Zloty',
          symbolNative: 'zł',
          decimalDigits: 2,
          rounding: 0,
          code: 'PLN',
          namePlural: 'Polish zlotys'
        },
        {
          symbol: '₲',
          name: 'Paraguayan Guarani',
          symbolNative: '₲',
          decimalDigits: 0,
          rounding: 0,
          code: 'PYG',
          namePlural: 'Paraguayan guaranis'
        },
        {
          symbol: 'QR',
          name: 'Qatari Rial',
          symbolNative: 'ر.ق.‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'QAR',
          namePlural: 'Qatari rials'
        },
        {
          symbol: 'RON',
          name: 'Romanian Leu',
          symbolNative: 'RON',
          decimalDigits: 2,
          rounding: 0,
          code: 'RON',
          namePlural: 'Romanian lei'
        },
        {
          symbol: 'T',
          name: 'Samoan tālā',
          symbolNative: 'T.',
          decimalDigits: 0,
          rounding: 0,
          code: 'WST',
          namePlural: 'Samoan tālā'
        },
        {
          symbol: 'din.',
          name: 'Serbian Dinar',
          symbolNative: 'дин.',
          decimalDigits: 0,
          rounding: 0,
          code: 'RSD',
          namePlural: 'Serbian dinars'
        },
        {
          symbol: '₨',
          name: 'Seychellois rupee',
          symbolNative: '₨',
          decimalDigits: 0,
          rounding: 0,
          code: 'SCR',
          namePlural: 'Seychellois rupee'
        },
        {
          symbol: '$',
          name: 'Surinamese dollar',
          symbolNative: '$',
          decimalDigits: 0,
          rounding: 0,
          code: 'SRD',
          namePlural: 'Surinamese dollars'
        },
        {
          symbol: 'RUB',
          name: 'Russian Ruble',
          symbolNative: 'руб.',
          decimalDigits: 2,
          rounding: 0,
          code: 'RUB',
          namePlural: 'Russian rubles'
        },
        {
          symbol: 'RWF',
          name: 'Rwandan Franc',
          symbolNative: 'FR',
          decimalDigits: 0,
          rounding: 0,
          code: 'RWF',
          namePlural: 'Rwandan francs'
        },
        {
          symbol: 'Db',
          name: 'Sao Tomean Dobra',
          symbolNative: 'Db',
          decimalDigits: 0,
          rounding: 0,
          code: 'STD',
          namePlural: 'Sao Tomean Dobra'
        },
        {
          symbol: 'SR',
          name: 'Saudi Riyal',
          symbolNative: 'ر.س.‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'SAR',
          namePlural: 'Saudi riyals'
        },
        {
          symbol: 'Le',
          name: 'Sierra Leonean Leone',
          symbolNative: 'Le',
          decimalDigits: 2,
          rounding: 0,
          code: 'SLL',
          namePlural: 'Sierra Leonean Leone'
        },
        {
          symbol: '₩',
          name: 'South Korean Won',
          symbolNative: '₩',
          decimalDigits: 0,
          rounding: 0,
          code: 'KRW',
          namePlural: 'South Korean won'
        },
        {
          symbol: '£',
          name: 'South Sudanese pound',
          symbolNative: '£',
          decimalDigits: 2,
          rounding: 0,
          code: 'SSP',
          namePlural: 'South Sudanese pound'
        },
        {
          symbol: 'SLRs',
          name: 'Sri Lankan Rupee',
          symbolNative: 'SL Re',
          decimalDigits: 2,
          rounding: 0,
          code: 'LKR',
          namePlural: 'Sri Lankan rupees'
        },
        {
          symbol: 'SDG',
          name: 'Sudanese Pound',
          symbolNative: 'SDG',
          decimalDigits: 2,
          rounding: 0,
          code: 'SDG',
          namePlural: 'Sudanese pounds'
        },
        {
          symbol: 'Skr',
          name: 'Swedish Krona',
          symbolNative: 'kr',
          decimalDigits: 2,
          rounding: 0,
          code: 'SEK',
          namePlural: 'Swedish kronor'
        },
        {
          symbol: 'S$',
          name: 'Singapore Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'SGD',
          namePlural: 'Singapore dollars'
        },
        {
          symbol: 'Ssh',
          name: 'Somali Shilling',
          symbolNative: 'Ssh',
          decimalDigits: 0,
          rounding: 0,
          code: 'SOS',
          namePlural: 'Somali shillings'
        },
        {
          symbol: 'SY£',
          name: 'Syrian Pound',
          symbolNative: 'ل.س.‏',
          decimalDigits: 0,
          rounding: 0,
          code: 'SYP',
          namePlural: 'Syrian pounds'
        },
        {
          symbol: 'L',
          name: 'Swazi Lilangeni',
          symbolNative: 'L‏',
          decimalDigits: 0,
          rounding: 0,
          code: 'SZL',
          namePlural: 'Swazi Lilangeni'
        },
        {
          symbol: 'CHF',
          name: 'Swiss Franc',
          symbolNative: 'CHF',
          decimalDigits: 2,
          rounding: 0.05,
          code: 'CHF',
          namePlural: 'Swiss francs'
        },
        {
          symbol: 'ЅМ',
          name: 'Tajikistani Somoni',
          symbolNative: 'ЅМ',
          decimalDigits: 2,
          rounding: 0,
          code: 'TJS',
          namePlural: 'Tajikistani Somoni'
        },
        {
          symbol: '฿',
          name: 'Thai Baht',
          symbolNative: '฿',
          decimalDigits: 2,
          rounding: 0,
          code: 'THB',
          namePlural: 'Thai baht'
        },
        {
          symbol: 'DT',
          name: 'Tunisian Dinar',
          symbolNative: 'د.ت.‏',
          decimalDigits: 3,
          rounding: 0,
          code: 'TND',
          namePlural: 'Tunisian dinars'
        },
        {
          symbol: 'm',
          name: 'Turkmenistan manat',
          symbolNative: 'm',
          decimalDigits: 0,
          rounding: 0,
          code: 'TMT',
          namePlural: 'Turkmenistan manat'
        },
        {
          symbol: 'T$',
          name: 'Tongan Paʻanga',
          symbolNative: 'T$',
          decimalDigits: 2,
          rounding: 0,
          code: 'TOP',
          namePlural: 'Tongan paʻanga'
        },
        {
          symbol: 'TL',
          name: 'Turkish Lira',
          symbolNative: 'TL',
          decimalDigits: 2,
          rounding: 0,
          code: 'TRY',
          namePlural: 'Turkish Lira'
        },
        {
          symbol: 'TT$',
          name: 'Trinidad and Tobago Dollar',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'TTD',
          namePlural: 'Trinidad and Tobago dollars'
        },
        {
          symbol: 'NT$',
          name: 'New Taiwan Dollar',
          symbolNative: 'NT$',
          decimalDigits: 2,
          rounding: 0,
          code: 'TWD',
          namePlural: 'New Taiwan dollars'
        },
        {
          symbol: 'TSh',
          name: 'Tanzanian Shilling',
          symbolNative: 'TSh',
          decimalDigits: 0,
          rounding: 0,
          code: 'TZS',
          namePlural: 'Tanzanian shillings'
        },
        {
          symbol: '₴',
          name: 'Ukrainian Hryvnia',
          symbolNative: '₴',
          decimalDigits: 2,
          rounding: 0,
          code: 'UAH',
          namePlural: 'Ukrainian hryvnias'
        },
        {
          symbol: 'USh',
          name: 'Ugandan Shilling',
          symbolNative: 'USh',
          decimalDigits: 0,
          rounding: 0,
          code: 'UGX',
          namePlural: 'Ugandan shillings'
        },
        {
          symbol: 'AED',
          name: 'United Arab Emirates Dirham',
          symbolNative: 'د.إ.‏',
          decimalDigits: 2,
          rounding: 0,
          code: 'AED',
          namePlural: 'UAE dirhams'
        },
        {
          symbol: '$U',
          name: 'Uruguayan Peso',
          symbolNative: '$',
          decimalDigits: 2,
          rounding: 0,
          code: 'UYU',
          namePlural: 'Uruguayan pesos'
        },
        {
          symbol: 'UZS',
          name: 'Uzbekistani soʻm',
          symbolNative: 'UZS',
          decimalDigits: 0,
          rounding: 0,
          code: 'UZS',
          namePlural: 'Uzbekistani soʻm'
        },
        {
          symbol: 'Vt',
          name: 'Vanuatu Vatu',
          symbolNative: 'Vt',
          decimalDigits: 0,
          rounding: 0,
          code: 'VUV',
          namePlural: 'Vanuatu Vatu'
        },
        {
          symbol: 'Bs.F.',
          name: 'Venezuelan Bolívar',
          symbolNative: 'Bs.F.',
          decimalDigits: 2,
          rounding: 0,
          code: 'VEF',
          namePlural: 'Venezuelan bolívars'
        },
        {
          symbol: '₫',
          name: 'Vietnamese Dong',
          symbolNative: '₫',
          decimalDigits: 0,
          rounding: 0,
          code: 'VND',
          namePlural: 'Vietnamese dong'
        },
        {
          symbol: 'FCFA',
          name: 'CFA Franc BEAC',
          symbolNative: 'FCFA',
          decimalDigits: 0,
          rounding: 0,
          code: 'XAF',
          namePlural: 'CFA francs BEAC'
        },
        {
          symbol: 'CFA',
          name: 'CFA Franc BCEAO',
          symbolNative: 'CFA',
          decimalDigits: 0,
          rounding: 0,
          code: 'XOF',
          namePlural: 'CFA francs BCEAO'
        },
        {
          symbol: 'YR',
          name: 'Yemeni Rial',
          symbolNative: 'ر.ي.‏',
          decimalDigits: 0,
          rounding: 0,
          code: 'YER',
          namePlural: 'Yemeni rials'
        },
        {
          symbol: 'R',
          name: 'South African Rand',
          symbolNative: 'R',
          decimalDigits: 2,
          rounding: 0,
          code: 'ZAR',
          namePlural: 'South African rand'
        },
        {
          symbol: 'ZK',
          name: 'Zambian Kwacha',
          symbolNative: 'ZK',
          decimalDigits: 0,
          rounding: 0,
          code: 'ZMK',
          namePlural: 'Zambian kwachas'
        }
      ]
    }
  },

  computed: {
    currencySymbol () {
      const currency = this.currencies.find(currency => currency.code === this.getIdea().ideaSettings.currency)

      return typeof currency !== 'undefined' ? currency.symbol : '$'
    },

    currencyCode () {
      const currency = this.currencies.find(currency => currency.code === this.getIdea().ideaSettings.currency)

      return typeof currency !== 'undefined' ? currency.code : 'USD'
    },

    currencySymbolLength () {
      return this.currencySymbol.length
    }
  },

  methods: {
    chartValueFormatter (value) {
      return this.currencySymbol + '' + this.getRoundedFormattedAmount(value)
    },

    chartTooltipDisplay (value) {
      return this.currencySymbol + '' + this.getFormattedAmount(value)
    },

    getFormattedAmount (amount, numberOfDecimals = 0) {
      if (!amount) return 0

      return amount.toFixed(numberOfDecimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    getRoundedFormattedAmount (amount, numberOfDecimals) {
      const absoluteNumber = Math.abs(Number(amount))
      const decimalsForLargeAmount = numberOfDecimals === undefined ? 2 : numberOfDecimals

      if (absoluteNumber >= 1.0e+9) {
        return (parseInt(((Number(amount) / 1.0e+9) * 100).toString()) / 100).toFixed(decimalsForLargeAmount) + 'B'
      }

      if (absoluteNumber >= 1.0e+7) {
        return (parseInt(((Number(amount) / 1.0e+6) * 100).toString()) / 100).toFixed(decimalsForLargeAmount) + 'M'
      }

      const decimalsForSmallAmount = Number.isInteger(amount) || amount.toString().substr(amount.toString().indexOf('.') + 1).startsWith('00') ? 0 : 2

      return amount.toFixed(decimalsForSmallAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    getRoundedFormattedAmountForCharts (amount, numberOfDecimals) {
      const absoluteNumber = Math.abs(Number(amount))
      const generalsDecimals = numberOfDecimals || 0
      const decimalsForLargeAmount = numberOfDecimals === undefined ? 2 : numberOfDecimals

      if (absoluteNumber >= 1.0e+9) {
        return (parseInt(((Number(amount) / 1.0e+9) * 100).toString()) / 100).toFixed(decimalsForLargeAmount) + 'B'
      }

      if (absoluteNumber >= 1.0e+7) {
        return (parseInt(((Number(amount) / 1.0e+6) * 100).toString()) / 100).toFixed(decimalsForLargeAmount) + 'M'
      }

      if (absoluteNumber >= 1.0e+3) {
        return (parseInt(((Number(amount) / 1.0e+3) * 100).toString()) / 100).toFixed(decimalsForLargeAmount) + 'K'
      }

      const decimalsForSmallAmount = (amount.toString().indexOf('.') === -1) && !(amount.toString().substr(amount.toString().indexOf('.')).endsWith('.00')) ? 0 : generalsDecimals

      return amount.toFixed(decimalsForSmallAmount).toString()
    },

    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    }
  }
}
