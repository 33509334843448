<template>
  <div
    class="add-new-card-wrapper"
    :class="{'horizontal': horizontal, 'disabled': disabled, 'center': center}"
    :style="{minHeight: minHeight}"
    @click="onClick"
  >
    <div>
      <el-button
        type="plus-round-xs"
        icon="el-icon-plus"
        :class="{'mb-3': !horizontal, 'cursor-not-allowed': disabled}"
      />
    </div>

    <div>
      <p class="mb-0" :class="{'mt-1': !horizontal, 'mt-0': horizontal}">
        {{ title }}
      </p>
      <p v-if="subtitle" class="m-0 o-50 mt-1">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddNewCard',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String,
      default: '0'
    },
    center: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-new-card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid rgba($color-navy, .25);
  text-align: center;
  transition: all 0.25s;
  cursor: pointer;
  padding: 1rem;

  @include media-breakpoint-up($lg) {
    border: dashed 1px #545971;
  }

  &.horizontal {
    flex-direction: row;
    justify-content: left;
    padding: 0;

    &.center {
      justify-content: center;
    }

    ::v-deep {
      .el-button {
        max-width: 38px;
        min-height: 38px;
        margin: 0 14px;
        padding: 10px;

        i {
          font-size: 16px;
        }
      }
    }
  }

  &.disabled {
    filter: grayscale(0.7);
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    transform: translateY(-2px) translateX(-1px);

    ::v-deep {
      border: solid 1px transparent;
      background-color: rgba($color-white, .9);
      box-shadow: 8px 9px 13px 0 rgba(0, 0, 0, 0.14), 0 0 6px 0 rgba(0, 0, 0, 0.2);

      .el-button {
        &--plus-round-xs {
          transition: all ease-in-out 0.25s;
          border: 1px solid rgba($color-navy, .25);
          box-shadow: 0 16px 15px -12px rgba(0, 96, 238, 0.25);

          .el-icon-plus {
            transform: scale(1.25);
          }
        }
      }
    }
  }

  ::v-deep {
    .el-button {
      &--plus-round-xs {
        transition: all ease-in-out 0.25s;
        border: 1px solid transparent;
        width: 100%;
        max-width: 90px;
        min-height: 90px;
        padding: 30px;
        display: block;

        .el-icon-plus {
          font-size: 24px;
          transition: all ease-in-out 0.45s;
          transform: rotate(-180deg);
        }
      }
    }
  }
}
</style>
