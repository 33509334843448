<template>
  <!-- Dialog Process Completed -->
  <ib-dialog
    :visible="dialogProcessCompletedVisible"
    :show-close="false"
    @close="closeDialog"
  >
    <!-- Illustration -->
    <div class="tutorial-step-modal-image-wrapper d-flex justify-content-center">
      <img src="@/assets/img/tutorial/tutorial-step-modal.svg" alt="" class="w-100">
    </div>
    <!-- /Illustration -->

    <!-- Dialog Content -->
    <div class="text-wrapper text-center">
      <h1 class="mt-5">
        {{ $t('dialogs.processCompleted.title') }}
      </h1>
      <p class="m-0 o-50" v-html="$t('dialogs.processCompleted.subtitle')" />
    </div>
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('dialogs.processCompleted.gotIt')"
        :visible-cancel-btn="false"
        @submit="closeDialog"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Process Completed -->
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'DialogProcessCompleted',

  props: {
    dialogProcessCompletedVisible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapMutations('helpers', ['toggleImageUnlock']),
    closeDialog () {
      // display unlock image
      this.toggleImageUnlock(true)
      // Close dialog/modal
      this.$emit('close-dialog-process-completed')
    }
  }
}
</script>
<style scoped lang="scss">
.text-wrapper {
  h1 {
    font-size: 2.25rem;
  }
  p {
    font-size: 1.5rem;
  }
}

.tutorial-step-modal-image-wrapper {
  img {
    width: 50% !important;
  }
}
</style>
