<template>
  <div :style="{'max-width': width}">
    <v-select
      v-model="content"
      class="placeholder-black"
      :placeholder="`-- ${$t('select')} --`"
      outlined
      hide-details
      background-color="#fff"
      item-color="ai"
      color="#8509F6"
      :items="items"
      height="36px"
      dense
      :disabled="disabled"
      :menu-props="{offsetY: true, maxHeight: 350}"
      @change="$emit('change', $event)"
    >
      <template #item="{item}">
        <span class="item font-outfit-light">{{ item.text }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'AiHelpMeWriteDropdown',

  props: {
    value: {
      required: true,
      validator: value => typeof value === 'string' || value === null,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '185px'
    }
  },

  data () {
    return {
      items: [
        {
          text: this.$t('autoWrite'),
          value: 'help-me-write'
        },
        {
          text: this.$t('improveWriting'),
          value: 'improve-writing'
        },
        {
          text: this.$t('checkGrammar'),
          value: 'check-grammar'
        },
        {
          text: this.$t('makeShorter'),
          value: 'make-shorter'
        },
        {
          text: this.$t('makeLonger'),
          value: 'make-longer'
        }
      ]
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  font-size: 16px;
}
</style>
