<template>
  <ib-card
    v-if="isGridView"
    class="business-guide-card grid"
    @click="$emit('click')"
  >
    <div class="ib-card-top">
      <slot name="top-grid" />
    </div>
    <slot name="bottom-grid" />
  </ib-card>
  <ib-card
    v-else
    class="business-guide-card list d-flex align-items-center"
    @click="$emit('click')"
  >
    <slot name="list" />
  </ib-card>
</template>

<script>
export default {
  name: 'BusinessGuideCardWrapper',

  inject: ['view'],

  computed: {
    isGridView () {
      return this.view === 'grid'
    }
  }
}
</script>

<style scoped>
.business-guide-card.list:hover >>> .actions,
.business-guide-card.grid:hover >>> .actions {
  opacity: 0.5;
}

.business-guide-card.list {
  padding: 8px 15px;
  display: flex;
  min-height: 44px;
}

.business-guide-card.grid .ib-card-top {
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.business-guide-card.grid >>> .avatar-holder,
.business-guide-card.list >>> .avatar-holder {
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center center;
}

.business-guide-card.grid >>> .avatar-holder.circle,
.business-guide-card.list >>> .avatar-holder.circle {
  border-radius: 50%;
  background-size: cover;
}

.business-guide-card.grid >>> .avatar-holder {
  width: 114px;
  height: 114px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-guide-card.list >>> .avatar-holder {
  width: 38px;
  height: 38px;
}

.business-guide-card.grid >>> .actions {
  width: 25px;
  height: 35px;
  position: absolute;
  right: 0;
  padding: 10px;
}

.business-guide-card.list >>> .actions {
  position: relative;
  width: 12px;
}

.business-guide-card >>> .actions span svg {
  height: 16px;
  width: 16px;
  padding: 5px;
  cursor: pointer;
}

.business-guide-card.list >>> .actions,
.business-guide-card.grid >>> .actions {
  transition: all 0.25s;
  opacity: 0.2;
}
</style>
