<template>

  <!-- Tutorial Tooltip -->
  <transition name="fade">
    <div class="instructions-tooltip">

      <!-- Introduction -->
      <p class="text-uppercase">
        STARTING TIP
      </p>
      <!-- /Introduction -->

      <!-- Title -->
      <h3>{{ title }}</h3>
      <!-- /Title -->

      <!-- Text -->
      <p v-html="text" />
      <!-- /Title -->

      <!-- /Tooltip Bottom -->
      <div class="tooltip-bottom">

        <!-- Got It -->
        <el-button type="warning" class="small" @click="onGotIt">
          Got It!
        </el-button>
        <!-- /Got It -->

      </div>
      <!-- /Tooltip Bottom -->

    </div>
  </transition>
  <!-- /Tutorial Tooltip -->

</template>

<script>
export default {
  name: 'TutorialTooltip',

  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    tutorialName: {
      type: String,
      default: ''
    }
  },

  methods: {
    onGotIt () {
      this.$http.put(
        'tutorials/' + this.$store.state.user.tutorial.id,
        { [this.tutorialName]: true }
      ).then((response) => {
        if (response.status === 200) {
          this.$store.commit('user/setTutorial', { tutorial: response.data.payload.tutorial })
        }
      })
    }
  }
}
</script>
