<template>
  <div>
    <!-- Divider -->
    <ib-divider block class="my-4" />
    <!-- /Divider -->

    <!-- Buttons -->
    <el-row :gutter="10">
      <el-col :span="12">
        <ib-button
          class="btn"
          variant="white"
          size="lg"
          font-family="regular"
          @click="$emit('cancel')"
        >
          {{ cancelBtnText }}
        </ib-button>
      </el-col>

      <el-col :span="12" class="text-right">
        <ib-button
          class="btn text-uppercase"
          size="lg"
          font-family="semi-bold"
          :loading="loading"
          @click="$emit('submit')"
        >
          {{ submitBtnText }}
        </ib-button>
      </el-col>
    </el-row>
    <!-- Buttons -->
  </div>
</template>

<script>
export default {
  name: 'IbDialogFooterFullscreen',

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    submitBtnText: {
      type: String,
      default: function () {
        return this.$t('save')
      }
    },

    cancelBtnText: {
      type: String,
      default: function () {
        return this.$t('close')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 100%;

  @include media-breakpoint-up($md) {
    width: unset;
    min-width: 220px;
    max-width: 220px;
  }
}
</style>
