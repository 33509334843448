export default [{
  breakpoint: 576,
  options: {
    yaxis: {
      labels: {
        show: false
      }
    },
    xaxis: {
      offsetY: 5,
      tickAmount: 5
    }
  }
},
// Temporary workaround for https://github.com/apexcharts/apexcharts.js/issues/2056
{
  // 'Fake' breakpoint that will never apply
  breakpoint: 9001,
  options: {} // You can leave this empty
}
]
