import MixinDirectCosts from '@/mixins/directCosts'
import MixinStaffAndPayroll from '@/mixins/staffAndPayroll'

export default {
  mixins: [
    MixinDirectCosts,
    MixinStaffAndPayroll
  ],

  methods: {
    formatChartYLabels (value) {
      return this.currencySymbol + '' + this.getRoundedFormattedAmountForCharts(value, 0)
    },

    formatTooltip (value) {
      if (value) {
        return this.currencySymbol + ' ' + this.getFormattedAmount(value)
      }
    },

    switchToMonthly () {
      this.$set(this, 'period', 'monthly')
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    },

    switchToQuarterly () {
      this.$set(this, 'period', 'quarterly')
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    },

    switchToYearly () {
      this.$set(this, 'period', 'yearly')
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    },

    switchToDetailed () {
      this.showTotals = false
    },

    switchToTotals () {
      this.showTotals = true
    },

    getCategories () {
      const categories = []
      if (this.period === 'monthly' || this.period === 'quarterly' || typeof this.period === 'undefined') {
        const startingString = this.getIdea().ideaSettings.startingFrom
        const numberOfMonthsPlanning = this.getIdea().ideaSettings.planningFor * 12

        let format = 'MMM [\']YY'
        if (this.period === 'quarterly') {
          format = '[Q]Q [\']YY'
        }

        for (let i = 0; i < numberOfMonthsPlanning; i++) {
          if (categories.includes(this.$moment(startingString).add(i, 'M').format(format)) === false) {
            categories.push(this.$moment(startingString).add(i, 'M').format(format))
          }
        }
      } else {
        const numberOfYearsToAdd = this.getIdea().ideaSettings.planningFor
        for (let i = 0; i < numberOfYearsToAdd; i++) {
          categories.push('Y' + (i + 1))
        }
      }

      return categories.slice(0, 12)
    },

    getIdea () {
      return this.sharedIdea || this.$store.state.idea
    },

    getDateFormat () {
      let format = 'MMM [\']YY'

      if (this.period === 'quarterly') {
        format = '[Q]Q [\']YY'
      }

      if (this.period === 'yearly') {
        format = 'YYYY'
      }

      return format
    }
  }
}
