export default {
  idea: 'Executive summary',
  product: 'Value proposition',
  promotion: 'Marketing plan',
  distribution: 'Sales and distribution plan',
  partner: 'Partnership strategy',
  market: 'Market overview',
  customers: 'Target audience',
  competitors: 'Competitive landscape',
  'swot-analysis': 'Business environment',
  'market-potential': 'Business environment',
  managementTeam: 'Team structure',
  ownership: 'Company description'
}
