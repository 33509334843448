<template>
  <ib-drawer
    :size="size"
    :visible="visible"
    @open="onOpen"
    @close="$emit('close')"
  >
    <ib-expand-panel
      :value="true"
      :expandable="false"
      closable
      color="violet"
      inline
      close-btn-mobile-align
      @close="$emit('close')"
    >
      <template #title>
        <div class="title-wrapper my-3 my-md-0">
          <div class="ai-buddy-drawer-title">
            <i class="icon ib-icon ib-icon-magic-2" />
            <div class="d-flex">
              AI Buddy
            </div>
          </div>

          <!-- Tabs -->
          <div class="tabs">
            <ib-button-tab
              v-if="helpMeWrite && canEdit"
              :is-active="activeComponent === 'AiHelpMeWrite'"
              class="my-3 my-md-0 button-tab"
              @click="onClick('AiHelpMeWrite')"
            >
              <span class="font-outfit-medium">{{ $t('aiBuddy.helpMeWrite.title') }}</span>
            </ib-button-tab>
            <ib-button-tab
              :is-active="activeComponent === 'AiTips'"
              class="my-3 my-md-0 button-tab"
              @click="onClick('AiTips')"
            >
              <span class="font-outfit-medium">{{ $t('tips') }}</span>
            </ib-button-tab>
          </div>
        </div>
        <!-- /Tabs -->
      </template>

      <!-- Ai Help Me Write -->
      <ai-help-me-write
        v-if="helpMeWrite"
        v-show="activeComponent === 'AiHelpMeWrite'"
        :type="type"
        @close="$emit('close')"
      />
      <!-- /Ai Help Me Write -->

      <!-- Ai Tips -->
      <ai-tips
        v-if="activeComponent === 'AiTips'"
        :type="type"
        @close="$emit('close')"
      />
      <!-- /Ai Tips -->
    </ib-expand-panel>
  </ib-drawer>
</template>

<script>
import AiHelpMeWrite from '@/views/Home/StoryMode/Components/AiBuddy/AiHelpMeWrite.vue'
import AiTips from '@/views/Home/StoryMode/Components/AiBuddy/AiTips.vue'
import IbDrawer from '@/components/_v2/Drawer/IbDrawer'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapGetters } from 'vuex'

export default {
  name: 'AiBuddyDrawer',

  components: {
    AiHelpMeWrite,
    AiTips,
    IbDrawer
  },

  mixins: [MixinIdeaRoles],

  provide () {
    return {
      getSection: () => this.section,
      getVisible: () => this.visible
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true
    },
    sectionName: {
      type: String,
      required: false,
      default: null
    },
    helpMeWrite: {
      type: Boolean,
      default: false
    },
    defaultActiveComponent: {
      type: String,
      default: 'AiTips'
    }
  },

  data () {
    return {
      activeComponent: 'AiTips'
    }
  },

  computed: {
    ...mapGetters('idea', ['getBusinessPlanSectionByName']),

    size () {
      return this.$breakpoint.lgAndUp ? '768px' : '100%'
    },

    section () {
      return this.getBusinessPlanSectionByName(this.sectionName)
    }
  },

  methods: {
    onOpen () {
      if (!this.canEdit) {
        this.activeComponent = this.defaultActiveComponent

        return
      }
      this.activeComponent = this.helpMeWrite ? 'AiHelpMeWrite' : this.defaultActiveComponent
    },

    onClick (component) {
      this.activeComponent = component
    }
  }
}
</script>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 0 10px 10px;

  @include media-breakpoint-up($md) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    padding: 20px 0 10px 10px;
  }

  .ai-buddy-drawer-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: $color-ai;
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .icon {
      font-size: 22px !important;
      margin-bottom: 5px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
    //padding: 0 20px 0 35px;
    padding-left: 20px;

    @include media-breakpoint-up($md) {
      padding: 0;
      column-gap: 0;
    }

    .button-tab {
      @include media-breakpoint-up($md) {
        margin-left: 10px;
      }
    }
  }
}
</style>
