import ImageResize from 'quill-image-resize'
import Quill from 'quill'

const BaseImageFormat = Quill.import('formats/image')

const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
]

class ImageFormat extends BaseImageFormat {
  static formats (domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }

      return formats
    }, {})
  }

  format (name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register('modules/imageResize', ImageResize)
Quill.register(ImageFormat, true)

export default {
  imageResize: {
    modules: [
      'DisplaySize',
      'Resize',
      'Toolbar'
    ]
  }
}
