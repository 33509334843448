<template>
  <!-- Set Labor Burden Dialog-->
  <ib-dialog
    id="dialog-set-labor-burden"
    :visible="dialogSetLaborBurdenVisible"
    fullscreen
    empty-header
    @open="openDialog"
    @close="closeDialog"
  >

    <!-- Content Wrapper -->
    <ib-dialog-container max-width="990px" class="content-wrapper">
      <!-- Header Flex -->
      <header class="header-flex">

        <!-- Header Title -->
        <h2>{{ $t('pages.businessGuide.staffAndPayroll.setLaborBurden') }}</h2>
        <!-- /Header Title -->

      </header>
      <!-- /Header Flex -->

      <!-- Guide -->
      <guide
        :visible="guideDialogVisible"
        :is-dialog="true"
        :labor="true"
        :title="$t('pages.businessGuide.staffAndPayroll.buttonDialog.setLaborBurden.title')"
        :text="$t('pages.businessGuide.staffAndPayroll.buttonDialog.setLaborBurden.text')"
        @close="toggleDialogGuide"
      />
      <!-- /Guide -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">

        <!-- Grid Wrapper -->
        <div class="grid-wrapper">
          <el-row :gutter="60">
            <el-col :md="12">

              <!-- Slider Block Item -->
              <div class="slider-block">

                <!-- Slider Block Title -->
                <h4>{{ $t('pages.businessGuide.staffAndPayroll.estimatedTaxRate') }}</h4>
                <!-- /Slider Block Title -->

                <!-- Slider Block Top -->
                <div class="top">
                  <div>
                    <p>{{ form.percentageValue }}%</p>
                  </div>
                </div>
                <!-- /Slider Block Top -->

                <!-- Slider Block Bottom -->
                <div class="bottom">
                  <el-slider
                    v-model="form.percentageValue"
                    :show-tooltip="false"
                    :min="0"
                    :max="100"
                    :step="1"
                  />
                </div>
                <!-- Slider Block Bottom -->

                <!-- Slider Block Range Values -->
                <div class="flex">
                  <p>0%</p>
                  <p>100%</p>
                </div>
                <!-- /Slider Block Range Values -->

              </div>
              <!-- /Slider Block Item -->

            </el-col>
          </el-row>
        </div>
        <!-- /Grid Wrapper -->

      </div>
      <!-- /Inner Wrapper -->

      <!-- Save & Close -->
      <ib-dialog-footer-fullscreen
        :loading="loading"
        @submit="updateLaborBurden"
        @cancel="closeDialog"
      />
      <!-- /Save & Close -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Set Labor Burden Dialog -->
</template>

<script>
import Guide from '@/views/Home/StoryMode/Components/Guide/Guide'
import IbDialogFooterFullscreen from '@/components/_v2/Dialog/IbDialogFooterFullscreen'

export default {
  name: 'DialogSetLaborBurden',

  components: {
    Guide,
    IbDialogFooterFullscreen
  },

  props: {
    dialogSetLaborBurdenVisible: {
      type: Boolean,
      default: false
    },
    laborBurdenAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      guideDialogVisible: true,
      expandedDialogGuide: true,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        percentageValue: 0,
        paymentFrequency: 'Monthly'
      },
      focus: {
        paymentFrequency: false
      },
      formTop: null,
      ruleFormTop: {
        name: {
          value: '',
          focus: false
        }
      }
    }
  },

  mounted () {
    this.checkInputLabels()
  },

  methods: {
    toggleDialogGuide () {
      this.guideDialogVisible = !this.guideDialogVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]
      if (this.form[input]) this.focus[input] = true
    },

    closeDialog () {
      this.$emit('close-dialog-set-labor-burden')
    },

    onUpdateSuccess () {
      this.$emit('on-save')
    },

    checkInputLabels () {
      this.checkInputLength('paymentFrequency')
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    openDialog () {
      if (this.laborBurdenAction) {
        this.form = Object.assign({}, this.laborBurdenAction)
        this.form.ideaId = this.$store.state.idea.id
      }
      this.checkInputLabels()
    },

    updateLaborBurden () {
      this.loading = true
      this.$http.put(`story-mode/set-up/staff-and-payroll/labor-burden/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateLaborBurden', response.data.payload.laborBurden)
            this.form = this.$store.state.idea.storyMode.setUp.staffAndPayrolls.laborBurden
            this.closeDialog()
            this.onUpdateSuccess()
          }
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
