<template>
  <!-- Dialog Remove Examples -->
  <ib-dialog
    :visible="visible"
    :show-close="false"
    width="550px"
    top="8vh"
    empty-header
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/trash.svg')"
      :title="$t('dialogs.removeExamples.title')"
      :subtitle="$t('dialogs.removeExamples.subtitle')"
      :show-illustration="false"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('dialogs.removeExamples.yesDeleteIt')"
        :cancel-btn-text="$t('dialogs.removeExamples.noJustKidding')"
        :loading="loading"
        space-between-buttons
        variant-button="red"
        @cancel="$emit('no')"
        @submit="$emit('yes')"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Remove Examples -->
</template>

<script>
export default {
  name: 'DialogRemoveExamples',

  props: {
    visible: {
      default: false,
      type: Boolean
    },

    loading: {
      default: false,
      type: Boolean
    }
  }
}
</script>
