<template>
  <!-- Main Navigation -->
  <div id="main-navigation" class="container-1200 d-none d-md-block">
    <div class="nav-wrapper">
      <!-- Concept -->
      <step
        :step-data="steps['concept']"
        :active-step="activeStep"
        @toggle-step="onToggleStep"
        @away-settings="onAwaySettings"
      />
      <!-- /Concept -->

      <!-- Research -->
      <step
        :step-data="steps['research']"
        :active-step="activeStep"
        @toggle-step="onToggleStep"
        @away-settings="onAwaySettings"
      />
      <!-- /Research -->

      <!-- Set Up -->
      <step
        :step-data="steps['setup']"
        :active-step="activeStep"
        @toggle-step="onToggleStep"
        @away-settings="onAwaySettings"
      />
      <!-- /Set Up -->

      <!-- Projections -->
      <step
        :step-data="steps['projections']"
        :active-step="activeStep"
        @toggle-step="onToggleStep"
        @away-settings="onAwaySettings"
      />
      <!-- /Projections -->

      <!-- Validation -->
      <!--      <step-->
      <!--        :step-data="steps['validation']"-->
      <!--        :active-step="activeStep"-->
      <!--        :show-counter="false"-->
      <!--        @toggle-step="onToggleStep"-->
      <!--        @away-settings="onAwaySettings"-->
      <!--      />-->
      <!-- /Validation -->
    </div>
  </div>
  <!-- /Main Navigation -->

</template>

<script>
import MixinStoryModeSteps from '@/mixins/storyModeSteps'
import Step from './Step/Step'

export default {
  name: 'MainNavigation',

  components: {
    Step
  },

  mixins: [
    MixinStoryModeSteps
  ],

  data () {
    return {
      activeStep: '',
      timeout: null
    }
  },

  methods: {
    onToggleStep (stepToActivate) {
      clearTimeout(this.timeout)

      if (stepToActivate) {
        this.activeStep = stepToActivate
      } else {
        this.closeStep()
      }
    },

    closeStep () {
      this.timeout = setTimeout(() => {
        this.activeStep = null
      }, 100)
    },

    onAwaySettings () {
      this.activeStep = ''
    }
  }
}
</script>
