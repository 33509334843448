var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-wrapper"},[_c('v-col',{staticClass:"d-flex pa-0"},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":[
        {
          text:_vm.$t('monthly'),
          value: 'monthly',
        },
        {
          text:_vm.$t('quarterly'),
          value: 'quarterly',
        },
        {
          text:_vm.$t('yearly'),
          value: 'yearly',
        }
      ]},on:{"change":_vm.onChange},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }