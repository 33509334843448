import EventBus from '@/event-bus'

export default {
  mounted () {
    EventBus.$on('idea-settings-update', () => {
      if (this.$refs.table !== undefined) {
        this.$refs.table.doLayout()
      }
    })

    setTimeout(function () {
      this.showTables = true
    }.bind(this), 10)
  },

  computed: {
    tableFirstColumnWidth () {
      return this.period === 'yearly' && !this.tableAdjustment.journalPage
        ? 550
        : this.tableAdjustment.tableFirstColumnWidth
    }
  }
}
