<template>
  <div class="d-flex flex-column align-items-center align-items-md-stretch py-2 py-md-0 right-side">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ChartComponentRightSide'
}
</script>

<style scoped lang="scss">
.right-side{
  padding: 0 30px;
}
</style>
