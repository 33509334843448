<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.distributionOfPayrollExpenses') }}
    </template>
    <!-- /Title -->
    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('pages.businessGuide.monthlyAverage') }}
    </template>
    <!-- /Subtitle -->
    <!-- Chart -->
    <template #chart>
      <distribution-of-payroll-expenses-chart
        hide-title
        format-labels
        :chart-animations="chartAnimations"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('totalMonthlyAverage')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('topThreeAverage') }}
          </p>
          <template v-for="(stuff, index) in topStuffs">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="index + 1"
              :text="stuff.name"
              circle
              :money="stuff.average"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
  </chart-component>
</template>

<script>
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import DistributionOfPayrollExpensesChart from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Charts/DistributionOfPayrollExpensesChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'DistributionOfPayrollExpenses',

  components: {
    ChartComponentRightSide,
    DistributionOfPayrollExpensesChart,
    ChartComponent,
    TotalAmount,
    YearInfo
  },

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    totalAmount () {
      return this.$store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.total
    },

    topStuffs () {
      return this.$store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.topStuffs
    }
  }
}
</script>
