
<template>
  <div
    class="ai-suggestion-tag-wrapper cursor-pointer font-outfit-medium"
    :class="classes"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>

const SIZES = ['small', 'large']

export default {
  name: 'AiSuggestionTag',

  props: {
    selected: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      validator: value => {
        return SIZES.includes(value)
      },
      default: 'large'
    }
  },

  computed: {
    classes () {
      return [
        this.sizeClass,
        { selected: this.selected }
      ]
    },

    sizeClass () {
      return this.size
    }
  }
}
</script>

<style scoped lang="scss">
.ai-suggestion-tag-wrapper {
  visibility: hidden;
  display: inline-block;
  background: rgba(133, 9, 246, 0.2);
  border: 1px solid rgba(133, 9, 246, 0.002);
  color: #081E4A;

  margin: 0 0 10px;
  border-radius: 22px;
  transition: all 0.25s;

  &.large {
    font-size: 16px;
    padding: 10px 15px;
  }

  &.small {
    font-size: 13px;
    padding: 6px 10px;
  }

  &.selected {
    color: $color-white;
    border: 1px solid #8509F6;
    background: rgba(133, 9, 246, 0.5);
  }

  &:hover {
    color: $color-white;
    background: rgba(133, 9, 246, 0.5);
  }
}
</style>
