<template>
  <!-- Main Bottom Bar -->
  <div id="main-bottom-bar" class="container-1200">
    <!-- Step Status Mobile -->
    <!--    <step-status-->
    <!--      ref="stepStatusMobile"-->
    <!--      class="d-md-none mb-4"-->
    <!--      :value="completed"-->
    <!--      :disabled="!canEdit"-->
    <!--      :step-status="statusOfCompleted"-->
    <!--      @in-progress="returnStepInProgress"-->
    <!--      @complete="onSwitchToCompleteStep"-->
    <!--    />-->
    <!-- /Step Status Mobile -->

    <el-row :gutter="10">
      <el-col :span="12">
        <!-- Back Button -->
        <ib-navigation-button
          class="navigation-btn"
          type="back-arrow"
          :title="back.title"
          :disabled="back.disabled"
          @click="navigateBack"
        />
        <!-- /Back Button -->
      </el-col>
      <!--      <el-col :xs="12" :md="8" class="d-none d-md-block">-->
      <!--        &lt;!&ndash; Step Status Desktop &ndash;&gt;-->
      <!--        <step-status-->
      <!--          ref="stepStatus"-->
      <!--          :value="completed"-->
      <!--          :disabled="!canEdit"-->
      <!--          :step-status="statusOfCompleted"-->
      <!--          @in-progress="returnStepInProgress"-->
      <!--          @complete="onSwitchToCompleteStep"-->
      <!--        />-->
      <!--        &lt;!&ndash; /Step Status Desktop &ndash;&gt;-->
      <!--      </el-col>-->
      <el-col :span="12" class="text-md-right">
        <!-- Next Button -->
        <ib-navigation-button
          v-if="next.title"
          class="navigation-btn"
          :title="next.title"
          :disabled="next.disabled"
          @click="navigateNext"
        />
        <!-- /Next Button -->
      </el-col>
    </el-row>

    <!-- Dialog Complete Step -->
    <dialog-complete-step
      :visible="dialogCompleteStepVisible"
      @close="closeDialogCompleteStep"
      @complete-step="complete"
    />
    <!-- /Dialog Complete Step -->

    <!-- Dialog Congratulation Concept -->
    <dialog-congratulations-concept
      :dialog-congratulations-concept-visible="
        dialogCongratulationsConceptVisible
      "
      @finish-concept-step="onFinishConceptStep"
    />
    <!-- /Dialog Congratulation Concept -->

    <!-- Dialog Congratulation Research -->
    <dialog-congratulations-research
      :dialog-congratulations-research-visible="
        dialogCongratulationsResearchVisible
      "
      @finish-research-step="onFinishResearchStep"
    />
    <!-- /Dialog Congratulation Research -->

    <!-- Dialog Congratulation Set Up -->
    <dialog-congratulations-setup
      :dialog-congratulations-setup-visible="dialogCongratulationsSetupVisible"
      @finish-setup-step="onFinishSetupStep"
    />
    <!-- /Dialog Congratulation Set Up -->

    <!-- Dialog Congratulation Forecast -->
    <dialog-congratulations-forecast
      :dialog-congratulations-forecast-visible="
        dialogCongratulationsForecastVisible
      "
      @finish-forecast-step="onFinishForecastStep"
    />
    <!-- /Dialog Congratulation Forecast -->

    <!-- Illustration for congrats popup -->
    <div ref="notify" style="display: none;">
      <!--<img style="width: 287px; margin-left: -36px; margin-bottom: -20px; margin-top: -4px;" src="@/assets/img/illustrations/fogg-success-1.svg" alt="success">-->
      <p>{{ $t('mainBottomBar.thisStepIsCompleted') }}</p>
    </div>
    <!-- !Illustration for congrats popup -->
  </div>
  <!-- /Main Bottom Bar -->
</template>

<script>
import DialogCompleteStep from './Dialogs/DialogCompleteStep'
import DialogCongratulationsConcept from '@/views/Home/StoryMode/Components/Dialogs/DialogCongratulationsConcept'
import DialogCongratulationsForecast from '@/views/Home/StoryMode/Components/Dialogs/DialogCongratulationsForecast'
import DialogCongratulationsResearch from '@/views/Home/StoryMode/Components/Dialogs/DialogCongratulationsResearch'
import DialogCongratulationsSetup from '@/views/Home/StoryMode/Components/Dialogs/DialogCongratulationsSetup'
import IbNavigationButton from '@/components/_v2/Button/IbNavigationButton'
import MixinGamification from '@/mixins/gamification'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinStoryModeSteps from '@/mixins/storyModeSteps'
// import StepStatus from '@/views/Home/StoryMode/Components/MainBottomBar/Components/StepStatus'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EventBus from '@/event-bus'

export default {
  name: 'MainBottomBar',

  components: {
    // StepStatus,
    DialogCompleteStep,
    DialogCongratulationsConcept,
    DialogCongratulationsForecast,
    DialogCongratulationsResearch,
    DialogCongratulationsSetup,
    IbNavigationButton
  },

  mixins: [
    MixinStoryModeSteps,
    MixinIdeaRoles,
    MixinGamification
  ],

  props: {
    back: {
      type: Object,
      default: () => ({
        disabled: false,
        title: '',
        route: ''
      })
    },

    next: {
      type: Object,
      default: () => ({
        disabled: false,
        title: '',
        route: ''
      })
    },

    completedStepName: {
      type: String,
      default: ''
    },

    stepFilled: {
      type: Boolean,
      default: false
    },

    stepData: {
      type: Object,
      default: () => ({
        name: '',
        title: '',
        description: ''
      })
    }
  },

  data () {
    return {
      completed: false,
      dialogCompleteStepVisible: false,
      dialogCongratulationsConceptVisible: false,
      dialogCongratulationsResearchVisible: false,
      dialogCongratulationsSetupVisible: false,
      dialogCongratulationsForecastVisible: false,
      countComplete: 0,
      isCompleted: false,
      showUnlock: false
    }
  },

  computed: {
    ...mapState({
      completedSteps: (state) => state.idea.storyMode.common.completedSteps
    }),

    ...mapGetters('idea', [
      'isConceptCompleted',
      'isResearchCompleted',
      'isSetUpCompleted',
      'isProjectionsCompleted',
      'isSectionEverComplete'
    ]),

    statusOfCompleted () {
      return this.$store.state.idea.storyMode.common.completedSteps[this.completedStepName]
    }
  },

  watch: {
    statusOfCompleted: {
      handler (value) {
        this.completed = value
      },
      immediate: true
    }
  },

  mounted () {
    EventBus.$on('change-step-status', (payLoad) => {
      if (payLoad) {
        this.onSwitchToCompleteStep()
      } else {
        this.returnStepInProgress()
      }
    })
  },

  updated () {
    setTimeout(() => {
      this.isCompleted = true
    }, 2000)
  },

  beforeDestroy () {
    EventBus.$off('change-step-status')
  },

  methods: {
    ...mapActions('idea', ['completeStep']),
    ...mapMutations('idea', ['setIsSectionEverComplete']),

    navigateBack () {
      this.$router.push({ name: this.back.route })

      this.$gtm.trackEvent({
        event: 'backButton'
      })
    },

    navigateNext () {
      this.$router.push({ name: this.next.route })

      this.$gtm.trackEvent({
        event: 'nextButton'
      })
    },

    closeDialogCompleteStep () {
      this.dialogCompleteStepVisible = false
      this.returnStepInProgress()
    },

    onSwitchToCompleteStep () {
      if (!this.canEdit) return
      if (this.stepFilled === false && this.completed === false) {
        this.dialogCompleteStepVisible = true
      } else if (this.completed === false) {
        this.complete()
        this.dialogCompleteStepVisible = false
      }
    },

    complete () {
      this.dialogCompleteStepVisible = false
      if (this.completed === false || this.stepFilled === false) {
        this.$gtm.trackEvent({
          event: 'statusCompleted'
        })

        this.completed = true
        this.$notify({
          dangerouslyUseHTMLString: true,
          title: this.$t('congrats'),
          message:
            '<div class="notification" style="width: 100%">' +
            this.$refs.notify.innerHTML +
            '</div>',
          type: 'success',
          iconClass: 'el-icon-check'
        })

        // this.$refs.stepStatus.$refs.vueStar.active = true
        // this.$refs.stepStatusMobile.$refs.vueStar.active = true
        setTimeout(function () {
          if (this.$refs.stepStatus) {
            this.$refs.stepStatus.$refs.vueStar.active = false
          }
          if (this.$refs.stepStatusMobile) {
            this.$refs.stepStatusMobile.$refs.vueStar.active = false
          }
        }.bind(this), 1500)
      } else if (this.completed === false || this.stepFilled === true) {
        this.dialogCompleteStepVisible = false
        this.completed = true
      }

      this.$store.commit('idea/changeCompletedStep', {
        completed: this.completed,
        step: this.completedStepName
      })

      this.$store.commit('ideaCollection/changeCompletedStep', {
        id: this.$store.state.idea.id,
        completed: this.completed,
        step: this.completedStepName
      })

      if (this.$route.name.includes('concept') && this.isConceptCompleted && !this.isSectionEverComplete('concept')) {
        this.dialogCongratulationsConceptVisible = true
        this.setIsSectionEverComplete({ section: 'concept', value: true })
      }

      if (this.$route.name.includes('research') && this.isResearchCompleted && !this.isSectionEverComplete('research')) {
        this.dialogCongratulationsResearchVisible = true
        this.setIsSectionEverComplete({ section: 'research', value: true })
      }

      if (this.$route.name.includes('set-up') && this.isSetUpCompleted && !this.isSectionEverComplete('setUp')) {
        this.dialogCongratulationsSetupVisible = true
        this.setIsSectionEverComplete({ section: 'setUp', value: true })
      }

      if (this.$route.name.includes('projections') && this.isProjectionsCompleted && !this.isSectionEverComplete('projections')) {
        this.dialogCongratulationsForecastVisible = true
        this.setIsSectionEverComplete({ section: 'projections', value: true })
      }

      this.completeStep({ [this.completedStepName]: this.completed, ideaId: this.$store.state.idea.id })
        .then(() => {
          this.handleGamification()
        })

      this.intercomUpdateUser(Object.values(this.$store.state.idea.storyMode.common.completedSteps).reduce((a, item) => a + item, 0))

      this.$emit('complete-step')
    },

    returnStepInProgress () {
      if (!this.canEdit) return
      EventBus.$emit('return-step-in-progress')
      if (this.completed === true) {
        this.$gtm.trackEvent({
          event: 'statusInProgress'
        })

        this.completed = false
        this.$store.commit('idea/changeCompletedStep', {
          completed: this.completed,
          step: this.completedStepName
        })

        this.$store.commit('ideaCollection/changeCompletedStep', {
          id: this.$store.state.idea.id,
          completed: this.completed,
          step: this.completedStepName
        })

        this.completeStep({ [this.completedStepName]: this.completed, ideaId: this.$store.state.idea.id })

        this.intercomUpdateUser(Object.values(this.$store.state.idea.storyMode.common.completedSteps).reduce((a, item) => a + item, 0))
      }
    },

    onFinishConceptStep () {
      this.$router.push({ name: 'business-guide-research-market' })
      this.dialogCongratulationsConceptVisible = !this.dialogCongratulationsConceptVisible
    },

    onFinishResearchStep () {
      this.$router.push({ name: 'business-guide-set-up-startup-costs' })
      this.dialogCongratulationsResearchVisible = !this.dialogCongratulationsResearchVisible
    },

    onFinishSetupStep () {
      this.$router.push({ name: 'business-guide-projections-revenue-streams' })
      this.dialogCongratulationsSetupVisible = !this.dialogCongratulationsSetupVisible
    },

    onFinishForecastStep () {
      this.$router.push({ name: 'validation-idea-score' })
      this.dialogCongratulationsForecastVisible = !this.dialogCongratulationsForecastVisible
    },

    intercomUpdateUser (count) {
      this.$intercom.update({
        BusinessGuideCount: count
      })
    }
  }
}
</script>

<style scoped lang="scss">
#main-bottom-bar {
  border-top: 1px solid #f1f1f1;
  padding-top: 50px;

  @include media-breakpoint-up($md) {
    padding-top: 75px;
  }

  .navigation-btn {
    @include media-breakpoint-up($md) {
      min-width: 200px;
      max-width: 200px;
    }
  }
}
</style>
