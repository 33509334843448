<template>
  <div class="switch-wrapper">
    <v-col class="d-flex pa-0">
      <v-select
        v-model="period"
        outlined
        dense
        hide-details
        :items="[
          {
            text:$t('monthly'),
            value: 'monthly',
          },
          {
            text:$t('quarterly'),
            value: 'quarterly',
          },
          {
            text:$t('yearly'),
            value: 'yearly',
          }
        ]"
        @change="onChange"
      />
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'TimelinePeriodSwitcher',

  props: {
    size: {
      type: String,
      validator: value => {
        return ['xs', 'sm', 'md'].includes(value)
      },
      default: 'sm'
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  methods: {
    onChange (period) {
      this.$emit('change', period)
      switch (period) {
        case 'quarterly':
          this.switchToQuarterly()
          break
        case 'yearly':
          this.switchToYearly()
          break
        default:
          this.switchToMonthly()
      }
    },

    switchToMonthly () {
      this.$emit('switch-to-monthly')
    },

    switchToQuarterly () {
      this.$emit('switch-to-quarterly')
    },

    switchToYearly () {
      this.$emit('switch-to-yearly')
    }
  }
}
</script>
