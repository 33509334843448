import { mapGetters } from 'vuex'

import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

export default {
  mixins: [MixinIdeaRoles],

  computed: {
    ...mapGetters('user', ['getAiRequests']),
    ...mapGetters('idea', ['getOwnerAiRequests']),

    aiRequests () {
      return this.isRoleBuddy ? this.getOwnerAiRequests : this.getAiRequests
    }
  }
}
