export const imageCardTypes = {
  fti: require('@/assets/img/icons/management-team-icon.svg'),
  ci: require('@/assets/img/icons/management-team-icon.svg'),
  ftg: require('@/assets/img/icons/staff-group-icon.svg'),
  cg: require('@/assets/img/icons/staff-group-icon.svg'),
  facilities: require('@/assets/img/icons/set-up-facilities.svg'),
  equipment: require('@/assets/img/icons/set-up-equipment.svg'),
  inventory: require('@/assets/img/icons/set-up-inventory.svg'),
  'initial-promotion': require('@/assets/img/icons/set-up-initial-promotion.svg'),
  miscellaneous: require('@/assets/img/icons/set-up-miscellaneous.svg'),
  savings: require('@/assets/img/icons/savings-icon.svg'),
  crowdfunding: require('@/assets/img/icons/financing-dropdown/crowdfunding.svg'),
  investment: require('@/assets/img/icons/financing-dropdown/investment.svg'),
  loan: require('@/assets/img/icons/financing-dropdown/loan.svg'),
  loc: require('@/assets/img/icons/financing-dropdown/line-of-credit.svg'),
  product: require('@/assets/img/icons/story-mode/revenue-stream/w-product.svg'),
  service: require('@/assets/img/icons/story-mode/revenue-stream/w-service.svg'),
  bl: require('@/assets/img/icons/story-mode/revenue-stream/w-billable-rate.svg'),
  or: require('@/assets/img/icons/story-mode/revenue-stream/w-occupancy-rate.svg'),
  rc: require('@/assets/img/icons/story-mode/revenue-stream/w-recurring-charges.svg'),
  'direct-cost': require('@/assets/img/icons/direct-costs-icon.svg'),
  miscellaneous_marketing_budget: require('@/assets/img/icons/story-mode/marketing-budget/w-miscellaneous.svg'),
  public_relations_marketing_budget: require('@/assets/img/icons/story-mode/marketing-budget/w-public-relations.svg'),
  offline_advertising_marketing_budget: require('@/assets/img/icons/story-mode/marketing-budget/w-offline-adv.svg'),
  social_media_marketing_budget: require('@/assets/img/icons/story-mode/marketing-budget/w-soc-media.svg'),
  digital_marketing_marketing_budget: require('@/assets/img/icons/story-mode/marketing-budget/w-digital-marketing.svg'),
  'rental-cost': require('@/assets/img/icons/other-overheads/rental-cost.svg'),
  traveling: require('@/assets/img/icons/other-overheads/travelling.svg'),
  'repair-and-maintenance': require('@/assets/img/icons/other-overheads/repair-and-maintenatce.svg'),
  utility: require('@/assets/img/icons/other-overheads/utilities.svg'),
  'telephone-and-internet': require('@/assets/img/icons/other-overheads/telephone-and-internet.svg'),
  'sales-expenses': require('@/assets/img/icons/other-overheads/sales-expenses.svg'),
  'product-development': require('@/assets/img/icons/other-overheads/product-development.svg'),
  'office-supplies': require('@/assets/img/icons/other-overheads/office-supplies.svg'),
  'dues-and-subscriptions': require('@/assets/img/icons/other-overheads/dues-and-subs.svg'),
  'taxes-and-licenses': require('@/assets/img/icons/other-overheads/taxes-and-legal.svg'),
  'accounting-and-legal': require('@/assets/img/icons/other-overheads/accounting.svg'),
  'web-hosting-and-domains': require('@/assets/img/icons/other-overheads/web-hosting.svg'),
  representation: require('@/assets/img/icons/other-overheads/representation.svg'),
  other_overhead: require('@/assets/img/icons/other-overheads-icon.svg')
}
