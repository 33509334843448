import { mapActions, mapGetters, mapMutations } from 'vuex'
import EventBus from '@/event-bus'

import marketPotentialDefaultData from '@/data/marketPotentialDefaultData.json'

export default {
  computed: {
    ...mapGetters('idea', {
      revenueStreams: 'getRevenueStreams',
      finances: 'getFinances',
      startupCosts: 'getStartupCosts',
      staffAndPayrolls: 'getStaffAndPayrolls',
      directCosts: 'getDirectCosts',
      marketingCosts: 'getMarketingElements',
      otherOverheads: 'getOtherOverheads',
      dividends: 'getProfitAndCashFlowDividends',
      isIdeaFromTemplate: 'isIdeaFromTemplate'
    }),

    revenueHasItemsFromTemplate () {
      return this.revenueStreams.some(revenueStream => !revenueStream.isTouched && revenueStream.isFromTemplate)
    },

    financesHasItemsFromTemplate () {
      return this.finances.some(finance => !finance.isTouched && finance.isFromTemplate)
    },

    startupCostHasItemsFromTemplate () {
      return this.startupCosts.some(startupCost => !startupCost.isTouched && startupCost.isFromTemplate)
    },

    staffAndPayrollIHasItemsFromTemplate () {
      return this.staffAndPayrolls.some(staffAndPayroll => !staffAndPayroll.isTouched && staffAndPayroll.isFromTemplate)
    },

    directCostHasItemsFromTemplate () {
      return this.directCosts.some(directCost => !directCost.isTouched && directCost.isFromTemplate)
    },

    marketingCostHasItemsFromTemplate () {
      return this.marketingCosts.some(marketingElement => !marketingElement.isTouched && marketingElement.isFromTemplate)
    },

    otherOverheadHasItemsFromTemplate () {
      return this.otherOverheads.some(otherOverhead => !otherOverhead.isTouched && otherOverhead.isFromTemplate)
    },

    totalCostHasItemsFromTemplate () {
      return [
        this.startupCostHasItemsFromTemplate,
        this.staffAndPayrollIHasItemsFromTemplate,
        this.directCostHasItemsFromTemplate,
        this.marketingCostHasItemsFromTemplate,
        this.otherOverheadHasItemsFromTemplate
      ].some(item => item === true)
    },

    profitAndCashFlowHasItemsFromTemplate () {
      return [
        this.revenueHasItemsFromTemplate,
        this.financesHasItemsFromTemplate,
        this.startupCostHasItemsFromTemplate,
        this.staffAndPayrollIHasItemsFromTemplate,
        this.directCostHasItemsFromTemplate,
        this.marketingCostHasItemsFromTemplate,
        this.otherOverheadHasItemsFromTemplate
      ].some(item => item === true)
    }
  },

  beforeDestroy () {
    EventBus.$off('remove-examples')
  },

  methods: {
    ...mapActions('idea', [
      'deleteIdeaExamples'
    ]),

    ...mapMutations('idea', [
      'setIdeaWhy',
      'setIdeaWhat',
      'setIdeaWhyFromTemplate',
      'setIdeaWhatFromTemplate',
      'removeProduct',
      'removePropositionById',
      'removePromotionalActivity',
      'removeDistribution',
      'removePartner',
      'removeCustomer',
      'removeCompetitor',
      'removeStartupCost',
      'removeFinance',
      'removeTeamMember',
      'removeStaffAndPayroll',
      'removeDirectCost',
      'removeOtherOverhead',
      'removeMarketingElement',
      'removeRevenueStream',
      'setMarketPotential',
      'setSwotAnalysisStrengths',
      'setSwotAnalysisWeaknesses',
      'setSwotAnalysisOpportunities',
      'setSwotAnalysisThreats',
      'setSwotAnalysisFromTemplate'
    ]),

    ...mapMutations('idea/ownership', ['setOwnership']),

    onDelete () {
      EventBus.$emit('on-delete')
    },

    deleteExamplesMixin (type, events = true) {
      if (events) EventBus.$emit('remove-examples-loading', true)

      return this.deleteIdeaExamples(type)
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          if (events) {
            EventBus.$emit('has-items-from-template', false)
            EventBus.$emit('remove-examples-loading', false)
          }
        }).catch(_ => {
          if (events) EventBus.$emit('remove-examples-loading', false)
        })
    },

    deleteTotalCostExamplesMixin () {
      EventBus.$emit('remove-examples-loading', true)

      return Promise.all([
        this.deleteExamplesMixin('startup-costs', false),
        this.deleteExamplesMixin('staff-and-payrolls', false),
        this.deleteExamplesMixin('direct-costs', false),
        this.deleteExamplesMixin('marketing-elements', false),
        this.deleteExamplesMixin('other-overheads', false),
        this.deleteExamplesMixin('financing', false)
      ]).then(_ => {
        EventBus.$emit('has-items-from-template', false)
        EventBus.$emit('remove-examples-loading', false)
      }).catch(_ => { EventBus.$emit('remove-examples-loading', false) })
    },

    deleteProfitAndCashFlowExamplesMixin () {
      EventBus.$emit('remove-examples-loading', true)

      return Promise.all([
        this.deleteExamplesMixin('revenue-streams', false),
        this.deleteExamplesMixin('financing', false),
        this.deleteExamplesMixin('startup-costs', false),
        this.deleteExamplesMixin('staff-and-payrolls', false),
        this.deleteExamplesMixin('direct-costs', false),
        this.deleteExamplesMixin('marketing-elements', false),
        this.deleteExamplesMixin('other-overheads', false)
      ]).then(_ => {
        EventBus.$emit('has-items-from-template', false)
        EventBus.$emit('remove-examples-loading', false)
      }).catch(_ => { EventBus.$emit('remove-examples-loading', false) })
    },

    deleteFromState ({ type, ids }) {
      if (!ids.length) return

      switch (type) {
        case 'idea-what':
          this.setIdeaWhat('')
          this.setIdeaWhatFromTemplate(false)
          break
        case 'idea-why':
          this.setIdeaWhy('')
          this.setIdeaWhyFromTemplate(false)
          break
        case 'products':
          ids.forEach(id => {
            this.removeProduct(id)
          })
          break
        case 'unique-selling-propositions':
          ids.forEach(id => {
            this.removePropositionById(id)
          })
          break
        case 'promotions':
          ids.forEach(id => {
            this.removePromotionalActivity(id)
          })
          break
        case 'distributions':
          ids.forEach(id => {
            this.removeDistribution(id)
          })
          break
        case 'partners':
          ids.forEach(id => {
            this.removePartner(id)
          })
          break
        case 'customers':
          ids.forEach(id => {
            this.removeCustomer(id)
          })
          break
        case 'competitors':
          ids.forEach(id => {
            this.removeCompetitor(id)
          })
          break
        case 'startup-costs':
          ids.forEach(id => {
            this.removeStartupCost(id)
          })
          break
        case 'financing':
          ids.forEach(id => {
            this.removeFinance(id)
          })
          break
        case 'management-team':
          ids.forEach(id => {
            this.removeTeamMember(id)
          })
          break
        case 'staff-and-payrolls':
          ids.forEach(id => {
            this.removeStaffAndPayroll(id)
          })
          break
        case 'ownership':
          this.setOwnership(null)
          break
        case 'direct-costs':
          ids.forEach(id => {
            this.removeDirectCost(id)
          })
          break
        case 'other-overheads':
          ids.forEach(id => {
            this.removeOtherOverhead(id)
          })
          break
        case 'marketing-elements':
          ids.forEach(id => {
            this.removeMarketingElement(id)
          })
          break
        case 'revenue-streams':
          ids.forEach(id => {
            this.removeRevenueStream(id)
          })
          break
        case 'market-potential':
          this.setMarketPotential(Object.assign({ id: ids.shift() }, marketPotentialDefaultData))
          break
        case 'swot-analysis-strengths':
          this.setSwotAnalysisStrengths('')
          this.setSwotAnalysisFromTemplate({
            type: 'strengthsFromTemplate',
            value: false
          })
          break
        case 'swot-analysis-weaknesses':
          this.setSwotAnalysisWeaknesses('')
          this.setSwotAnalysisFromTemplate({
            type: 'weaknessesFromTemplate',
            value: false
          })
          break
        case 'swot-analysis-opportunities':
          this.setSwotAnalysisOpportunities('')
          this.setSwotAnalysisFromTemplate({
            type: 'opportunitiesFromTemplate',
            value: false
          })
          break
        case 'swot-analysis-threats':
          this.setSwotAnalysisThreats('')
          this.setSwotAnalysisFromTemplate({
            type: 'threatsFromTemplate',
            value: false
          })
          break
      }
    }
  }
}
