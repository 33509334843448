<template>
  <el-row :gutter="60">
    <el-col :md="24" class="d-none d-md-block">
      <!-- Left slot -->
      <slot name="left" />
      <ib-divider class="my-3" block />
      <!-- /Left slot -->
    </el-col>
    <el-col :md="12">
      <div
        class="d-flex justify-content-end"
        :class="!rightEnd ? 'justify-content-md-start' : null"
      >
        <!-- Right slot -->
        <slot name="right" />
        <!-- /Right slot -->
      </div>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: 'AddDialogHeader',

  props: {
    rightEnd: {
      type: Boolean,
      default: false
    }
  }
}
</script>
