export default {
  data () {
    return {
      cardHolder: null
    }
  },

  updated () {
    this.cardHolder = this.$refs.cardHolder

    if (this.$refs.cardHolder !== undefined) {
      this.cardHolder.addEventListener('scroll', this.handleMenuScroll)
    }
  },

  mounted () {
    const elementWrapper = document.getElementsByClassName('el-dialog')

    for (let i = 0; i < elementWrapper.length; i++) {
      elementWrapper[i].addEventListener('scroll', this.handleMenuScroll)
    }
  },

  methods: {
    handleMenuScroll () {
      if (this.isDialogOpen && this.$refs.itemDropdownWrapper !== undefined) {
        for (let i = 0; i < this.$refs.itemDropdownWrapper.length; i++) {
          this.$refs.itemDropdownWrapper[i].$refs.itemDropdown.visible = false
        }
      }
    }
  }
}
