<template>

  <!-- Guide -->
  <transition name="fade">
    <div :id="isDialog === false ? 'guide' : ''"
         :class="{ 'guide-visible': visible, 'dialog-guide': isDialog, 'labor': labor }"
    >
      <div class="card-wrapper">
        <div class="inner-wrapper">
          <div v-if="!labor" class="top-declaration">
            Guide
          </div>
          <button
            v-if="!labor"
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
            @click="$emit('close')"
          >
            <i class="el-dialog__close el-icon el-icon-close" />
          </button>

          <!-- Guide Header -->
          <header>
            <h3>{{ title }}</h3>
          </header>
          <!-- /Guide Header -->

          <!-- Guide Content -->
          <div>
            <!-- Shadow -->
            <div v-if="!expanded && !labor" class="bottom-shadow" />
            <!-- /Shadow -->

            <!-- Text -->
            <p
              :class="{ 'clamped': !expanded }"
              v-html="text"
            />
            <!-- /Text -->

            <template v-if="!expand">
              <!-- Action Buttons if labor-->
              <a v-if="labor" @click="expandElement">

                <!-- See More -->
                <span class="more" :class="{ 'visible': !expanded}">
                  {{ $t('seeMore') }}
                </span>
                <!-- /See More -->

                <!-- See Less -->
                <span class="less" :class="{ 'visible': expanded }">
                  {{ $t('showLess') }}
                </span>
                <!-- /See Less -->
              </a>

              <!-- If not labor -->
              <a
                v-if="!labor && !expanded"
                class="animation-pulse"
                @click="expandElement"
              >
                See more +
              </a>
              <!-- /Action Buttons -->
            </template>

          </div>
          <!-- /Guide Content -->
        </div>
      </div>
    </div>
  </transition>
  <!-- /Guide -->

</template>

<script>
export default {
  name: 'GuideDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    labor: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: false,
      scrollVisible: false
    }
  },

  mounted () {
    if (this.expand) {
      this.expanded = true
    }
  },

  methods: {
    expandElement () {
      this.expanded = !this.expanded
      this.headerScrollable(this.expanded)
    },

    headerScrollable (visible) {
      const header = document.querySelector('.header-fixed')
      // const fakeDiv = document.querySelector('.height-holder')
      if (header) {
        setTimeout(function () {
          const h = header.offsetHeight
          if (visible && h > window.innerHeight) {
            header.classList.add('scrollable')
          } else {
            header.classList.remove('scrollable')
            // fakeDiv.style.height = h + 'px'
          }
        }, 350)
      }
    }
  }
}
</script>

<style scoped>
.content {
  position: relative;
}

.bottom-shadow {
  -webkit-box-shadow: inset -10px -15px 10px 0 rgba(255, 255, 255, 1);
  -moz-box-shadow: inset -10px -15px 10px 0 rgba(255, 255, 255, 1);
  box-shadow: inset -10px -15px 10px 0 rgba(255, 255, 255, 1);
  position: absolute;
  width: 100%;
  height: 85%;
}
</style>
