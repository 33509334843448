<template>
  <!-- Add More Details -->
  <div>
    <!-- Add More Details Text Editor-->
    <div id="add-more-details-filled" :class="{'more-visible' : addMoreDetailsFilledVisible}">
      <!-- Notes Grid Wrapper -->
      <div class="notes-grid-wrapper">
        <el-row :gutter="20">
          <el-col :md="24">
            <div
              class="note"
            >
              <!--              :class="{ 'completed-card': disabled }"-->
              <!--              <div-->
              <!--                v-if="$store.state.idea.storyMode.common.completedSteps[completedStepName]"-->
              <!--                class="completed-overlay"-->
              <!--                @click="onOverlayClick"-->
              <!--              />-->
              <!-- QUILL EDITOR -->
              <div
                v-if="editorType === editorEnum.QUILL"
                class="note-footer"
              >
                <div class="quill-wrapper">
                  <ib-loader-overlay v-if="loading" />
                  <quill-editor
                    ref="quillio"
                    v-model="content"
                    :options="editorOptions"
                    :disabled="!canEdit"
                    @change="inputTimeOut"
                  />
                </div>
              </div>
              <!-- QUILL EDITOR -->

              <ib-expand-panel
                v-model="visible"
                icon="ib-icon-note"
                :value="true"
                title="Notes"
                color="mid-blue"
              >
                <!-- JODIT EDITOR -->
                <div
                  v-if="editorType === editorEnum.JODIT"
                  class="add-more-details-jodit-container"
                >
                  <ib-loader-overlay v-if="loading" />
                  <jodit-editor
                    ref="joditEditor"
                    v-model="content"
                    :config="joditEditorConfig"
                    :buttons="joditEditorButtons"
                  />
                </div>
                <!-- JODIT EDITOR -->
              </ib-expand-panel>

            </div>
            <p class="note-status pr-2">
              {{ noteStatus }}
            </p>
          </el-col>
          <!--          <el-col :md="8">-->
          <!--            <div-->
          <!--              class="tips-and-tricks"-->
          <!--              :class="{ 'completed-card': disabled }"-->
          <!--            >-->
          <!--              <div class="inner">-->
          <!--                <h3>{{ $t('tipsAndTricks.title') }}</h3>-->
          <!--                <ul>-->
          <!--                  <template v-if="tipsAndTricks[step]">-->
          <!--                    <li v-for="(item, index) in tipsAndTricks[step]" :key="index" v-html="`${$t(item)}`" />-->
          <!--                  </template>-->
          <!--                  <template v-else>-->
          <!--                    <li>{{ $t('addMoreDetails.provideHighQualityProduct') }}</li>-->
          <!--                    <li>{{ $t('addMoreDetails.makeCustomerFeelGoodAboutTheirChoice') }}</li>-->
          <!--                    <li>{{ $t('addMoreDetails.offerAnExperience') }}</li>-->
          <!--                    <li>{{ $t('addMoreDetails.marketYourBusinessInLessObviousWays') }}</li>-->
          <!--                    <li>{{ $t('addMoreDetails.utilizeSocialMedia') }}</li>-->
          <!--                  </template>-->
          <!--                </ul>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </el-col>-->
        </el-row>
      </div>
      <!-- /Notes Grid Wrapper -->
    </div>
    <!-- /Add More Details Text Editor-->
    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->
  </div>
  <!-- /Add More Details -->
</template>
<script>
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import IbLoaderOverlay from '@/components/_v2/IbLoaderOverlay.vue'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import MixinUnsupportedPlugins from '@/mixins/unsupportedPlugins'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import _debounce from 'lodash/debounce'
import editorEnum from '@/constants/editorEnum'
import quillPasteSmartOptions from '@/options/quillPasteSmartOptions'
import quillResizeImageOptions from '@/options/quillResizeImageOptions'
import tipsAndTricks from '@/data/tipsAndTricks.json'

import { mapActions, mapMutations } from 'vuex'
import { Jodit } from 'jodit'
import { JoditEditor } from 'jodit-vue'
import { quillEditor } from 'vue-quill-editor'
import { validateImage } from '@/helpers/imageHelper'

let timeout = null
export default {
  name: 'AddMoreDetails',

  components: {
    IbLoaderOverlay,
    DialogProcessCompleted,
    quillEditor,
    JoditEditor
  },

  mixins: [
    MixinProcessCompleted,
    MixinIdeaRoles,
    MixinUserSubscription,
    MixinUnsupportedPlugins
  ],

  props: {
    conceptName: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    step: {
      type: String,
      default: ''
    },
    completedStepName: {
      type: String,
      default: ''
    },
    editorType: {
      type: String,
      default: editorEnum.JODIT
    }
  },

  data () {
    return {
      learnMoreLink: `${process.env.VUE_APP_HELP_URL}/en/articles/4552250-notes-and-tips`,
      dialogDeleteNoteVisible: false,
      addMoreDetailsFilledVisible: true,
      textChange: 'Show',
      noteStatus: '',
      latestSavedNote: null,
      content: '',
      imageArrayOld: [],
      tipsAndTricks: tipsAndTricks,
      editorEnum,
      firstLoad: true,
      loading: false,
      visible: true
    }
  },

  computed: {
    currentIdeaId () {
      return this.$store.state.idea.id
    },

    disabled () {
      return this.$store.state.idea.storyMode.common.completedSteps[this.completedStepName]
    },

    editorOptions () {
      return {
        modules: {
          ...(this.canEdit && quillResizeImageOptions),
          ...quillPasteSmartOptions,
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['blockquote'],
              ['link', 'image']
            ],
            handlers: {
              image: this.imageButtonClickHandler
            }
          }
        },
        placeholder: this.canEdit ? this.$t('addMoreDetails.addNoteText') : ''
      }
    },

    joditEditorConfig () {
      return {
        minHeight: 352,
        disabled: !this.canEdit,
        placeholder: this.canEdit ? this.$t('addMoreDetails.addNoteText') : '',
        toolbarButtonSize: 'middle',
        inline: false,
        showPlaceholder: true,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        limitChars: 5000,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        controls: {
          paragraph: {
            list: Jodit.atom({
              h2: 'Heading 1',
              h3: 'Heading 2',
              p: 'Paragraph',
              blockquote: 'Quote'
            })
          }
        },
        disablePlugins: ['addNewLine']
      }
    },

    joditEditorButtons () {
      return [
        'bold',
        'italic',
        'underline',
        // 'strikethrough',
        // 'eraser',
        'paragraph',
        // 'superscript',
        // 'subscript',
        'hr',
        'ul',
        'ol',
        'brush',
        'link',
        'table',
        {
          name: 'Insert image',
          tooltip: 'Insert picture',
          iconURL: require('@/assets/img/icons/jodit-image.svg'),
          exec: this.joditImageButtonHandler
        }
      ]
    }
  },

  watch: {
    moreDetails (newValue) {
      this.content = newValue || ''
    },

    content: _debounce(function () {
      if (this.firstLoad) {
        this.firstLoad = false

        return
      }
      this.inputTimeoutJodit()
    }, 500)
  },

  created () {
    this.textChange = this.$store.state.idea.storyMode.common.moreDetailsVisibility[this.conceptName + 'Visible'] ? '' : this.$t('addMoreDetails.notesAndTips')
    this.content = this.$store.state.idea.storyMode.common.moreDetails[this.conceptName] || ''
  },

  beforeDestroy () {
    if (this.latestSavedNote) {
      this.$store.commit('idea/setMoreDetails', {
        content: this.latestSavedNote,
        conceptName: this.conceptName
      })
    }
  },

  methods: {
    ...mapActions('idea', ['uploadImage']),
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    inputTimeoutJodit () {
      if (this.isUnsupportedPluginOn) {
        this.showUnsupportedPluginNotification(this.content)

        return
      }
      this.noteStatus = ''
      const jodit = this.$refs.joditEditor.editor
      if (jodit.getEditorValue().replace(/(<([^>]+)>)/ig, '').length > 5001) {
        this.$notify.closeAll()
        this.$notify({ title: this.$t('error'), message: this.$t('validationMessages.maximumCharacter', { max: 5000 }), type: 'error' })
        this.content = this.content.substring(0, 5004)
      } else {
        this.$gtm.trackEvent({
          event: 'notesEdited'
        })

        this.noteStatus = this.$t('status.saving')
        this.$http.post(
          'story-mode/common/add-more-details',
          {
            [this.conceptName]: this.content,
            ideaId: this.currentIdeaId
          }
        ).then((response) => {
          this.noteStatus = this.$t('status.saved')
          this.latestSavedNote = response.data.payload.moreDetails[this.conceptName]
          this.$store.commit('idea/setMoreDetails', {
            content: this.latestSavedNote,
            conceptName: this.conceptName
          })
        }).catch(_ => {
          this.noteStatus = this.$t('error')
        })
      }
    },

    inputTimeOut () {
      if (this.isUnsupportedPluginOn) {
        this.showUnsupportedPluginNotification(this.content)

        return
      }
      this.noteStatus = ''
      const cEditor = this.$refs.quillio.quill
      if (cEditor.getLength() > 5001) {
        this.$notify.closeAll()
        this.$notify({ title: this.$t('error'), message: this.$t('validationMessages.maximumCharacter', { max: 5000 }), type: 'error' })
        this.content = this.content.substring(0, 5004)
      } else {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          this.$gtm.trackEvent({
            event: 'notesEdited'
          })
          this.noteStatus = this.$t('status.saving')
          this.$http.post(
            'story-mode/common/add-more-details',
            {
              [this.conceptName]: this.content,
              ideaId: this.currentIdeaId
            }
          ).then((response) => {
            this.noteStatus = this.$t('status.saved')
            this.latestSavedNote = response.data.payload.moreDetails[this.conceptName]
            this.$store.commit('idea/setMoreDetails', {
              content: this.latestSavedNote,
              conceptName: this.conceptName
            })
          }).catch(_ => {
            this.noteStatus = this.$t('error')
          })
        }, 500)
      }
    },

    joditImageButtonHandler () {
      if (!this.canEdit) return

      const jodit = this.$refs.joditEditor.editor
      if (jodit.getEditorValue().replace(/(<([^>]+)>)/ig, '').length > 5000) {
        alert('You have reached the maximum content length, you can not upload any more images!')
      } else {
        // Add a file input element to the document, then click it (open file).
        const input = document.createElement('input')
        input.hidden = true
        input.setAttribute('type', 'file')
        input.click()
        document.body.appendChild(input)
        // Once file is selected.
        input.onchange = () => {
          const file = input.files[0]
          const images = jodit.currentPlace.editor.getElementsByTagName('img').length
          if (!validateImage(file, images)) return
          // Create form.
          const formData = new FormData()
          formData.append('image', file)

          this.loading = true
          this.uploadImage({ formData, type: 'add-more-details' })
            .then(response => {
              jodit.selection.insertImage(response.url)
              this.loading = false
              this.firstLoad = false
            })
            .catch(_ => {
              this.loading = false
            })
          this.$gtm.trackEvent({
            event: 'notesFile'
          })
        }
      }
    },

    imageButtonClickHandler () {
      if (!this.canEdit) return
      const cEditor = this.$refs.quillio.quill
      if (cEditor.getLength() > 5000) {
        alert('You have reached the maximum content length, you can not upload any more images!')
      } else {
        // Add a file input element to the document, then click it (open file).
        const input = document.createElement('input')
        input.hidden = true
        input.setAttribute('type', 'file')
        input.click()
        document.body.appendChild(input)
        // Once file is selected.
        input.onchange = () => {
          const file = input.files[0]
          const cEditor = this.$refs.quillio.quill
          const images = cEditor.root.getElementsByTagName('img').length
          if (!validateImage(file, images)) return
          // Create form.
          const formData = new FormData()
          formData.append('image', file)

          this.loading = true
          this.uploadImage({ formData, type: 'add-more-details' })
            .then(response => {
              // Get the current cursor position.
              const range = cEditor.getSelection()
              // Insert the image via URL where the cursor is.
              cEditor.insertEmbed(range.index, 'image', response.url)
              this.loading = false
            })
            .catch(_ => {
              this.loading = false
            })
          this.$gtm.trackEvent({
            event: 'notesFile'
          })
        }
      }
    },

    onOverlayClick () {
      if (!this.canEdit) return
      this.openedDialogProcessCompleted()
    }
  }
}
</script>

<style scoped lang="scss">
.notes-and-tips-learn-more-link {
  text-decoration: none;
}
.notes-and-tips-learn-more-link:hover {
  text-decoration: underline;
}
.note-status {
  text-align: right;
  font-size: small;
  height: 18px;
  color: $color-text-light-gray;
}

</style>
