import { render, staticRenderFns } from "./AiBuddyDrawer.vue?vue&type=template&id=316d954d&scoped=true"
import script from "./AiBuddyDrawer.vue?vue&type=script&lang=js"
export * from "./AiBuddyDrawer.vue?vue&type=script&lang=js"
import style0 from "./AiBuddyDrawer.vue?vue&type=style&index=0&id=316d954d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "316d954d",
  null
  
)

export default component.exports